import "./../../../../Styles/LoggedIn/Teams/GroupChat.css";
import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../../context/AuthContext";
import { TeamContext } from "../../../../context/TeamContext";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, addDoc, collection, updateDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../../../firebase";
import {Box, Stack } from "@mui/material";
import Modal from "react-modal";
import moment from "moment";



export default function NewGroupChat({isOpen, onCancel, groupChats, setGroupChats, setActiveChat}) {
    const { currentUser, teamID } = useContext(UserContext);
    const { members, teamDoc } = useContext(TeamContext);
    const [name, setName] = useState('');
    const [noName, setNoName] = useState(false);
    const [chatRoster, setChatRoster] = useState([])
    const [roster, setRoster] = useState(members);
    const [fullTeam, setFullTeam] = useState(true);
    const navigate = useNavigate();

    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "400px",
          width: "100%",
        },
      };
    useEffect(() => {
        
    }, []);

    const handleGroupChatNameChange = (event) => {
        const newName = event.target.value;
        setName(newName);
    }
    const handleCheckBoxChange = (event, index) => {
        console.log(index)
        const { checked } = event.target;
        const member = roster[index];
        if (member && member.userID) {
            const memberUsersID = member.userID;
            
            if (checked) {
                setChatRoster(prevChatRoster => [...prevChatRoster, memberUsersID]);
                console.log("Added to chatRoster")
            } else {
                setChatRoster(prevChatRoster => prevChatRoster.filter(userID => userID !== memberUsersID));
                console.log("Deleted from chat roster")
            }
        }
    };
    const handleFormSubmit = async () => {
        if (name !== '') {
            const creationDate = moment();
            const creationTimeStamp = Timestamp.fromDate(creationDate.toDate());
            try {
                let viewingKeyArray = [];
                roster.map((person, index) => {
                    const newIndex = {
                        userID: person.userID,
                        lastView: creationTimeStamp
                    }
                    viewingKeyArray = [...viewingKeyArray, newIndex]
                })
                let rosterArray = [];
                roster.map((member) => {
                    const memberID = member.userID
                    rosterArray = [...rosterArray, memberID];
                })
                // console.log('viewingKeyArray: ' + JSON.stringify(viewingKeyArray));
                // console.log('roster: ' + JSON.stringify(roster));
                const chatData = {
                    groupchatName: name,
                    createdAt: creationTimeStamp,
                    createdBy: currentUser.userID,
                    members: rosterArray,
                    messages: [],
                    recentMessage: {
                        content: '',
                        creator: '',
                        sentAt: creationTimeStamp
                    },
                    viewingKey: viewingKeyArray,
                    linkedTeam : teamID
                }
                const groupChatCollectionRef = collection(db, 'groupchats');    
                const groupChatDoc = await addDoc(groupChatCollectionRef, chatData);
                const docID = groupChatDoc.id;
                await updateDoc(doc(db, 'groupchats', docID), {groupchatID: docID});
                const groupChatArrayData = {
                    ...chatData,
                    groupchatID: docID
                }
                const newGroupChatsArray = [
                    ...groupChats,
                    groupChatArrayData
                ]
                setGroupChats(newGroupChatsArray);
                setActiveChat(docID);
                handleClose();
            } catch (err) {
                console.error(err);
            }
        } else {
            setNoName(true);
        }
    } 
    const handleClose = () => {
        onCancel();
      };
    return (
        <div>
            <Box
                sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
                }}>
                <Modal
                    isOpen={isOpen}
                    onRequestClose={handleClose}
                    style={customStyles}
                    contentLabel="Modal"
                    className="create-team-content"
                    overlayClassName="create-team-overlay"
                    ariaHideApp={false} // Opt-out of accessibility settings
                    >
                    <Stack direction='column' spacing={1}>
                        {noName && <text className='delete-red small'>*Must Enter in a name</text>}
                        <text className='light-grey small'>Create New Group Chat</text>
                        <Stack direction="row" spacing={1}>
                            <Stack className='team-create-input-container' direction='column' spacing={1}>
                                <input 
                                    type="text"
                                    placeholder="chat name"
                                    value={name}
                                    onChange={handleGroupChatNameChange}
                                    className='team-create-input border-outline white small'
                                    />
                                {/* <Stack direction='row' spacing={1} alignItems='center'>
                                    <Stack direciton='column'>
                                        <input 
                                            type='checkbox'
                                            id='myCheckBox'
                                            onChange={(event) => setFullTeam(event.target.checked)}
                                            checked={fullTeam}
                                        />
                                        <label for='myCheckBox' className="custom-checkbox"></label>                                        
                                    </Stack>
                                    <text className="white small">Full Team</text>
                                </Stack>
                                <text>Roster</text>
                                {!fullTeam && members.length !== 0 && (
                                    <Stack>
                                        {members.map((item, index) => (
                                            <Stack direction='row' spacing={1} key={`checkboxDiv-${index}`}>
                                                <Stack direction='column'>
                                                    <input
                                                        type="checkbox"
                                                        id='myCheckBox'
                                                        onChange={(event) => handleCheckBoxChange(event, index, item)}
                                                    />
                                                    <label for='myCheckBox' className='custom-checkbox'></label>
                                                </Stack>
                                                <text className="white small" key={index}>{item.firstName} {item.lastName}</text>
                                            </Stack> 
                                        ))}
                                    </Stack>
                                )} */}
                            </Stack>
                            <button
                                className='team-create-button border-outline submit-green small'
                                onClick={handleFormSubmit}
                                >
                                Create Chat
                            </button>
                        </Stack>
                    </Stack>
                </Modal>
            </Box>
        </div>
        )
    }