//Hold plan creation modal
import React, { useState, useContext, useEffect } from 'react';
import { TeamContext } from '../../../../context/TeamContext';
import { UserContext } from '../../../../context/AuthContext';
import { ModalContext } from '../../../../context/ModalContext';
import { Box, Stack } from '@mui/material';
import Modal from "react-modal";
import { db } from '../../../../firebase';
import { setDoc, doc, addDoc, collection, getDoc, updateDoc } from 'firebase/firestore';
 
export default function CurrentRoster({ isOpen, onCancel, setIsAllRostser }) {

    const { teamID } = useContext(UserContext);
    const { members } = useContext(TeamContext);
    const { updateRosterData } = useContext(ModalContext);
    
    const [rosterName, setRosterName] = useState('');
    const [description, setDescription] = useState('');
    const [selected, setSelected] = useState([]);
    const [noName, setNoName] = useState(false);
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "30rem",
            width: "100%",
        },
    };

    useEffect(() => {
        if (updateRosterData) {
            const rosterName = updateRosterData.roster.title;
            const selectedMembers = updateRosterData.roster.members;
            setRosterName(rosterName);
            setSelected(selectedMembers);
        }
    }, [updateRosterData])
    const handleRosterNameChange = (event) => {
        const newName = event.target.value;
        setRosterName(newName);
    }
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value
        setDescription(newDescription);
    }

    const addMember = (member) => {
        console.log("ADDMEMBER: " + JSON.stringify(member));
        const index = selected.findIndex(person => person === member.userID);

        if (index !== -1) {
            const newSelectedArray = selected.filter((_, i) => i !== index);

            setSelected(newSelectedArray);
        } else {
            setSelected(prevSelected => [...prevSelected, member.userID]);
        }
    };

    const handleUpdateSubRoster = async () => {
        const teamDocRef = doc(db, 'teams', teamID);
        const teamDocSnap = await getDoc(teamDocRef);
        if (teamDocSnap.exists()) {
            const data = teamDocSnap.data();
            let teamSubRosters = data.subRosters || [];
            teamSubRosters[updateRosterData.index] = rosterName;
            let members = data.members || [];

            members.forEach((member, index) => {
                const updatedSubRosters = member.subRosters.filter(roster => roster !== updateRosterData.roster.title);
                members[index].subRosters = updatedSubRosters;
            });
            members.forEach(member => {
                if (selected.includes(member.userID)) {
                    member.subRosters.push(rosterName);
                }
            });
    
            const newTeamData = {
                members: members,
                subRosters: teamSubRosters
            };
            await updateDoc(teamDocRef, newTeamData);
            setIsAllRostser(false)
        }
    }
    useEffect(() => {
        console.log("Selected: " + JSON.stringify(selected));
    }, [selected])
    const handleClose = () => {
        onCancel();
    };
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction="column" spacing={1}>
                    {noName && <text className='delete-red small'>*Must Enter in a name</text>}
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <text className='white small-medium'>All Members</text>
                    </Stack>
                    <div 
                        className='page-border-line'
                        />
                    {/* <textarea 
                        className='plan-description white small'
                        placeholder='//Add Description'
                        onChange={handleDescriptionChange}
                    />
                    <div 
                        className='page-border-line'
                    /> */}
                    <Stack direction='column' spacing={2}>
                        {members.map((member, index) => (
                            
                            <Stack direction='column' spacing={0.5}>
                                <Stack key={index} direction='row' spacing={1}  className='select-plan-roster'>
                                    <text className='white small'>{member.firstName} {member.lastName}</text>
                                    <text className='small light-grey'>-</text>
                                    <text className='small light-grey light-weight'>
                                        {member.accountType === 0 && 'athlete'}
                                        {member.accountType === 1 && 'coach'}
                                        {member.accountType === 2 && 'parent'}
                                    </text>
                                </Stack>
                            </Stack>
                        ))}
                        <div className='page-border-line'/>
                    </Stack>
                </Stack>
            </Modal>
        </Box>
    )
}
 