//Passes important information throughout the app about what teams 
// the user is on and their status on the team

import React, { createContext, useState, useEffect, useContext } from "react";
import { UserContext } from "./AuthContext";
import { auth } from "../firebase";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export const TeamContext = createContext();

export const TeamProvider = ({ children }) => {
    const { currentUser, teamID, allTeams } = useContext(UserContext);

    const [teamName, setTeamName] = useState(null);
    const [pendingMembers, setPendingMembers] = useState([]);
    const [members, setMembers] = useState([]);

    const [subRosters, setSubRosters] = useState([]);
    const [personalSubRosters, setPersonalSubRosters] = useState([]);
    const [teamDoc, setTeamDoc] = useState();

    useEffect(() => {
      if (teamID) {
        const teamDocRef = doc(db, 'teams', teamID);  
        const unsubscribe = onSnapshot(teamDocRef, (teamDocSnap) => {
          console.log("gets here")
            const teamData = teamDocSnap.data()
            const name = teamData.teamName;
            const pendingMembers = teamData.pendingMembers || [];
            // console.log("pendingMembers: " + JSON.stringify(pendingMembers));
            const members = teamData.members || [];
            const yourIndex = members.findIndex(member => member.userID === currentUser.userID);
            const personalSubRosters = members[yourIndex].subRosters
            const subRosters = teamData.subRosters || [];
            // console.log("members: " + members);
            
            setPendingMembers(pendingMembers);
            setMembers(members);
            setSubRosters(subRosters);
            setPersonalSubRosters(personalSubRosters);
            setTeamName(name);
            setTeamDoc(teamData);
            // console.log(teamID)
            // console.log(teamName)
        })
        return () => unsubscribe();          
      }
    }, [teamID]);

    useEffect(() => {
    
    }, [])
    useEffect(() => {
        console.log('allTeams: ' + JSON.stringify(allTeams));
    }, [allTeams])

    return (
        <TeamContext.Provider value={{ teamName, setTeamName, pendingMembers, setPendingMembers, members, setMembers, personalSubRosters, setPersonalSubRosters, subRosters, setSubRosters, teamDoc, setTeamDoc }}>
            {children}
        </TeamContext.Provider>
    );
};
