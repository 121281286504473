//Passes important information throughout the app about what teams 
// the user is on and their status on the team

import React, { createContext, useState, useEffect } from "react";
import { auth } from "../firebase";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [showEventCreation, setShowEventCreation] = useState(false);
    const [whichEventView, setWhichEventView] = useState(null);
    const [whichLogbookView, setWhichLogbookView] = useState('plans');
    const [isWorkoutModal, setIsWorkoutModal] = useState(false);
    const [isAddTraining, setIsAddTraining] = useState(false)

    const [isUpdateTraining, setIsUpdateTraining] = useState(false);
    const [updateTrainingData, setUpdateTrainingData] = useState(null); 

    const [isUpdateEvent, setIsUpdateEvent] = useState(false);
    const [updateEventData, setUpdateEventData] = useState(null);

    const [isUpdatePlan, setIsUpdatePlan] = useState(false);
    const [updatePlanData, setUpdatePlanData] = useState(null);  
    const [updatePlanIndex, setUpdatePlanIndex] = useState(null);

    const [isPendingRoster, setIsPendingRoster] = useState(false);
    const [isAllRoster, setIsAllRoster] = useState(false);
    const [isCreateRoster, setIsCreateRoster] = useState(false);
    const [isUpdateRoster, setIsUpdateRoster] = useState(false); 
    const [updateRosterData, setUpdateRosterData] = useState(null);

    const [isCreatePlan, setIsCreatePlan] = useState(false);
    const [isCreateTeam, setIsCreateTeam] = useState(false);
    const [isJoinTeam, setIsJoinTeam] = useState(false);
    const [whichTrainingView, setWhichTrainingView] = useState(1);
    const [parentTab, setParentTab] = useState(0);
    
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          setParentTab(0);
        } else {
          setParentTab(0)
        }
      });

      return unsubscribe;
    }, []); 
    return (
      <ModalContext.Provider value={{ showEventCreation, 
                                      setShowEventCreation, 
                                      whichEventView, 
                                      setWhichEventView, 
                                      whichLogbookView, 
                                      setWhichLogbookView, 
                                      isWorkoutModal, 
                                      setIsWorkoutModal, 
                                      isAddTraining, 
                                      setIsAddTraining, 

                                      isUpdateTraining,
                                      setIsUpdateTraining,
                                      updateTrainingData,
                                      setUpdateTrainingData,

                                      isUpdateEvent,
                                      setIsUpdateEvent,
                                      updateEventData,
                                      setUpdateEventData,

                                      isUpdatePlan, 
                                      setIsUpdatePlan,
                                      updatePlanData,
                                      setUpdatePlanData,
                                      updatePlanIndex,
                                      setUpdatePlanIndex,

                                      isAllRoster,
                                      setIsAllRoster,
                                      isCreateRoster,
                                      setIsCreateRoster,
                                      isUpdateRoster,
                                      setIsUpdateRoster,
                                      updateRosterData,
                                      setUpdateRosterData,
                                      isPendingRoster,
                                      setIsPendingRoster,

                                      isCreatePlan, 
                                      setIsCreatePlan, 
                                      isCreateTeam, 
                                      setIsCreateTeam,
                                      isJoinTeam,
                                      setIsJoinTeam,
                                      parentTab, 
                                      setParentTab, 
                                      whichTrainingView,
                                      setWhichTrainingView
                                      }}>
        {children}
      </ModalContext.Provider>
    );
};