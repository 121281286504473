//Passes important information throughout the app about what teams 
// the user is on and their status on the team

import React, { createContext, useState, useEffect } from "react";

import moment from "moment";
import { auth } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

export const CalendarContext = createContext();

export const EventsProvider = ({ children }) => {
    const [allEvents, setAllEvents] = useState([]);
    const [allTrainings, setAllTrainings] = useState([]);
    const [trainingPlans, setTrainingPlans] = useState([]);
    const [detailPlans, setDetailPlans] = useState([]);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
            try {
              let allEvents = []
              const docRef = doc(db, "users", user.uid);
              const docSnap = await getDoc(docRef);
              if (docSnap.exists()) {
                const data = docSnap.data();
                const userEvents = data.events || [];
                const userTrainings = data.trainings || [];
                const userPlans = data.trainingPlans || [];
                const teams = data.teams || [];
                let teamEvents = [];
                let teamTrainingsArray = [];
                let teamPlans = [];
                for (const team of teams) {
                  if (team.status === 1) {
                    const teamID = team.teamID;
                    const teamDocRef = doc(db, 'teams', teamID);
                    const teamDocSnap = await getDoc(teamDocRef);
                    if (teamDocSnap.exists()) {
                      const teamData = teamDocSnap.data();
                      const events = teamData.teamEvents || [];
                      const teamTrainings = teamData.teamTrainings || [];
                      const teamTrainingPlans = teamData.trainingPlans || [];
                      teamEvents = teamEvents.concat(events);
                      teamTrainingsArray = teamTrainingsArray.concat(teamTrainings);
                      teamPlans = teamPlans.concat(teamTrainingPlans);
                    }  
                  }
                }
                let allTrainingsData = [...userTrainings, ...teamTrainingsArray]
                allEvents = [...userEvents, ...teamEvents];

                let allTrainingPlans = [...userPlans, ...teamPlans]
                let snippetArray = []
                allTrainingPlans.map(async (plan) => {
                  if (plan.linkedTeamName && plan.linkedTeamID) {
                      const planDocRef = doc(db, "teams", plan.linkedTeamID, "training plans", plan.planID);
                      const planDocSnap = await getDoc(planDocRef);
                      // console.log("is run here")
                      if (planDocSnap.exists()) {
                          const planData = planDocSnap.data();
                          const planWorkouts = planData.planWorkouts; 
                          // console.log("TeamWorkouts: " + planWorkouts);
                          snippetArray = [...snippetArray, ...planWorkouts];
                          // console.log("snippetArray: " + snippetArray);
                      }
                  } else {
                      const planDocRef = doc(db, "users", currentUser.userID, "training plans", plan.planID);
                      const planDocSnap = await getDoc(planDocRef);
                      if (planDocSnap.exists()) {
                          const planData = planDocSnap.data();
                          const planWorkouts = planData.planWorkouts; 
                          // console.log("UserWorkouts: " + planWorkouts);
                          snippetArray = [...snippetArray, ...planWorkouts];
                      }
                  }
                  setDetailPlans(snippetArray);
                })
                // console.log("All Events: ", allEvents);
                setAllEvents(allEvents);
                setAllTrainings(allTrainingsData);
                setTrainingPlans(allTrainingPlans);
              } 
            } catch (err) {
              console.error(err);
            }
        } else {
            console.log("No User");
            setAllEvents(null);
            setAllTrainings(null);
            setTrainingPlans([]);
        }
    });
      return unsubscribe; 
    }, []);
     
    useEffect(() => {
      console.log("allEvents: " + JSON.stringify(allEvents));
    }, [allEvents])
    useEffect(() => {
      console.log("allTrainings: " + JSON.stringify(allTrainings));
    }, [allTrainings])
    useEffect(() => {
      console.log("Training Plans: " + JSON.stringify(trainingPlans));
    }, [trainingPlans])
    return (
      <CalendarContext.Provider value={{allEvents, setAllEvents, allTrainings, setAllTrainings, trainingPlans, setTrainingPlans, detailPlans, setDetailPlans}}>
        {children}
      </CalendarContext.Provider>
    );
};