import "./../../../../Styles/LoggedIn/TrainingManager/TopPlan.css";
import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';

export default function TopPlan({allPlans, currentPlan, whichTrainingView, setWhichTrainingView}) {
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (allPlans.length > 0) {
            // console.log("currentIndex: " + currentPlan);
            // console.log("teamPlans: " + teamPlans);
            const index = currentPlan;
            const plan = allPlans[index];
            const title = plan.planTitle
            setTitle(title);    
        }
    }, [allPlans, currentPlan])


    return (
        <Stack direction='row' spacing={2} alignItems='center' className="more-margin-left padding-bottom">
            {title !== '' ? (
                <text className="white small-header mid-weight">{title}</text>
            ):(
                <text className="white small-header mid-weight">No Plans</text>
            )}
            <Stack direction='row' spacing={1} className="margin-bottom">
                <button 
                    className='plan-nav-button border-outline very-small white'
                    onClick={() => setWhichTrainingView(1)}
                    >
                    Month View
                </button>
                <button 
                    className="plan-nav-button border-outline very-small white"
                    onClick={() => setWhichTrainingView(2)}
                    > 
                    Week View
                </button>
            </Stack>
        </Stack>
    )
}
