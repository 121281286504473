
import React, { useContext } from 'react';
import { UserContext } from '../../../context/AuthContext';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
export default function ProfileButton({setIsProfileModal}) {
    const { currentUser, userRole } = useContext(UserContext)

    const navigate = useNavigate();
    const handleToProfile = () => {
        setIsProfileModal(true);
    }
    return (
        <Box className="profile-component margin-top">
            <div 
                className='icon-button padding profile-button'
                onClick={handleToProfile}
                >
                <Stack direction="row" spacing={1} alignItems='center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                        <circle cx="14.5" cy="14.5" r="14.5" fill="#D9BC21">
                            <text>J</text>
                        </circle>
                    </svg>
                    <Stack direction="column" alignItems="flex-start">
                        <text className='white small'>{currentUser.firstName}</text>
                        {userRole === 0 && <text className='mid-grey very-small'>Athlete</text>}
                        {userRole === 1 && <text className='mid-grey very-small'>Coach</text>}
                        {userRole === 2 && <text className='mid-grey very-small'>Parent</text>}
                    </Stack>
                </Stack>
            </div>
        </Box>
    )
}
