import React, { useContext, useState, useEffect } from 'react';
import { CalendarContext } from '../../../../../context/EventContext';
import { Box, Stack } from '@mui/material';
import moment from 'moment';

export default function DaySnippet({selectedDay}) {
    const [snippet, setSnippet] = useState([]);
    const [trainingSnippet, setTrainingSnippet] = useState([]);
    const { allEvents, allTrainings } = useContext(CalendarContext);

    useEffect(() => {
        let snippetArray = [];
        let trainingSnippetArray = [];
        allEvents.map((event) => {
            const eventDate = moment(event.startDate.toDate()).format("YYYY-MM-DD");
            if (eventDate === selectedDay) {
                snippetArray = [
                    ...snippetArray,
                    event
                ]
            }
        })
        allTrainings.map((training) => {
            const trainingDate = moment(training.date.toDate()).format("YYYY-MM-DD");
            if (trainingDate === selectedDay) {
                trainingSnippetArray = [
                    ...trainingSnippetArray,
                    training
                ]
            }
        })
        setSnippet(snippetArray);
        setTrainingSnippet(trainingSnippetArray);
    }, [selectedDay, allEvents, allTrainings])

    useEffect(() => {
        console.log("allEvents: " + JSON.stringify(allEvents));
    }, [allEvents])
    return (
        <Stack direction='column' spacing={2}>
            {trainingSnippet.map((training) => (
                <div className='event-snapshot-container mid-padding'>
                    <Stack direction="column" spacing={1}>
                        <text className='white small'>
                            {training.trainingName}
                        </text>
                        <Stack direction='row' spacing={1}>
                            {training.linkedPlanID && (
                                <text className='submit-green really-small plan-tag'> 
                                    {training.linkedPlanName}
                                </text>
                            )}
                            {training.linkedTeamID && (
                                <text className='delete-red really-small team-tag'> 
                                    {training.linkedTeamName}
                                </text>
                            )}
                        </Stack>
                    </Stack>
                </div>
            ))}
            {snippet.map((item) => (
                <div className='event-snapshot-container mid-padding'>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="column" spacing={1}>
                            <text className='white small'>
                                {item.eventName}
                            </text>
                            {!item.linkedTeamID && (
                                <text className='ocean-blue really-small personal-tag'> 
                                    Personal
                                </text>
                            )}
                            {item.linkedTeamID && (
                                <text className='delete-red really-small team-tag'> 
                                    {item.linkedTeamName}
                                </text>
                            )}
                        </Stack>
                        <Stack direction="column" spacing={2}>
                            <text className='white very-small light-weight'>
                                {moment(item.startDate.toDate()).format("hh:mm A")}
                            </text>
                            <text className='white very-small light-weight'>
                                {moment(item.endDate.toDate()).format("hh:mm A")}
                            </text>
                        </Stack>
                    </Stack>
                </div>
            ))}
        </Stack>
    )
}
