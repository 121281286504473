import { Box, TextField } from '@mui/material'
import React from 'react'

export default function EventName({eventName, setEventName}) {
    const handleNameChange = (event) => {
        const newName = (event.target.value);
        setEventName(newName);
    }
    return (
        <Box
            sx={{
                borderBottom: "0.125rem solid #222122",
                marginBottom: '1rem'
            }}>
            <TextField 
                id='custom-input'
                placeholder='// Event Title'
                variant='standard'
                onChange={handleNameChange}
                value={eventName}
                InputProps={{
                    disableUnderline: true, // Remove the bottom line
                    style: {
                      fontSize: '2rem',
                      fontWeight: 600,
                      letterSpacing: '-0.03375rem',
                      color: '#FFF',
                    },
                  }}
                
            />
        </Box>
    )
}
