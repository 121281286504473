import "./../../../../Styles/LoggedIn/TrainingManager/TrainingCalendar.css";
import React, {useEffect, useState, useContext} from 'react';
import { CalendarContext } from '../../../../context/EventContext';
import moment from 'moment';
import { Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


import SpecificDayTraining from './SpecificDayTraining';

export default function MonthlyTraining({targetDate, setTargetDate, selectedDay, setSelectedDay, setIsAddTraining, whichEventView, setWhichEventView, allPlans, currentPlan, teamPlans}) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', "Thu", "Fri", "Sat"];
    const [currentMonth, setCurrentMonth] = useState([]);

    const isLastRowToShow =
        (moment(targetDate).daysInMonth() === 31 && moment(targetDate).startOf('month').day() >= 5) ||
        (moment(targetDate).daysInMonth() === 30 && moment(targetDate).startOf('month').day() === 6);

    useEffect(() => {
        if (allPlans.length > 0) {
            const firstDayOfTargetMonth = moment(targetDate).startOf('month');
            const dayOfWeek = firstDayOfTargetMonth.day();
            const startDate = firstDayOfTargetMonth.clone().subtract(dayOfWeek, 'days');
            const endDate = startDate.clone().add(42, 'days');
    
            const plan = allPlans[currentPlan];
    
            const trainings = plan.planWorkouts || [];
            console.log("Trainings: " + JSON.stringify(trainings));
    
            let currentMonth = []
            for (let date = startDate.clone(); date.isBefore(endDate); date.add(1, 'day')) {
                currentMonth = [
                    ...currentMonth, 
                    {
                        date: date.format("YYYY-MM-DD"),
                        day: date.format("D"),
                        trainings: [],
                      }
                ]
              }
              currentMonth.map((day, index) => {
                trainings.map((training) => {
                    const date = day.date;
                    const trainingDate = moment(training.date.toDate()).format("YYYY-MM-DD");
    
                    if (date === trainingDate) {
                        currentMonth[index] = {
                            ...currentMonth[index], 
                            trainings: [
                                ...currentMonth[index].trainings,
                                training
                            ]
                        }
                    }
                })
            })
            setCurrentMonth(currentMonth);
            setSelectedDay(targetDate);    
        }        
    }, [targetDate, allPlans, currentPlan])

    const handleShowTrainingModal = () => {
        setIsAddTraining(true)
    }
    const oneMonthForward = () => {
        const newTargetDate = moment(targetDate).add(1, "month").format('YYYY-MM-DD');
        setTargetDate(newTargetDate);
    }
    const oneMonthBackward = () => {
        const newTargetDate = moment(targetDate).subtract(1, "month").format('YYYY-MM-DD');
        setTargetDate(newTargetDate)
    }
    const toToday = () => {
        const today = moment().format("YYYY-MM-DD");
        // console.log("today: " + today)
        setTargetDate(today);
    }
    
    return (
        <Stack direction='column' spacing={1} className='training-manager-monthly-container twice-more-padding'>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' spacing={1}>
                    <text className='small white'>
                        {moment(targetDate).format('MMMM')} {moment(targetDate).format('YYYY')}
                    </text>                
                    <button className='draft-new-event-button white really-small mid-weight padding' onClick={handleShowTrainingModal}>
                        Draft New Training + 
                    </button>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.3}>
                    <button 
                        className='day-button left-button border-outline light-grey small'
                        onClick={oneMonthBackward}
                        >
                        <ArrowForwardIosIcon style={{ fontSize: '0.8rem' }}/> 
                    </button>
                    <button 
                        className='day-button border-outline light-grey small'
                        onClick={toToday}
                        >
                        Today
                    </button>
                    <button 
                        className='day-button border-outline light-grey small'
                        onClick={oneMonthForward}
                        >
                        <ArrowForwardIosIcon style={{ fontSize: '0.8rem' }}/>
                    </button>
                </Stack> 
            </Stack>
            <div className='calendar-container'>
                <Stack direction="row" spacing={11}>
                    {daysOfWeek.map((name) => (
                        <Stack direction='row' justifyContent='center'>
                            <text className='light-grey very-small day-of-week-text'>
                                {name}
                            </text>                            
                        </Stack>
                    ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(0,7).map((day, index) => (
                            <SpecificDayTraining 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                                allPlans={allPlans}
                                teamPlans={teamPlans}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(7,14).map((day, index) => (
                            <SpecificDayTraining 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                                allPlans={allPlans}
                                teamPlans={teamPlans}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(14,21).map((day, index) => (
                            <SpecificDayTraining 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                                allPlans={allPlans}
                                teamPlans={teamPlans}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(21,28).map((day, index) => (
                            <SpecificDayTraining 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                                allPlans={allPlans}
                                teamPlans={teamPlans}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(28,35).map((day, index) => (
                            <SpecificDayTraining 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                                allPlans={allPlans}
                                teamPlans={teamPlans}
                            />
                        ))}
                </Stack>
                {isLastRowToShow && (
                    <Stack direction='row'>
                        {currentMonth.slice(35,42).map((day, index) => (
                            <SpecificDayTraining 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                                allPlans={allPlans}
                                teamPlans={teamPlans}
                            />
                        ))}
                    </Stack>
                )}
            </div>
        </Stack>
    )
}
