//Holds the workout creation Modal

import React, { useState, useContext, useEffect } from 'react';
import { db } from '../../../../../firebase';
import { setDoc, doc, addDoc, collection, Timestamp, getDoc, updateDoc } from 'firebase/firestore';
import { UserContext } from '../../../../../context/AuthContext';
import { TeamContext } from '../../../../../context/TeamContext';
import { CalendarContext } from '../../../../../context/EventContext';
import { Box, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import SectionValue from '../../Workouts/WorkoutCreation/LeftSide/SectionValue';
import SectionDetail from '../../Workouts/WorkoutCreation/RightSide/SectionDetail';
import moment from 'moment';


export default function NewTraining({ isOpen, onCancel, setIsAddTraining, personalPlans, teamPlans, selectedDay, currentPlan }) {
    const navigate = useNavigate();
    const { currentUser, userRole, teamID } = useContext(UserContext);
    const { teamName } = useContext(TeamContext);
    const { allTrainings, setAllTrainings } = useContext(CalendarContext);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [linkedPlan, setLinkedPlan] = useState(() => {
        if (userRole === 1 && teamPlans.length > 0) {
            return {
                planID: teamPlans[0].planID,
                planName: teamPlans[0].planTitle
            };
        } else if (userRole === 0 && personalPlans.length > 0) {
            return {
                planID: personalPlans[0].planID,
                planName: personalPlans[0].planTitle
            };
        } else {
            return {}
        } 
    });
    const [linkedDate, setLinkedDate] = useState(moment(selectedDay).format("YYYY-MM-DD"));
    const [sections, setSections] = useState([{
                                            workoutSectionType: 1,
                                            metricType: 2,
                                            distance: '',
                                            unit: 2,
                                        }])
    const [currentSection, setCurrentSection] = useState(0)
    
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "37rem",
          width: "100%",
        },
      }; 

    useEffect(() => {
        if (currentPlan) {
            const plan = teamPlans[currentPlan];
            console.log(plan);
        }
    }, [currentPlan, teamPlans])
    const handleWorkoutNameChange = (event) => {
        const newName = event.target.value;
        setTitle(newName);
    } 
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        setDescription(newDescription);
    }
    const handleLinkedDateChange = (event) => {
        setLinkedDate(event.target.value);
    }
    const handleLinkedPlanChange = (event) => {
        const linkedPlanEvent = JSON.parse(event.target.value);
        setLinkedPlan(linkedPlanEvent);
    };
    const handleClose = () => {
        onCancel();
      };
    const handleCreateWorkout = async () => {
        const creationDate = moment();
        const creationTimeStamp = Timestamp.fromDate(creationDate.toDate());
        const linkedDateData = Timestamp.fromDate(moment(linkedDate).toDate());
        console.log(linkedPlan)
        if (userRole === 1) {
            try { 
                const trainingPlanDocRef = doc(db, 'teams', teamID, "training plans", linkedPlan.planID);
                console.log("trainingPlanDocRef: " + trainingPlanDocRef);
                const planDocSnap = await getDoc(trainingPlanDocRef);
                if (planDocSnap.exists()) {
                    const planData = planDocSnap.data();
                    const planTrainings = planData.planWorkouts || [];
                    console.log("sectionsData: " + JSON.stringify(sections));
                    const data = [...planTrainings, {
                        createdAt: creationTimeStamp,
                        date: linkedDateData,
                        creatorID: currentUser.userID,
                        linkedPlanName: linkedPlan.planName,
                        linkedPlanID: linkedPlan.planID,
                        linkedTeamID: teamID,
                        linkedTeamName: teamName,
                        workout: {
                            createdAt: creationTimeStamp,
                            creator: currentUser.userID,
                            description: description,
                            title: title,
                            workoutSections: sections,
                        }
                    }]
                    const writeToPlansDoc = {
                        planWorkouts: data
                    }
                    console.log("data: " + JSON.stringify(data));
                    await updateDoc(trainingPlanDocRef, writeToPlansDoc);
                    const teamDocRef = doc(db, "teams", teamID);
                    const teamDocSnap = await getDoc(teamDocRef);
                    if (teamDocSnap.exists()) {
                        const teamData = teamDocSnap.data();
                        const teamTrainings = teamData.teamTrainings || []
                        const teamTrainingsData = [...teamTrainings, {
                            createdAt: creationTimeStamp,
                            date: linkedDateData,
                            linkedPlanID: linkedPlan.planID,
                            linkedPlanName: linkedPlan.planName,
                            linkedTeamID: teamID,
                            linkedTeamName: teamName,
                            trainingName: title,
                            sectionCount: sections.length
                        }];
                        const teamTrainingsContext = [...allTrainings, {
                            createdAt: creationTimeStamp,
                            date: linkedDateData,
                            linkedPlanID: linkedPlan.planID,
                            linkedPlanName: linkedPlan.planName,
                            linkedTeamID: teamID,
                            linkedTeamName: teamName,
                            trainingName: title,
                            sectionCount: sections.length 
                        }];

                        const writingData = {
                            teamTrainings: teamTrainingsData
                        }
                        await setDoc(teamDocRef, writingData, { merge: true });  
                        setAllTrainings(teamTrainingsContext);   
                        setIsAddTraining(false);     
                    }
                   
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            try {
                const trainingPlanDocRef = doc(db, 'users', currentUser.userID, "training plans", linkedPlan.planID);
                console.log("trainingPlanDocRef: " + trainingPlanDocRef);
                const planDocSnap = await getDoc(trainingPlanDocRef);
                if (planDocSnap.exists()) {
                    const planData = planDocSnap.data();
                    const planTrainings = planData.planWorkouts || [];
                
                    const data = [...planTrainings, {
                        createdAt: creationTimeStamp,
                        date: linkedDateData,
                        creatorID: currentUser.userID,
                        linkedPlanName: linkedPlan.planName,
                        linkedPlanID: linkedPlan.planID,
                        linkedTeamID: teamID,
                        workout: {
                            createdAt: creationTimeStamp,
                            creator: currentUser.userID,
                            description: description,
                            title: title,
                            workoutSections: sections,
                        }
                    }]
                    const writeToPlansDoc = {
                        planWorkouts: data
                    }
                    console.log("data: " + data)
                    await setDoc(trainingPlanDocRef, writeToPlansDoc, {merge: true});
                    const userDocRef = doc(db, "users", currentUser.userID);
                    const userDocSnap = await getDoc(userDocRef );
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const trainings = userData.trainings || []
                        const teamTrainingsData = [...trainings, {
                            date: linkedDateData,
                            linkedPlanID: linkedPlan.planID,
                            linkedPlanName: linkedPlan.planName,
                            trainingName: title,
                            sectionTypes: []
                        }];
                        const writingData = {
                            trainings: teamTrainingsData
                        }
                        const teamTrainingsContext = [...allTrainings, {
                            date: linkedDateData,
                            linkedPlanID: linkedPlan.planID,
                            linkedPlanName: linkedPlan.planName,
                            trainingName: title,
                            sectionTypes: []
                        }];
                        await setDoc(userDocRef, writingData, { merge: true });  
                        // setAllTrainings(teamTrainingsContext);     
                        setIsAddTraining(false);     
                    }
                   
                }
            } catch (err) {
                console.error(err);
            }

        }
    }
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction="column" spacing={1}>
                    <input 
                        className='workout-name-input border-outline white small-medium'   
                        placeholder='Workout Title'
                        onChange={handleWorkoutNameChange}

                        />
                    <div 
                        className='page-border-line'
                        />
                    <textarea 
                        className='workout-description white small'
                        placeholder='//Add Description'
                        onChange={handleDescriptionChange}
                    />
                    <div 
                        className='page-border-line'
                        />
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction='column' spacing={0.5}>
                            <text className='light-grey small'>
                                Linked Plan
                            </text>
                            <select 
                                className='plans-select small-medium'
                                onClick={handleLinkedPlanChange}
                                >
                                {personalPlans.map((plan) => (
                                    <option value={JSON.stringify({planID: plan.planID, planName: plan.planTitle})}>
                                        {plan.planTitle}
                                    </option>
                                ))}
                                {teamPlans.map((plan) => (
                                    <option value={JSON.stringify({planID: plan.planID, planName: plan.planTitle})}>
                                        {plan.planTitle}
                                    </option>
                                ))}
                            </select>
                        </Stack>
                        <Stack direction='column' spacing={0.5}>
                            <text className='light-grey small'>
                                Linked Date
                            </text>
                            <Box className="plan-date-selector-container">
                                <Stack direction='row' spacing={1} alignItems='baseline'>
                                    <text className='white small-header  mid-weight'>
                                        {moment(linkedDate).format("ddd")}
                                    </text>
                                    <input
                                        type="date"
                                        id="date-input"
                                        className="plan-date-input small-medium"
                                        value={linkedDate}
                                        onChange={handleLinkedDateChange}
                                    />
                                </Stack>
                            </Box> 
                        </Stack>
                    </Stack>
                    <div 
                        className='page-border-line'
                        />
                    <Stack direction="row" spacing={1}>
                        <Box className="workout-creation-container">
                            <SectionValue
                                sections={sections}
                                setSections={setSections}
                                currentSection={currentSection}
                                setCurrentSection={setCurrentSection}
                                />
                        </Box>
                        <div className='vertical-border'
                            />
                        <SectionDetail 
                            sections={sections}
                            setSections={setSections}
                            currentSection={currentSection}
                            setCurrentSection={setCurrentSection}
                            />
                    </Stack>
                    <button 
                        className='border-outline submit-green create-workout-button'
                        onClick={handleCreateWorkout}
                        >
                        Add Workout to Plan
                    </button>
                </Stack>
            </Modal>
        </Box>
    )
}
 