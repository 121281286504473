import React, { useContext, useState, useEffect } from 'react';
import { CalendarContext } from '../../../../../../../context/EventContext';
import { Box, Stack } from '@mui/material';
import moment from 'moment';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../../../firebase';
import { UserContext } from '../../../../../../../context/AuthContext';
import RunIcon from '../../../../../../../media/icons/RunIcon/RunIcon';
import BikeIcon from '../../../../../../../media/icons/BikeIcon/BikeIcon';
import LiftIcon from '../../../../../../../media/icons/LiftIcon/LiftIcon';
import SwimIcon from '../../../../../../../media/icons/SwimIcon/SwimIcon';

export default function DaySnippetPlan({selectedDay, trainings}) {
    const { trainingPlans, setTrainingPlans, detailPlans, setDetailPlans } = useContext(CalendarContext);
    const { userRole, currentUser } = useContext(UserContext);
    const [snippet, setSnippet] = useState([]);
 
    useEffect(() => {
        const selectedTrainings = trainings.filter((training) => {
            const trainingDate = moment(training.date.toDate()).format("YYYY-MM-DD");
            return trainingDate === selectedDay;
        });
        console.log("selectedTrainings: " + JSON.stringify(selectedTrainings));
        setSnippet(selectedTrainings);
    }, [selectedDay, trainings])

    return (
        <Stack direction='column' spacing={1}>
                {snippet.map((item) => {
                    const workout = item.workout;
                    console.log("Workout: " + JSON.stringify(workout));
                    const title = workout.title;
                    const description = workout.description;
                    const workoutSections = workout.workoutSections
                    console.log(description);
                    return (
                    <div className='event-snapshot-container'>
                        <Stack direction='column' spacing={0.5}>
                            <text className='small white mid-weight'>
                                {title !== '' ? title : 'Untitled'}
                            </text>
                            {(workoutSections.length === 1 && ((workoutSections[0].metricType === 2 && workoutSections[0].distance !== '') || 
                             (workoutSections[0].metricType === 1 && workoutSections[0].duration !== '') || 
                             (workoutSections[0].metricType === 0 && workoutSections[0].calories !== ''))) ? (
                                <div>
                                    {workoutSections.map((metric) => (
                                        <Stack direction="row" spacing={1}>
                                            <text className='light-grey small light-weight'>
                                                {metric.workoutSectionType === 1 && <RunIcon color={'#8A8A8A'}/>}
                                                {metric.workoutSectionType === 3 && <BikeIcon color={'#8A8A8A'}/>}
                                                {metric.workoutSectionType === 2 && <SwimIcon color={'#8A8A8A'}/>}
                                                {metric.workoutSectionType === 4 && <LiftIcon color={'#8A8A8A'}/>}

                                            </text>
                                            <Stack direction="row" spacing={0.25}>
                                                <text className='light-grey small light-weight'>
                                                    {metric.metricType === 2 && metric.distance}
                                                    {metric.metricType === 1 && metric.duration}
                                                </text>
                                                {metric.metricType !== 1 && (
                                                    <text className='light-grey small light-weight'>
                                                        {metric.unit === 2 && 'miles'}
                                                        {metric.unit === 1 && 'kilometers'}
                                                        {metric.unit === 3 && 'meters'}
                                                        {metric.unit === 4 && 'yards'}
                                                        {metric.unit === 5 && 'laps'}
                                                    </text>
                                                )}
                                            </Stack>   
                                        </Stack>
                                    ))}                                    
                                </div>
                             ) : (
                                <text className='light-grey small light-weight'>
                                    {description.slice(0, 50)}
                                </text>
                             )}
                            {item.workout.workoutSections.length > 1 && (
                                <div>
                                    {item.workout.workoutSections.map((metric) => (
                                        <Stack direction="row" spacing={1} alignItems='center'>
                                            <text className='light-grey small light-weight'>
                                                {metric.workoutSectionType === 1 && <RunIcon color={'#8A8A8A'}/>}
                                                {metric.workoutSectionType === 3 && <BikeIcon color={'#8A8A8A'}/>}
                                                {metric.workoutSectionType === 2 && <SwimIcon color={'#8A8A8A'}/>}
                                                {metric.workoutSectionType === 4 && <LiftIcon color={'#8A8A8A'}/>}
                                            </text>
                                            <Stack direction='row' spacing={0.25} alignItems='center'>
                                                <text className='light-grey small light-weight'> 
                                                    {metric.metricType === 0 && metric.calories}
                                                    {metric.metricType === 1 && metric.duration}
                                                    {metric.metricType === 2 && metric.distance}
                                                </text>
                                                <text className='light-grey small light-weight'>
                                                    {metric.unit === 2 && 'miles'}
                                                    {metric.unit === 1 && 'kilometers'}
                                                    {metric.unit === 3 && 'meters'}
                                                    {metric.unit === 4 && 'yards'}
                                                    {metric.unit === 5 && 'laps'}
                                                </text>

                                            </Stack>
                                        </Stack>   
                                    ))}                                    
                                </div>
                            )}
                        </Stack>
                    </div>
                )})}
        </Stack>
    )
}