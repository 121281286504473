import "../../../Styles/LoggedIn/Teams/Teams.css"
import React, { useState, useContext } from 'react';
import { UserContext } from "../../../context/AuthContext";
import { ModalContext } from "../../../context/ModalContext";
import { Box, Stack, Typography } from '@mui/material';
import TeamNav from './TeamNav/TeamNav';
import JoinTeam from './NewTeam/JoinTeam';
import InTeamNav from "./TeamNav/InTeamNav";
import WeeklySummary from "./WeeklySummary.js/WeeklySummary";
import CreateTeam from "./NewTeam/CreateTeam";
import Roster from "./Roster/Roster";
import GroupChat from "./GroupChat/GroupChat";
import Announcements from "./Announcements/Announcements";
export default function Teams() {
    const { allTeams } = useContext(UserContext);
    const { isCreateTeam, setIsCreateTeam, isJoinTeam, setIsJoinTeam } = useContext(ModalContext);
    const [whichTab, setWhichTab] = useState('1');

    return (
        <div className={`${isJoinTeam ? 'blurred' : ''} ${isCreateTeam ? 'blurred' : ''}`}
            >
                <Stack direction="column" spacing={1}>                      
                    {allTeams.length > 0 ? (
                        <Stack direction='column' spacing={1}>
                            <InTeamNav 
                                whichTab={whichTab}
                                setWhichTab={setWhichTab}
                            />
                            <div className="page-border-line"/>
                            {whichTab === '0' && <WeeklySummary />}
                            {whichTab === '1' && <Roster />}
                            {whichTab === '2' && <GroupChat />}
                            {whichTab === '3' && <Announcements />}
                        </Stack>
                    ):(
                        <Stack spacing={1} sx={{width: '33rem'}}>
                            <text className="update-purple small-header">What Teams Provide</text>
                            <div className="page-border-line"/>
                            <text className="small light-grey">Joining a team gives you access to that team's events, training plans, and communication feeds. You will also get to see your teammates workouts, and more.</text>
                        </Stack>
                    )}
                </Stack>
                {isJoinTeam && (
                    <JoinTeam
                        isOpen={isJoinTeam}
                        onCancel={() => setIsJoinTeam(false)}
                        setIsJoinTeam={setIsJoinTeam}
                    />
                )}
                {isCreateTeam && (
                    <CreateTeam
                        isOpen={isCreateTeam}
                        onCancel={() => setIsCreateTeam(false)}
                        setIsCreateTeam={setIsCreateTeam}
                    />
                )}
        </div>
    )
}
