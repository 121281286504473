import React, { useState } from "react";
import { db, auth } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import {
  Typography,
  Button,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  TextField,
  Stack,
} from "@mui/material";

export default function Signup() {
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userType, setUserType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const signUpWithEmailAndPassword = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        userEmail,
        userPassword
      );
      const email = userCredential.user.email;
      const userID = userCredential.user.uid;
      const userData = {
        bio: null,
        email: email,
        userID: userID,
        accountType: userType,
        firstName: firstName,
        lastName: lastName,
        // phoneNumber: phoneNumber,
        teams: [],
        selectedTeam: 'N/A'
      };
      await setDoc(doc(db, "users", userCredential.user.uid), userData);
      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <Stack direction='row' justifyContent='center' alignItems='center' sx={{height: '95vh'}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: '35rem',
        }}
      >
        <Paper
          elevation={17}
          style={{
            padding: "3%",
            width: "100%", // Adjust the width as needed
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background:
              "linear-gradient(to bottom right, #26b0e7, #68d5f9)",
          }}
        >
          <Typography variant="h3">Sign Up</Typography>
          <TextField
            type="email"
            label="Email"
            fullWidth
            margin="normal"
            onChange={(event) => {
              setUserEmail(event.target.value);
            }}
          />
          <TextField
            type="password"
            label="Password"
            fullWidth
            margin="normal"
            onChange={(event) => {
              setUserPassword(event.target.value);
            }}
          />
          <TextField
            type="text"
            label="First Name"
            fullWidth
            margin="normal"
            onChange={handleFirstNameChange}
          />
          <TextField
            type="text"
            label="Last Name"
            fullWidth
            margin="normal"
            onChange={handleLastNameChange}
          />
          <TextField
            type="text"
            label="Phone Number"
            fullWidth
            margin="normal"
            onChange={handlePhoneNumberChange}
          />
          <FormControl
            style={{ minWidth: "100px", marginBottom: "1.3%" }}
          >
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Select
              name="usertype"
              id="usertype"
              onChange={(event) => {
                setUserType(event.target.value);
              }}
              value={userType}
            >
              <MenuItem value="">Choose A Role</MenuItem>
              <MenuItem value={0}>Athlete</MenuItem>
              <MenuItem value={1}>Coach</MenuItem>
              <MenuItem value={2}>Parent</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            style={{
              marginTop: "1.3%",
              backgroundColor: "#808080",
            }}
            onClick={signUpWithEmailAndPassword}
          >
            Submit
          </Button>
        </Paper>
      </Box>

    </Stack>
  );
}
