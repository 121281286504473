import React from 'react';
import { CalendarContext } from '../../../../context/EventContext';
import { Box, Stack } from '@mui/material';
import CurrentDate from './CalendarBar/CurrentDate';
import DaySnippet from './DaySnippet/DaySnippet';
import "../../../../Styles/LoggedIn/Calendar/RightBar.css"

export default function RightBar({selectedDay, setSelectedDay}) {
    return (
        <div className='right-bar-container'>
            <Stack direction="column" spacing={1}>
                <CurrentDate 
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    />
                <DaySnippet 
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}                    
                    />
           </Stack>
        </div>
    )
}
 