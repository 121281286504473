import React, { useState, useContext } from 'react';
import { ModalContext } from '../../../context/ModalContext';
import { UserContext } from '../../../context/AuthContext';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function NavButtons() {
    const navigate = useNavigate();
    const { parentTab, setParentTab } = useContext(ModalContext); 
    const { userRole } = useContext(UserContext);
    
    const handleToUpdates = () => {
        setParentTab(6);
        navigate('/updates');
    }
    const handleToHome = () => {
        setParentTab(0);
        navigate('/');
    }
    const handleToCalendar = () => {
        navigate('/calendar')
        setParentTab(1);
    }
    const handleToTeams = () => {
        navigate('/teams');
        setParentTab(2);
    }
    const handleToLogbook = () => {
        navigate('/logbook')
        setParentTab(4);
    }
    const handleToTrainingManager = () => {
        navigate('/training-manager');
        setParentTab(3);
    }

    const handleToAthletePerformance = () => {
        navigate('/athlete-performance');
        setParentTab(5);
    }
    const handleToWorkouts = () => {
        navigate('/logbook');
        setParentTab(3);
    }
    return (
        <Box
            sx={{
                marginLeft: '5%',
                marginTop: '1rem',
                width: '100%'
            }}>

            <Box
                sx={{
                    paddingTop: '1rem'
                }}>
                <Stack direction="column" spacing={0.6}>
                    <text className='very-small mid-grey nav-text'>Tabs</text>
                    <button 
                        className={`nav-buttons text-button very-small ${parentTab === 0 ? 'selected-tab submit-green' : 'white'}`}
                        onClick={handleToHome}
                        >
                        Home
                    </button>
                    <button 
                        className={`nav-buttons text-button very-small ${parentTab === 1 ? 'selected-tab submit-green' : 'white'}`}
                        onClick={handleToCalendar}
                        >
                        Calendar
                    </button>
                    <button 
                        className={`nav-buttons text-button very-small ${parentTab === 3 ? 'selected-tab submit-green' : 'white'}`}
                        onClick={handleToTrainingManager}
                        >
                        Training                  
                    </button>
                    {/* {userRole === 1 && (
                        <button
                            className={`nav-buttons text-button very-small ${parentTab === 5 ? 'selected-tab submit-green' : 'white'}`}
                            onClick={handleToAthletePerformance}
                        >
                            Athletes
                        </button>
                    )} */}
                    <button 
                        className={`nav-buttons text-button very-small ${parentTab === 2 ? 'selected-tab submit-green' : 'white'}`}
                        onClick={handleToTeams}
                        >
                        Social           
                    </button>
                    {/* {userRole === 0 && (
                        <button
                            className={`nav-buttons text-button very-small ${parentTab === 4 ? 'selected-tab submit-green' : 'white'}`}
                            onClick={handleToLogbook}
                        >
                            logbook
                        </button>
                    )} */}
                </Stack>
            </Box>
        </Box>
    )
}
