import { Stack } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useContext } from 'react';
import { ModalContext } from '../../../../context/ModalContext';
import RunIcon from '../../../../media/icons/RunIcon/RunIcon';
import BikeIcon from '../../../../media/icons/BikeIcon/BikeIcon';
import SwimIcon from '../../../../media/icons/SwimIcon/SwimIcon';
import LiftIcon from '../../../../media/icons/LiftIcon/LiftIcon';

export default function WeeklyDay({ day, trainings, setSelectedDay, setIsAddTraining, teamPlans }) {
    // useEffect(() => {
    //     console.log("trainings: " + JSON.stringify(trainings));
    // }, [trainings])

    const { setUpdateTrainingData, setIsUpdateTraining } = useContext(ModalContext);
    const openCreateTraining = (day) => {
        setSelectedDay(moment(day).format("YYYY-MM-DD"))
        setIsAddTraining(true);
    }

    const openUpdateWorkout = (item, day) => {
        console.log("item: " + JSON.stringify(item));
        const currentPlanID = item.linkedPlanID;
        const workoutCreationTime = moment(item.workout.createdAt.toDate());
        // console.log("workoutCreationTime: " + workoutCreationTime)
        teamPlans.map((plan) => {  
            const planID = plan.planID;
            if (planID === currentPlanID) {
                // console.log("PLAN: " +JSON.stringify(plan));
                const planWorkouts = plan.planWorkouts;
                planWorkouts.map((workout) => {
                    // console.log("WokroutDate: " + workout.date);
                    const workoutDate = moment(workout.workout.createdAt.toDate());
                    if (workoutCreationTime.isSame(workoutDate, 'second')) {
                        const workoutSections = workout.workout.workoutSections;
                        const workoutTitle = workout.workout.title;
                        const workoutDescription = workout.workout.description;
                        // console.log("WorkoutTitle: " + workoutTitle)
                        // console.log("workoutDescription: " + workoutDescription)
                        // console.log("WorkoutSEctions:" + JSON.stringify(workoutSections));
                        const updateTrainingDataObject = {workoutSections: workoutSections, title: workoutTitle, description: workoutDescription, createdAt: workoutCreationTime, planID: planID}
                        setSelectedDay(day.date)
                        setUpdateTrainingData(updateTrainingDataObject);
                    }
                })   
            }
        })
        setIsUpdateTraining(true);   
    }

    return (
        <Stack direction='column' spacing={1}>
            <text className='light-grey super-small mid-weight'>{moment(day.date).format('dddd, MMMM Do')}</text>
            {trainings.length > 0 && (
                <Stack direction='column' spacing={1}>
                    {trainings.map((item) => {
                            const workout = item.workout;
                            // console.log("Workout: " + JSON.stringify(workout));
                            const title = workout.title;
                            const description = workout.description;
                            const workoutSections = workout.workoutSections
                        if ((workoutSections[0].metricType === 2 && workoutSections[0].distance !== '') || 
                            (workoutSections[0].metricType === 1 && workoutSections[0].duration !== '') || 
                            (workoutSections[0].metricType === 0 && workoutSections[0].calories !== '')) {
                            return (
                                <div onClick={() => openUpdateWorkout(item, day)}>
                                    <Stack direction='column' spacing={1} className='weekly-day-container padding'>
                                        <text className='white really-small mid-weight'>{title}</text>
                                        {workoutSections.map((section, index) => (
                                            <Stack direction='column' spacing={0.5}>
                                                <Stack direction='column' spacing={0.25}>
                                                    <text className='light-grey really-small'>Section {index + 1} - {section.workoutSectionType === 1 ? 'Run' : 
                                                                                                                    section.workoutSectionType === 2 ? 'Swim' : 
                                                                                                                    section.workoutSectionType === 3 ? 'Bike' : 
                                                                                                                    section.workoutSectionType === 4 ? 'Lift' :
                                                                                                                    null
                                                                                                                    }
                                                    </text>
                                                    <div className='page-border-line-white'/>
                                                </Stack>
                                                <Stack direction='row' spacing={0.6} alignItems='center'>
                                                    <text className='light-grey very-small light-weight'>
                                                        {section.workoutSectionType === 1 && <RunIcon color={'#FFF'}/>}
                                                        {section.workoutSectionType === 3 && <BikeIcon color={'#FFF'}/>}
                                                        {section.workoutSectionType === 2 && <SwimIcon color={'#FFF'}/>}
                                                        {section.workoutSectionType === 4 && <LiftIcon color={'#FFF'}/>}
                                                    </text>
                                                    {section.workoutSectionType !== 4 ? (
                                                        <Stack direction="row" spacing={0.25}>
                                                            <text className='white very-small light-weight'>
                                                                {section.metricType === 2 && section.distance}
                                                                {section.metricType === 1 && section.duration}
                                                                {section.metricType === 0 && section.calories}
                                                            </text>
                                                            {section.metricType !== 1 && (
                                                                <text className='white very-small light-weight'>
                                                                    {section.unit === 2 && 'miles'}
                                                                    {section.unit === 1 && 'kilometers'}
                                                                    {section.unit === 3 && 'meters'}
                                                                    {section.unit === 4 && 'yards'}
                                                                    {section.unit === 5 && 'laps'}
                                                                </text>
                                                            )}
                                                        </Stack>                                                                       
                                                    ):(
                                                        <Stack direction="column" spacing={0.25}>
                                                            <text className='white very-small light-weight'>
                                                                {section.movementName}
                                                            </text>
                                                            <text className='light-grey really-small light-weight'>
                                                                {section.movementDescription}
                                                            </text>
                                                        </Stack> 
                                                    )}
                                                </Stack>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </div>
                            )
                        } else {
                            return (
                                <Stack direction='column' spacing={1} className='weekly-day-container padding'>
                                    <text className='white really-small mid-weight'>{title}</text>
                                    <text className='light-grey really-small'>{description}</text>
                                </Stack>
                            )
                        }
                    })}
                    
                </Stack>
            )}
            <button className="add-training-weekly border-outline white" onClick={() => openCreateTraining(day)}>
                +
            </button>                                    
        </Stack>
    )
}
