import React from 'react';
import { Box, TextField } from '@mui/material';

export default function EventDescription({description, setDescription}) {
    const handleDescriptionChange = (event) => {
        const newDescription = (event.target.value);
        setDescription(newDescription);
    }
    return (
        <textarea 
            className='description-input white small border-outline'
            placeholder='// Add A Description'
            value={description}
            onChange={handleDescriptionChange}
        />
    )
}
