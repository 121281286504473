import React, { useContext, useEffect, useState, useRef } from 'react';
import { Stack } from '@mui/material';
import { UserContext } from '../../../../../context/AuthContext';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { doc, updateDoc, Timestamp, onSnapshot} from 'firebase/firestore';
import { db } from '../../../../../firebase';
import moment from 'moment';


export default function Feed({activeChat}) {
    const { currentUser } = useContext(UserContext);
    const [newMessage, setNewMessage] = useState('');
    const [feed, setFeed] = useState([]);
    const viewingKeyRef = useRef([]); 

    useEffect(() => {
        if (activeChat) {
            console.log('activeChat in the On SnapSHot: ' + activeChat)
            const activeChatRef = doc(db, 'groupchats', activeChat);
            const unsubscribe = onSnapshot(activeChatRef, (groupChatDoc) => {  
                if (groupChatDoc.exists()) {
                    const updatedChat = groupChatDoc.data()
                    // console.log('updatedChat: ' + JSON.stringify(updatedChat));    
                    const messages = updatedChat.messages;
                    const viewingKey = updatedChat.viewingKey;                    
                    setFeed(messages);
                    viewingKeyRef.current = viewingKey; 
                } else {
                    console.log('Group chat document does not exist.');
                }
            });
            return () => {
                unsubscribe();
                const setLastView = async () => {
                    try {
                        console.log("cleanUP")
                        const currentViewingKey = viewingKeyRef.current;
                        if (currentViewingKey && currentViewingKey.length > 0) {
                            const currentUserIndex = currentViewingKey.findIndex(index => index.userID === currentUser.userID);
                            if (currentUserIndex !== -1) {
                                currentViewingKey[currentUserIndex].lastView = Timestamp.now();
                                const activeChatRef = doc(db, 'groupchats', activeChat);
                                await updateDoc(activeChatRef, {viewingKey: currentViewingKey});     
                                console.log('wrote to the groupChat doc probably');                       
                            }
                        }        
                    } catch (err) {
                        console.error(err);
                    }
                }
                setLastView();
            }      
        }
    }, [activeChat])

    document.addEventListener('DOMContentLoaded', function() {
        const textarea = document.querySelector('.message-text-area');
    
        textarea.addEventListener('input', function() {
            this.style.height = 'auto'; // Reset height to auto
            this.style.height = (this.scrollHeight + 2) + 'px'; // Set height to scroll height
        });
    });
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Prevent new line on Enter press
            handleSubmitMessage();
        }
    };
    const handleMessageChange = (event) => {
        const newMessage = event.target.value;
        setNewMessage(newMessage);
    }
    const handleSubmitMessage = async () => {
        try {
            const creationDate = moment();
            const creationTimeStamp = Timestamp.fromDate(creationDate.toDate());
            if (newMessage !== '') {
                console.log('activeChat: ' + activeChat)
                if (activeChat && activeChat) {
                    console.log('gets here asdfasdfaf')
                    const activeChatRef = doc(db, 'groupchats', activeChat);
                    const newMessageData = {
                        messages: [
                            ...feed,
                            {content: newMessage,
                             userID: currentUser.userID,
                             sentAt: creationTimeStamp,
                             creator: currentUser.firstName + ' ' + currentUser.lastName
                            }
                        ],
                        recentMessage: {
                            content: newMessage,
                            creator: currentUser.firstName + ' ' + currentUser.lastName,
                            sentAt: creationTimeStamp
                        }
                    }
                    await updateDoc(activeChatRef, newMessageData)
                    setNewMessage('')
                }
            }                
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <Stack direction='row' className="container">
            <Stack className='feed-container' direction='column' spacing={2.5}>
                {feed.map((message, index) => (
                    <Stack direction='row' spacing={1}>
                        <div>
                            <div className='profile-placeholder'/>
                        </div>
                        <Stack direction='column' spacing={1}>
                            <Stack direction='row' spacing={1} alignItems='baseline'>
                                <text className='white small mid-weight' >{message.creator}</text>
                                <text className='light-grey really-small'>{moment(message.sentAt.toDate()).format('MM/DD/YYYY hh:mm A')}</text>
                            </Stack>
                            <div className='message-container'>
                                <text className='white ligth-weigth small'>{message.content}</text>
                            </div>
                        </Stack>
                    </Stack>
                ))}
            </Stack>
            <Stack direction='row' className='message-bar-container' alignItems='center'>
                <button className="icon-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" fill="#f0f0f0" />
                        <text className='mid-text plus-grey' x="50%" y="50%" text-anchor="middle" alignment-baseline="central">
                            +
                        </text>
                    </svg>
                </button>
                <textarea 
                    className='white message-text-area small' 
                    placeholder={`message @${currentUser.firstName} ${currentUser.lastName}`} 
                    onChange={handleMessageChange}
                    value={newMessage}
                    onKeyDown={handleKeyDown}
                    />
                <Stack direction='row' alignItems='center' justifyContent='center' className='submit-arrow-container margin' onClick={handleSubmitMessage}>
                    <ArrowUpwardIcon style={{ fontSize: '1.4rem', color: '#FFF'}}/>
                </Stack>
            </Stack>
        </Stack> 
    )
}
