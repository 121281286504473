import React, { useEffect, useRef } from 'react';
import "../../../Styles/Icons/Icons.css";
import "../../../Styles/Icons/SwimIcon.css";

const SwimIcon = ({ color }) => {
    const svgRef = useRef(null);

    useEffect(() => {
        // Function to resize and center the viewBox based on the bounding box of the SVG content
        const resizeViewBox = () => {
            const svgElement = svgRef.current;
            const bbox = svgElement.getBBox();
            const viewBoxValue = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
            svgElement.setAttribute('viewBox', viewBoxValue);
        };

        // Call resizeViewBox on initial render and on window resize
        resizeViewBox();
        window.addEventListener('resize', resizeViewBox);
        return () => window.removeEventListener('resize', resizeViewBox);
    }, []);

    return (
        <svg ref={svgRef} className='medium-icon' fill={color} id="Layer_1" xmlns="http://www.w3.org/2000/svg">
            <circle fill={color} cx="322.53" cy="223.78" r="31.32"/>
            <path fill={color} d="M229.67,274.33c5.58,2.25,13.76,13.65,19.78,13.74,7.05.1,16.89-13.3,23.63-15.38,
            10.76-3.34,45.05-1.1,45.05-1.1l-30.26-31.75h0l-48.77-57.48,75.35-20.94c7.7-2.14,12.03-10.75,
            9.68-19.23-2.36-8.48-10.51-13.62-18.21-11.48l-99.04,27.52c-6.83,1.9-10.99,8.89-10.2,16.36-.45,3.8.52,
            7.64,3.07,10.64l37.19,43.83-44.09,39.34s28.17,2.45,36.81,5.94Z"/><path fill={color} d="M398.27,279.75c-11.87,
            11.87-33.71,17.42-49.47,14.13-12.96-2.71-23.12-8.44-36.79-8.44-10.62,0-21.05,5-29.66,10.71-13.82,9.17-20.62,
            8.67-36.44,2.96-23.3-8.4-42.19-22.47-67.75-14.81-13.13,3.94-23.99,13.73-38.63,
            11.4-12.96-2.06-25.41-14.33-36.74-20.43-.95-.51-1.88-.83-2.8-1.06v24.75c20.17,11.89,37.23,24.13,62.49,15.79,
            17-5.61,27.1-14.34,46.08-8.08,14.78,4.87,28.33,12.9,43.31,17.6,11.13,3.49,21.07,2.49,31.45-2.16,5.29-2.37,
            10.23-7.07,15.07-10.28,11.66-7.74,25.36-4.06,37.14.56,20.03,7.85,45.17,4.72,
            64.46-6.58v-27.49c-.59.41-1.17.86-1.73,1.42Z"/><path fill={color} d="M348.81,
            337.29c-12.96-2.71-23.12-8.44-36.79-8.44-10.62,0-21.05,5-29.66,10.71-13.82,9.17-20.62,8.67-36.44,
            2.96-23.3-8.4-42.19-22.47-67.75-14.81-13.13,3.94-23.99,13.73-38.63,
            11.4-12.96-2.06-25.41-14.33-36.74-20.43-.95-.51-1.88-.83-2.8-1.06v24.75c20.17,11.89,
            37.23,24.13,62.49,15.79,17-5.61,27.1-14.34,46.08-8.08,14.78,4.87,28.33,12.9,43.31,
            17.6,11.13,3.49,21.07,2.49,31.45-2.16,5.29-2.37,10.23-7.07,15.07-10.28,11.66-7.74,25.36-4.06,37.14.56,20.03,
            7.85,45.17,4.72,64.46-6.58v-27.49c-.59.41-1.17.86-1.73,1.42-11.87,11.87-33.71,17.42-49.47,14.13Z"/>
        </svg>
    );
}

export default SwimIcon;
