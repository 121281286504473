import "./../../Styles/LoggedOut/NavBar/NavBar.css";
import React from 'react';
import { Box, Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function TempararyNav() {
    const navigate = useNavigate();
    const handleToLanding = () => {
        navigate("/Slate-Fitness");
    }
    const handleToLogin = () => {
        navigate("/Login")
    }
    const handleToSignup = () => {
        navigate("/SignUp")
    }
    return (
        <Stack direction="row" justifyContent='space-between' alignItems='center' className='nav-bar-container'>
            <button className="text-button white medium home-nav-container" onClick={handleToLanding}>
                Slate Fitness
            </button>
            <Stack direction='row' spacing={3} alignItems='center' className="auth-buttons-container">
                <button
                    className='logged-out-nav-button white small-header padding border-outline'
                    onClick={handleToLogin}>
                    Login
                </button>
                <button
                    className='logged-out-nav-button white small-header padding border-outline'
                    onClick={handleToSignup}>
                    Sign Up
                </button>
            </Stack>
        </Stack>
    )
}
