import React from 'react';
import "../../../../Styles/LoggedIn/Logbook/Workouts.css"
import RecentWorkouts from './RecentWorkouts/RecentWorkouts';

export default function Workouts() {
    return (
        <div>
            <RecentWorkouts />
        </div>
    )
}
