import React, { useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';

export default function RunMetricModifiers({ section, sections, setSections, currentSection }) {
    const [pace, setPace] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const [textDescription, setTextDescription] = useState(false);

    useEffect(() => {
        if (section.hasOwnProperty('pace')) {
            setPace(true);
        } else {
            setPace(false);
        }
        if (section.hasOwnProperty('repeat')) {
            setRepeat(true);
        } else {
            setRepeat(false);
        }
        if (section.hasOwnProperty('textDescription')) {
            setTextDescription(true);
        } else {
            setTextDescription(false);
        }
    }, [section]);

    const handlePaceClick = () => {
        if (pace === false) {
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? {
                            ...section,
                            pace: `00'00"`,
                        }
                        : section
                )
            );
        } else {
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? (() => {
                            const newObj = { ...section };
                            delete newObj.pace;
                            return newObj;
                        })()
                        : section
                )
            );
        }
    };

    const handleRepeatClick = () => {
        if (repeat === false) {
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? {
                            ...section,
                            repeat: '',
                        }
                        : section
                )
            );
        } else {
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? (() => {
                            const newObj = { ...section };
                            delete newObj.repeat;
                            return newObj;
                        })()
                        : section
                )
            );
        }
    };

    const handleTextClick = () => {
        if (textDescription === false) {
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? {
                            ...section,
                            textDescription: '',
                        }
                        : section
                )
            );
        } else {
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? (() => {
                            const newObj = { ...section };
                            delete newObj.textDescription;
                            return newObj;
                        })()
                        : section
                )
            );
        }
    };

    return (
        <Box>
            {section.workoutSectionType !== 4 ? (
                <Stack direction="column" spacing={1}>
                    <Stack direction="row" spacing={1}>
                        <button
                            className={`white ${pace === true ? 'selected-add-modifier-button' : 'add-modifier-button white'}`}
                            onClick={handlePaceClick}
                        >
                            + Pace
                        </button>
                        <button
                            className={`white ${repeat === true ? 'selected-add-modifier-button' : 'add-modifier-button white'}`}
                            onClick={handleRepeatClick}
                        >
                            + Repeat
                        </button>
                        <button
                            className={`white ${textDescription === true ? 'selected-add-modifier-button' : 'add-modifier-button white'}`}
                            onClick={handleTextClick}
                        >
                            + Text Description
                        </button>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <button
                            className={`white ${section.heartRate ? 'selected-add-modifier-button' : 'add-modifier-button white'}`}
                        >
                            + Heart Zone
                        </button>
                    </Stack>
                </Stack>
            ):(
                <text className='light-grey very-small no-lifting-modifiers'>
                    No Lifting Modifiers
                </text>
            )}
        </Box>
    );
}
