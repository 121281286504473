import "../../../Styles/LoggedIn/LeftNavBar/LeftNavBar.css";
import React, { useState } from 'react'
import { Box, Button, Stack } from '@mui/material'
import TopButtons from "./TopButtons";
import ProfileButton from "./ProfileButton";
import NavButtons from "./NavButtons";
import ProfileModal from "./ProfileModal";

export default function LeftNavBar() {
    const [isProfileModal, setIsProfileModal] = useState(false);
    return (
        <div>
            <Stack direction='column'>
                <TopButtons />      
                <ProfileButton 
                    setIsProfileModal={setIsProfileModal}
                />     
                <NavButtons /> 
            </Stack>
            {isProfileModal && (
                <Box>
                    <ProfileModal 
                        isOpen={isProfileModal}
                        setIsProfileModal={setIsProfileModal}
                        onCancel={() => setIsProfileModal(false)}
                        />
                </Box>    
            )}
        </div>
    )
}
