import React, { useContext, useState, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import Modal from "react-modal";
import { collection, query, where, getDocs, setDoc, updateDoc, arrayUnion, doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { UserContext } from '../../../../context/AuthContext';
import { TeamContext } from '../../../../context/TeamContext';

export default function JoinTeam({ isOpen, onCancel, setIsJoinTeam }) {
    const { currentUser, userRole, setTeamID, allTeams, setAllTeams, setStatus } = useContext(UserContext);
    const { setTeamName, setTeamDoc, setMembers, setPendingMembers } = useContext(TeamContext);
    const [joinCodeString, setJoinCodeString] = useState('');
    const [allTeamsArray, setAllTeamsArray] = useState([]);
    const [errorMessage1, setErrorMessage1] = useState(false);

    useEffect(() => {
        const allTeamsArray = allTeams || [];
        setAllTeamsArray(allTeamsArray);
    });

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "400px",
            width: "100%",
        },
    };

    const handleJoinCodeChange = (event) => {
        const newJoinCode = event.target.value;
        setJoinCodeString(newJoinCode);
    };

    const joinTeam = async () => {
        try {
            const teamCollectionRef = collection(db, 'teams');
            const q = query(teamCollectionRef, where("joinCode", "==", joinCodeString))
            const querySnapshot = await getDocs(q);
            if (querySnapshot.size === 1) {
                const document = querySnapshot.docs[0]
                const teamDocID = document.id;
                const teamData = document.data();
                console.log("teamData: " + JSON.stringify(teamData));
                const pendingMembers = teamData.pendingMembers 
                console.log(JSON.stringify(pendingMembers));
                const teamName = teamData.teamName;
                const teamCreator = teamData.creatorName;
                const userDocData = [
                    ...allTeams,
                    {
                        teamID: teamDocID,
                        teamName: teamName,
                        status: 0,
                        creatorName: teamCreator,
                        joinCode: joinCodeString
                    }
                ]
                const newUserDocData = {
                    teams: userDocData,
                    selectedTeam: teamDocID 
                }
                const userDocRef = doc(db, "users", currentUser.userID);
                await setDoc(userDocRef, newUserDocData, { merge: true });
                const teamDocData = [
                    ...pendingMembers,
                    {
                        userID: currentUser.userID,
                        accountType: userRole,
                        email: currentUser.email,
                        firstName: currentUser.firstName,
                        lastName: currentUser.lastName,
                        subRosters: []
                    }
                ]
                const newTeamDocData = {
                    pendingMembers: teamDocData
                }
                
                await updateDoc(doc(db, "teams", teamDocID), newTeamDocData, { merge: true });
                setTeamName(teamName);
                setTeamID(teamDocID);
                setTeamDoc(teamData);
                setMembers(teamData.members);
                setPendingMembers(teamDocData);
                setStatus(0);
                setIsJoinTeam(false);
                setAllTeams(userDocData);
            } else {
                console.log('Document does not exist');
            }
        } catch (err) {
            console.error("There was an error", err)
        }
    };

    const handleClose = () => {
        onCancel();
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="join-team-content"
                overlayClassName="join-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
            >
                <Stack direction='column' spacing={1}>
                    {errorMessage1 && (
                        <text className='delete-red small'>
                            *Already on the specified team
                        </text>
                    )}
                    <text className='light-grey small'>Enter Join Code</text>
                    <Stack direction="row" spacing={1}>
                        <input
                            type="text"
                            placeholder="join code"
                            className='enter-join-code border-outline white small'
                            onChange={handleJoinCodeChange}
                        />
                        <button
                            className='join-team-button very-small submit-green small border-outline'
                            onClick={joinTeam}
                        >
                            Send Request
                        </button>
                    </Stack>
                    <div className='join-team-message very-small'>
                        Enter the join code for the team you want to join. You will be added to the pending members list until a team
                        manager accepts the request.
                    </div>
                </Stack>
            </Modal>
        </Box>
    )
}
