import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyCBvdTI4s6oVxp7OIdzyjsfjnD9IBsDBwg",
  authDomain: "slateproductiondatabase.firebaseapp.com",
  projectId: "slateproductiondatabase",
  storageBucket: "slateproductiondatabase.appspot.com",
  messagingSenderId: "658651043778",
  appId: "1:658651043778:web:a443bcba8c7c2725f7285b",
  measurementId: "G-Z12D230LRF"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

