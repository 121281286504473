//Passes Important information about the user throughout the app

import React, { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [allTeams, setAllTeams] = useState([]);
  const [teamID, setTeamID] = useState('');
  const [status, setStatus] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
        const data = docSnap.data();
          const userType = data.accountType;
          const allTeams = data.teams || [];
          if (allTeams[0]) {
            const teamID = allTeams[0].teamID;
            const status = allTeams[0].status;
            setTeamID(teamID)
            setStatus(status);
          }
          setAllTeams(allTeams);
          setCurrentUser({ 
            email: user.email,
            userID: user.uid,
            firstName: data.firstName,
            lastName: data.lastName,
            phoneNumber: data.phoneNumber,
          });
          setUserRole(userType);
          navigate("/")
        }
      } else {
        console.log("No User");
        setCurrentUser(null);
        setUserRole(null);
        navigate("/Slate-Fitness");
      }
    });

    return unsubscribe;
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, userRole, allTeams, setAllTeams, teamID, setTeamID, status, setStatus }}>
      {props.children}
    </UserContext.Provider>
  );
};
