import React, { useContext, useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import SwitchTeam from './SwitchTeam';
import DateAndTime from '../DateAndTime/DateAndTime';
import EventDescription from '../EventDetails/EventDescription';
import CreateEvent from '../SaveEvent/CreateEvent';
import { UserContext } from '../../../../../../context/AuthContext';

export default function TeamEvents({startDate,
                                    startTime,
                                    endDate,
                                    endTime,
                                    setStartDate,
                                    setStartTime,
                                    setEndDate,
                                    setEndTime,
                                    description,
                                    setDescription,
                                    onCreateEvent,
                                    onSaveAsDraft,
                                    selectedTeam, 
                                    setSelectedTeam,
                                    initialStartTime,
                                    initialEndTime
                                }) {
    const { allTeams } = useContext(UserContext);
    // useEffect(() => {
    //     console.log("allTeams: " + JSON.stringify(allTeams));
    // }, [allTeams])
    const handleSelectChange = (event) => {
        setSelectedTeam(event.target.value);
    } 
    return (
        <Box>
            <Stack direction="column" spacing={2}>
                <select className='switch-team-select white small-header more-padding' onChange={handleSelectChange} value={selectedTeam}>
                    {allTeams.map((team) => (
                        <option value={team.teamID}>{team.teamName}</option>
                    ))}
                </select>
                <DateAndTime 
                    startDate={startDate}
                    startTime={startTime}
                    endDate={endDate}
                    endTime={endTime}
                    setStartDate={setStartDate}
                    setStartTime={setStartTime}
                    setEndDate={setEndDate}
                    setEndTime={setEndTime}
                    initialStartTime={initialStartTime}
                    initialEndTime={initialEndTime}
                />
                <EventDescription 
                    description={description}
                    setDescription={setDescription}
                    />
                <CreateEvent 
                    onCreateEvent={onCreateEvent}
                    onSaveAsDraft={onSaveAsDraft}                
                />
            </Stack>
        </Box>
    )
}
