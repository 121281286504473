import { Stack, Box } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../../context/AuthContext';
import { ModalContext } from '../../../../context/ModalContext';
import { CalendarContext } from '../../../../context/EventContext';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db } from '../../../../firebase';

export default function SpecificDay({day, selectedDay, setSelectedDay, setShowEventCreation, whichEventView, setWhichEventView}) {
    const [isHovered, setIsHovered] = useState(false);
    const { userRole, currentUser } = useContext(UserContext);
    const { setIsUpdateTraining, setUpdateTrainingData, setIsUpdateEvent, setUpdateEventData } = useContext(ModalContext);
    const { detailPlans } = useContext(CalendarContext);

    const switchSelectedDay = () => {
        setSelectedDay(day.date);
    }
    const handleSetEventChange = () => {
        if (userRole === 1) {
            setWhichEventView("coach-options")
        } else {
            setWhichEventView(0);
        } 
        setShowEventCreation(true);
    } 
    const openUpdateEvent = async (item) => {
        console.log('item.startDate: ' + item.startDate);
        console.log("endDate: " + item.endDate);
        try {
            let updateDataObj = {...item}
            const eventID = item.eventID;
            if (item.linkedTeamID) {
                const teamID = item.linkedTeamID;
                const eventDocRef = doc(db, 'teams', teamID, 'events', eventID);
                const eventDocSnap = await getDoc(eventDocRef);
                if (eventDocSnap.exists()) {
                    const data = eventDocSnap.data();
                    const description = data.description;
                    updateDataObj = {...updateDataObj, description: description}
                    setUpdateEventData(updateDataObj);
                    setIsUpdateEvent(true);
                }    
            } else {
                const userID = currentUser.userID;
                const eventDocRef = doc(db, 'users', userID, 'events', eventID);
                const eventDocSnap = await getDoc(eventDocRef);
                if (eventDocSnap.exists()) {
                    const data = eventDocSnap.data();
                    const description = data.description;
                    updateDataObj = {...updateDataObj, description: description};
                    setUpdateEventData(updateDataObj);
                    setIsUpdateEvent(true);
                }
            }
        } catch (err) {

        }
    }
 
    const openUpdateTraining = async (item) => {
        try {
            console.log("item:  " + JSON.stringify(item));
            const workoutCreationTime = moment(item.createdAt.toDate());
            const linkedPlan = item.linkedPlanID;
            const linkedTeamID = item.linkedTeamID;
            const planDocRef = collection(db, 'teams', linkedTeamID, 'training plans');
            const querySnapshot = await getDocs(planDocRef);
            if (querySnapshot.size > 0) {
                let teamPlansArray = [];
                let updateTrainingDataObject = {};
                querySnapshot.forEach((doc) => {
                    const planData = doc.data();
                    const planLinkedTeamID = planData.linkedTeamID;
                    if (linkedTeamID === planLinkedTeamID) {
                        const planID = planData.planID;
                        const planTitle = planData.planTitle;
                        const description = planData.description;
                        const linkedTeamName = planData.linkedTeamName;
                        const planCreatorName = planData.planCreatorName;
                        const members = planData.members;
                        const planWorkouts = planData.planWorkouts;
                        const planSubRosters = planData.subRosters || [];
                        const teamPlan = {planID: planID, planTitle: planTitle, linkedTeamID: linkedTeamID, linkedTeamName: linkedTeamName, planCreatorName: planCreatorName, description: description, members: members, planWorkouts: planWorkouts, subRosters: planSubRosters}
                        teamPlansArray = [...teamPlansArray, teamPlan];  
                        if (linkedPlan === planID) {
                            planWorkouts.map((workout) => {
                                const workoutDate = moment(workout.workout.createdAt.toDate());
                                if (workoutCreationTime.isSame(workoutDate, 'second')) {
                                    const workoutSections = workout.workout.workoutSections;
                                    const workoutTitle = workout.workout.title;
                                    const workoutDescription = workout.workout.description;
                                    // console.log("WorkoutTitle: " + workoutTitle)
                                    // console.log("workoutDescription: " + workoutDescription)
                                    // console.log("WorkoutSEctions:" + JSON.stringify(workoutSections));
                                    updateTrainingDataObject = {workoutSections: workoutSections, title: workoutTitle, description: workoutDescription, createdAt: workoutCreationTime, planID: planID, planName: planTitle, teamName: item.linkedTeamName}        
                                }
                            })
                        }
                    }
                });
                updateTrainingDataObject = {...updateTrainingDataObject, teamPlans: teamPlansArray}
                console.log(updateTrainingDataObject);
                setUpdateTrainingData(updateTrainingDataObject);
                setIsUpdateTraining(true);
            }
        } catch (err) {
            console.error(err);
        }
    }
    return (
        <Stack 
            direction='column' 
            className={`specific-day-container ${isHovered && day.date !== selectedDay && 'hovered-day'}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={switchSelectedDay}
            >
            <Stack 
                direction='row' 
                justifyContent={`${day.date === selectedDay ? 'space-between' : 'right'}`}
                >
                {day.date === selectedDay && (
                    <button 
                        className='icon-button'
                        onClick={handleSetEventChange}
                        >
                        <EditIcon fontSize='small' style={{ color: "#7ACFF0" }} />
                    </button>
                )}
                <div>
                    {day.date === selectedDay ? (
                        <Stack alignItems='center' justifyContent='center' className='selected-indicator day-number-padding'>
                            <text className={`background-grey small mid-weight`}>
                                {day.day}
                            </text>
                        </Stack>
                    ) : (
                        <text className={`white small day-number-padding mid-weight`}>
                            {day.day}
                        </text>
                    )}
                </div>
            </Stack>
            <Stack className='events-container' direction='column' spacing={1}>
                {day.events.map((item) => (
                    <button className='text-button white specific-day-title small' onClick={() => openUpdateEvent(item)}>
                        {item.eventName}
                    </button>
                ))}
                {day.trainings.map((item) => (
                    <button className='text-button white specific-day-title small' onClick={() => openUpdateTraining(item)}>
                        {item.trainingName}
                    </button>
                ))}
            </Stack>
        </Stack>
    )
}
