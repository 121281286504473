import "../../../Styles/Icons/Icons.css";

const BikeIcon = ({color}) => {
    return (
        <svg fill={color} className="big-icon" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 635 357.03">
            <path fill={color} d="M289.83,245.44l-63.81,88.46c-4.78,6.62,3.17,15.01,9.7,10.25l142.08-103.69c2.56-1.87,
            3.57-5.22,2.61-8.2-.23-.72-.66-1.35-1.19-1.89l-71.83-74.05c-.52-.53-.95-1.16-1.18-1.87-1.5-4.56,1.76-9.54,
            6.66-9.55l121.3.91s.08,0,.12,0c5.25-.09,7.01-7.14,2.52-9.87-21.22-12.9-112.8-68.49-127.43-75.65-1.08-.53-2.29-.67-3.46-.42l-125.45,
            27.07c-.29.06-.59.09-.88.11-4.71.29-7.75,5.23-6.17,9.69.2.57.52,1.1.91,1.57l115.59,138.82c.29.35.56.73.73,1.16.97,2.35.67,5.08-.83,7.16Z"/>
            <path fill={color} d="M449,.53h-85.69c-8.76,0-13.09,10.64-6.82,16.75l81.41,79.42c8.97,8.75,22.43,11.25,33.79,5.97,12.89-6,27.18-19.3,
            30.81-48.64C509.5-2.47,449,.53,449,.53Z"/><circle cx="523" cy="243.03" r="111.5"/><circle cx="114.5" cy="242.53" r="114"/>
        </svg>
    )
}

export default BikeIcon

// import "../../../Styles/Icons/Icons.css";

// const BikeIcon = ({color}) => {
//     return (
//         <svg className='icon-size' fill={color} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1606 1325.84"><defs>
//             </defs><path fill={color} d="M1188.47,532.29c26.78.19,
//             48.6-21.46,48.6-48.25h0c0-26.65-21.6-48.25-48.25-48.25h-205.39l-135.19-208.93c-34.78-53.76-110.37-61.56-155.4-16.04l-246.11,
//             248.75c-48,48.51-36.95,129.34,22.3,163.2l253.84,145.05v261.71c0,
//             40.11,32.52,72.63,72.63,72.63h0c40.11,0,72.63-32.52,72.63-72.63v-331.94c0-32-17.64-61.39-45.87-76.44l-161.66-86.22,
//             163.71-154.49,78.55,124.52c9.97,15.8,27.29,25.44,45.97,25.57l239.63,1.74Z"/>
//             <circle fill={color} cx="944.23" cy="119.9" r="119.9" transform="translate(191.78 702.79) rotate(-45)"/>
//             <path fill={color} d="M330.88,664.07c-88.38,0-171.47,34.42-233.97,96.91C34.42,823.48,0,906.58,0,994.96s34.42,171.47,
//             96.91,233.97c62.5,62.5,145.59,96.91,233.97,96.91s171.47-34.42,233.97-96.91c62.5-62.5,96.91-145.59,
//             96.91-233.97s-34.42-171.47-96.91-233.97c-62.5-62.5-145.59-96.91-233.97-96.91ZM330.88,
//             1240.53c-135.41,0-245.57-110.16-245.57-245.57s110.16-245.57,245.57-245.57,245.57,110.16,245.57,245.57-110.16,
//             245.57-245.57,245.57Z"/><path fill={color} d="M1509.09,760.99c-62.5-62.5-145.59-96.91-233.97-96.91s-171.47,
//             34.42-233.97,96.91c-62.5,62.5-96.91,145.59-96.91,233.97s34.42,171.47,96.91,233.97c62.5,62.5,145.59,96.91,
//             233.97,96.91s171.47-34.42,233.97-96.91c62.5-62.5,96.91-145.59,96.91-233.97s-34.42-171.47-96.91-233.97ZM1275.12,
//             1240.53c-135.41,0-245.57-110.16-245.57-245.57s110.16-245.57,245.57-245.57,245.57,110.16,245.57,
//             245.57-110.16,245.57-245.57,245.57Z"/>
//         </svg>
//     )
// }

// export default BikeIcon
