import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../context/AuthContext';
import { TeamContext } from '../../../context/TeamContext';
import { ModalContext } from '../../../context/ModalContext';
import { Stack } from '@mui/material';
import TopPlanList from './TopPlanList/TopPlanList';
import TopPlan from './TopPlan/TopPlan';
import PlansList from './PlansList/PlansList';
import { doc, onSnapshot, collection, query, } from 'firebase/firestore';
import { db } from '../../../firebase';
import NewPlan from '../Logbook/Plans/NewPlan/NewPlan';
import CreateTeam from '../Teams/NewTeam/CreateTeam';
import NewTraining from '../Logbook/Plans/NewTraining/NewTraining';
import moment from 'moment';
import MonthlyTraining from './MonthlyCalendar/MonthlyTraining';
import WeeklyTraining from './WeeklyTraining/WeeklyTraining';
import UpdateTraining from './UpdateTraining/UpdateTraining';
import UpdatePlan from './UpdatePlan/UpdatePlan';

export default function TrainingManager() {
    const {currentUser, userRole, teamID, status} = useContext(UserContext);
    const { teamName, personalSubRosters } = useContext(TeamContext); 
    const { isCreatePlan, 
            setIsCreatePlan, 
            isCreateTeam, 
            setIsCreateTeam, 
            isAddTraining, 
            setIsAddTraining,
            isUpdateTraining,
            setIsUpdateTraining,
            whichTrainingView, 
            setWhichTrainingView,
            isUpdatePlan,
            setIsUpdatePlan
         } = useContext(ModalContext);
    const [teamPlans, setTeamPlans] = useState([]);
    const [personalPlans, setPersonalPlans] = useState([]);
    const [trainings, setTrainings] = useState([]);
    const [currentPlan, setCurrentPlan] = useState();

    const [targetDate, setTargetDate] = useState(moment().format("YYYY-MM-DD"))
    const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));
    const [whichEventView, setWhichEventView] = useState(null);
    const [allPlans, setAllPlans] = useState([]);

    useEffect(() => {
        const getTeamTrainings = async () => {
            if (teamID && status === 1) {
                // console.log('teamID: ' + teamID);
                const trainingsCollectionRef = collection(db, 'teams', teamID, 'training plans');
                const q = query(trainingsCollectionRef);
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    // console.log("querySnapShot  " + querySnapshot.size);
                    if (querySnapshot.size > 0) {
                        let newTrainings = [];
                        let teamPlansArray = [];
                        querySnapshot.forEach((doc) => {
                            const planData = doc.data();
                            const linkedTeamID = planData.linkedTeamID;
                            if (linkedTeamID === teamID) {
                                const planID = planData.planID;
                                const planTitle = planData.planTitle;
                                const description = planData.description;
                                const linkedTeamName = planData.linkedTeamName;
                                const planCreatorName = planData.planCreatorName;
                                const members = planData.members;
                                const planWorkouts = planData.planWorkouts;
                                const planSubRosters = planData.subRosters || [];
                                const teamPlan = {planID: planID, planTitle: planTitle, linkedTeamID: linkedTeamID, linkedTeamName: linkedTeamName, planCreatorName: planCreatorName, description: description, members: members, planWorkouts: planWorkouts, subRosters: planSubRosters}
                                teamPlansArray = [...teamPlansArray, teamPlan];
                                const docTrainings = planData.planWorkouts;
                                newTrainings.push(...docTrainings);    
                            }
                        });
                        // console.log("Team Trainings: :" + trainings);
                        setTrainings(newTrainings);
                        // console.log("TrainingPlans: " + JSON.stringify(teamPlansArray));
                        setTeamPlans(teamPlansArray)
                        if (teamPlansArray.length > 0) {
                            setCurrentPlan(0);
                        }
                    } else {
                        console.log("No documents found in the query snapshot.");
                    }
                });
                return () => unsubscribe()
            } 
        }
        getTeamTrainings();
    }, [teamID])

    useEffect(() => {
        if (userRole === 0) {
            const userTrainingCollectionRef = collection(db, 'users', currentUser.userID, 'training plans');
            const q = query(userTrainingCollectionRef);
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    if (querySnapshot.size > 0) {
                        let newTrainings = [];
                        let personalPlans = [];
                        querySnapshot.forEach((doc) => {
                            const planData = doc.data();
                            const planID = planData.planID;
                            const planTitle = planData.planTitle;
                            const description = planData.description;
                            const planCreatorName = planData.planCreatorName;
                            const planWorkouts = planData.planWorkouts;
                            console.log("planWorkouts: " + JSON.stringify(planWorkouts));
                            
                            const teamPlan = {planID: planID, planTitle: planTitle, linkedTeamID: null, planCreatorName: planCreatorName, description: description, planWorkouts: planWorkouts, subRosters: []}
                            personalPlans = [...personalPlans, teamPlan];
                            const docTrainings = planData.planWorkouts;
                            newTrainings.push(...docTrainings);
                        });
                        // console.log("Team Trainings: :" + trainings);
                        setTrainings(newTrainings);
                        // console.log("TrainingPlans: " + JSON.stringify(personalPlans));
                        setPersonalPlans(personalPlans)
                        if (personalPlans.length > 0) {
                            setCurrentPlan(0);
                        }
                    } else {
                        console.log("No documents found in the query snapshot.");
                    }
                })
                return () => unsubscribe();
        }
    }, [])

    useEffect(() => {
        const allPlans = [...teamPlans, ...personalPlans];
        const filteredPlans = allPlans.filter(plan => plan.linkedTeamID === teamID);
        if (userRole === 0) {
            let athletePlans = [];
            filteredPlans.forEach(plan => {
                console.log("personalSubRosters: " + personalSubRosters);
                console.log("planSubRosters: " + plan.subRosters);
                let matchFound = false;
                for (let i = 0; i < personalSubRosters.length; i++) {
                    console.log("I: " + personalSubRosters[i]);
                    if (plan.subRosters.includes(personalSubRosters[i])) {
                        // Match found, set the flag and break the loop
                        console.log("MATCH");
                        matchFound = true;
                        athletePlans.push(plan);
                        break;
                    }
                }
            });
            console.log("athletePlans: " + JSON.stringify(athletePlans));
            setAllPlans(athletePlans);
        } else {
            setAllPlans(filteredPlans);
        }
    }, [teamPlans, personalPlans, teamID, personalSubRosters, userRole]);
    



    useEffect(() => {

    }, [allPlans])

    return (
        <div className='training-manager-container'> 
            <Stack direction='column' className='padding-top'>
                <Stack direction='row'>
                    <TopPlanList 
                        teamPlans={teamPlans}
                        currentPlan={currentPlan}
                    />
                    <div className='vertical-border'/>
                    <TopPlan 
                        allPlans={allPlans}
                        currentPlan={currentPlan}    
                        whichTrainingView={whichTrainingView}
                        setWhichTrainingView={setWhichTrainingView} 
                    />
                </Stack>
                <div className='page-border-line'/>
                <Stack direction='row' className='training-manager-mid-section'>
                    <PlansList 
                        allPlans={allPlans}
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                        setIsCreatePlan={setIsCreatePlan}                   
                    />
                    <div className='full-vertical-border'/>
                    {teamPlans.length > 0 && currentPlan !== null && (
                        <div className='training-calendar-container'>
                            {whichTrainingView === 1 ? (
                                <MonthlyTraining 
                                    targetDate={targetDate}
                                    setTargetDate={setTargetDate}
                                    selectedDay={selectedDay}
                                    setSelectedDay={setSelectedDay}
                                    setIsAddTraining={setIsAddTraining}   
                                    whichEventView={whichEventView}
                                    setWhichEventView={setWhichEventView}    
                                    allPlans={allPlans}
                                    currentPlan={currentPlan}
                                    teamPlans={teamPlans}
                                />
                            ):(
                                <WeeklyTraining 
                                    targetDate={targetDate}
                                    setTargetDate={setTargetDate}
                                    selectedDay={selectedDay}
                                    setSelectedDay={setSelectedDay}
                                    setIsAddTraining={setIsAddTraining}   
                                    trainings={trainings}
                                    teamPlans={teamPlans}
                                    currentPlan={currentPlan}
                                />
                            )}
                        </div>
                    )}
                </Stack>
            </Stack>
            {isCreatePlan && (
                <NewPlan 
                    isOpen={isCreatePlan}
                    setIsCreatePlan={setIsCreatePlan}
                    onCancel={() => setIsCreatePlan(false)}
                    />
            )}         
            {isUpdatePlan && (
                <UpdatePlan 
                    isOpen={isUpdatePlan}
                    setIsUpdatePlan={setIsUpdatePlan}
                    onCancel={() => setIsUpdatePlan(false)}   
                    teamPlans={teamPlans}  
                    setTeamPlans={setTeamPlans}       
                />
            )}
            {isAddTraining && (
                <NewTraining
                    isOpen={isAddTraining}
                    setIsAddTraining={setIsAddTraining}
                    onCancel={() => setIsAddTraining(false)}
                    personalPlans={personalPlans}
                    teamPlans={teamPlans}
                    selectedDay={selectedDay}  
                    setSelectedDay={setSelectedDay} 
                    teamID={teamID}                         
                    teamName={teamName}
                    currentPlan={currentPlan}
                    />
            )}
            {isUpdateTraining && (
                <UpdateTraining
                    isOpen={isUpdateTraining}
                    setIsAddTraining={setIsUpdateTraining}
                    onCancel={() => setIsUpdateTraining(false)}
                    personalPlans={personalPlans}
                    teamPlans={teamPlans}
                    selectedDay={selectedDay}  
                    setSelectedDay={setSelectedDay} 
                    teamID={teamID}                         
                    teamName={teamName}
                    currentPlan={currentPlan}
                    />
            )}
            {isCreateTeam && (
                <CreateTeam
                    isOpen={isCreateTeam}
                    onCancel={() => setIsCreateTeam(false)}
                    setIsCreateTeam={setIsCreateTeam}
                />
            )}
        </div>
    )
}
