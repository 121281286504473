import "./../../../../Styles/LoggedIn/TrainingManager/TrainingManager.css";
import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Stack } from '@mui/material';
import WeeklyDay from "./WeeklyDay";


export default function WeeklyTraining({targetDate, setTargetDate, selectedDay, setSelectedDay, setIsAddTraining, trainings, teamPlans, currentPlan}) {
    const [currentWeeks, setCurrentWeeks] = useState([]);
    const [dates, setDates] = useState([]);

    useEffect(() => {
        const startWeekOne = moment(targetDate).startOf('week');
        const endWeekOne = startWeekOne.clone().add(6, 'days');
        const startWeekTwo = startWeekOne.clone().add(7, 'days');
        const endWeekTwo = startWeekOne.clone().add(14, 'days');

        // console.log("TeamPlans: " + teamPlans);
        // console.log('currentPlan: ' + currentPlan);
        const plan = teamPlans[currentPlan];

        // console.log("CurrentPlan Monthlytraining: " + plan);
        const trainings = plan.planWorkouts || [];
        // console.log(trainings);

        let currentWeeks = []
        for (let date = startWeekOne.clone(); date.isBefore(endWeekTwo); date.add(1, 'day')) {
            currentWeeks = [
                ...currentWeeks, 
                {
                    date: date.format("YYYY-MM-DD"),
                    day: date.format("D"),
                    trainings: [],
                  }
            ]
          }
          currentWeeks.map((day, index) => {
            trainings.map((training) => {
                const date = day.date;
                const trainingDate = moment(training.date.toDate()).format("YYYY-MM-DD");

                if (date === trainingDate) {
                    currentWeeks[index] = {
                        ...currentWeeks[index], 
                        trainings: [
                            ...currentWeeks[index].trainings,
                            training
                        ]
                    }
                }
            })
        })
        const dateData = {startWeekOne: startWeekOne, endWeekOne: endWeekOne, startWeekTwo: startWeekTwo, endWeekTwo: endWeekTwo}
        setCurrentWeeks(currentWeeks);
        setSelectedDay(targetDate);
        setDates(dateData)
        
    }, [targetDate, teamPlans, currentPlan])

    // useEffect(() => {
    //     console.log("currentWeeks: " + JSON.stringify(currentWeeks));
    // }, [currentWeeks])

    const openCreateTraining = (day) => {
        setSelectedDay(moment(day).format("YYYY-MM-DD"))
        setIsAddTraining(true);
    }
    return (
        <Stack direction='column' spacing={3}>
            <Stack direction='column' spacing={0.5} className="weekly-training-container twice-more-padding">
                <Stack direction='row' spacing={1}>
                    <text className='white small mid-weight'>{moment(dates.startWeekOne).format('MMMM DD')}</text>
                    <text className='light-grey small mid-weight'>to</text>
                    <text className='white small mid-weight'>{moment(dates.endWeekOne).format('MMMM DD')}</text>
                </Stack>
                <div className='page-border-line'/>
                <Stack direction='row' spacing={1} className='first-week-container tons-padding-bottom'>
                    {currentWeeks.slice(0,7).map((day) => {
                        const trainings = day.trainings;
                        // console.log(trainings);
                            return (    
                                <WeeklyDay 
                                    day={day}
                                    trainings={trainings}
                                    setSelectedDay={setSelectedDay}
                                    setIsAddTraining={setIsAddTraining}
                                    teamPlans={teamPlans}
                                />
                            )
                        }
                    )}
                </Stack>
            </Stack>
            <Stack direction='column' spacing={0.5} className="weekly-training-container twice-more-padding">
                <Stack direction='row' spacing={1}>
                    <text className='white small mid-weight'>{moment(dates.startWeekTwo).format('MMMM DD')}</text>
                    <text className='light-grey small mid-weight'>to</text>
                    <text className='white small mid-weight'>{moment(dates.endWeekTwo).format('MMMM DD')}</text>
                </Stack>
                <div className='page-border-line'/>
                <Stack direction='row' spacing={1} className='first-week-container tons-padding-bottom'>
                    {currentWeeks.slice(7,14).map((day) => {
                        const trainings = day.trainings
                        // console.log(trainings);
                            return (    
                                <WeeklyDay 
                                    day={day}
                                    trainings={trainings}
                                    setSelectedDay={setSelectedDay}
                                    setIsAddTraining={setIsAddTraining}
                                    teamPlans={teamPlans}
                                />
                            )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}
