import { Box } from '@mui/material'
import React from 'react'
import RunMetric from './Run/RunMetric'
import SwimMetric from './Swim/SwimMetric'

export default function SectionDetail({sections, setSections, currentSection, setCurrentSection}) {
    return (
        <Box className="workout-metrics-container">
            <RunMetric 
                sections={sections}
                setSections={setSections}
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
                />
        </Box>
    )
}
