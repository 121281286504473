import { Stack } from '@mui/material';
import React, { useContext } from 'react';
import { ModalContext } from '../../../../../context/ModalContext';
import { UserContext } from '../../../../../context/AuthContext';

export default function TopRosterList() {
    const { setIsCreateRoster } = useContext(ModalContext);
    const { userRole } = useContext(UserContext);

    const handleAddSubRoster = () => {
        setIsCreateRoster(true);
    }

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='baseline' className='roster-width margin-bottom padding'>
            <text className='light-grey small'>Rosters</text>
            {userRole === 1 && (
                <button className='white small text-button' onClick={handleAddSubRoster}>
                    +
                </button>
            )}
        </Stack>
    )
}
