import "../../../Styles/Icons/Icons.css";

import React, { useRef, useEffect } from "react";

const LiftIcon = ({color}) => {
    const svgRef = useRef(null);

    useEffect(() => {
        // Function to resize and center the viewBox based on the bounding box of the SVG content
        const resizeViewBox = () => {
            const svgElement = svgRef.current;
            const bbox = svgElement.getBBox();
            const viewBoxValue = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
            svgElement.setAttribute('viewBox', viewBoxValue);
        };

        // Call resizeViewBox on initial render and on window resize
        resizeViewBox();
        window.addEventListener('resize', resizeViewBox);
        return () => window.removeEventListener('resize', resizeViewBox);
    }, []);

    return (
        <svg ref={svgRef} className='icon-size' fill={color} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
            <defs></defs>
            <circle fill={color} cx="996.41" cy="414.15" r="157.5"/>
            <path fill={color} d="M1197.11,1174.55l-6.3-84.9h-388.8l-6.3,84.9-126.6,
            459c-15,55.2,26.4,109.8,83.7,109.8,19.8,0,38.4-6.9,53.1-18.3,14.7-11.4,
            26.1-27.9,30.9-47.1l124.5-486h70.2l124.5,486c4.8,19.2,16.2,35.7,30.9,47.1,14.7,
            11.4,33.3,18.3,53.1,18.3,57.3,0,98.7-54.6,83.7-109.8l-126.6-459Z"/>
            <path fill={color} d="M730.01,867.05c24.6,0,47.4,7.2,66.3,19.8h399.9c19.2-12.6,42-19.8,
            66.3-19.8s47.1,7.2,66.3,19.8c1.2.9,2.7,1.8,3.9,2.7v-105.6c0-101.7-82.5-184.2-184.2-184.2h-304.2c-101.7,
            0-184.2,82.5-184.2,184.2v105.6c1.2-.9,2.7-1.8,3.9-2.7,18.9-12.6,41.7-19.8,66-19.8Z"/>
            <path fill={color} d="M1665.16,879.46h-56.88v-39.52c0-18.9-13.43-34.22-30-34.22h-48.44c-16.57,
            0-30,15.32-30,34.22v106.91h-156.36c-15.12-29.49-45.49-49.88-80.9-49.88s-65.78,20.39-80.9,
            49.88h-370.62c-15.12-29.49-45.49-49.88-80.9-49.88s-65.78,20.39-80.9,
            49.88h-152.53v-106.91c0-18.9-13.43-34.22-30-34.22h-48.44c-16.57,0-30,15.32-30,34.22v39.52h-53.44c-16.57,
            0-30,15.32-30,34.22v149.09c0,18.9,13.43,34.22,30,34.22h53.44v39.52c0,18.9,13.43,34.22,30,34.22h48.44c16.57,
            0,30-15.32,30-34.22v-106.86h152.56c15.13,29.46,45.48,49.82,80.87,49.82s65.74-20.36,80.87-49.82h370.68c15.13,
            29.46,45.48,49.82,80.87,49.82s65.74-20.36,80.87-49.82h156.38v106.86c0,18.9,13.43,34.22,30,34.22h48.44c16.57,
            0,30-15.32,30-34.22v-39.52h56.88c16.57,0,30-15.32,30-34.22v-149.09c0-18.9-13.43-34.22-30-34.22Z"/>
        </svg>
    )
}

export default LiftIcon
