import "./../../../../../Styles/LoggedIn/Logbook/WorkoutCreation.css"
import React, { useEffect, useState } from 'react';
import RunIcon from "../../../../../media/icons/RunIcon/RunIcon";
import SwimIcon from "../../../../../media/icons/SwimIcon/SwimIcon";
import BikeIcon from "../../../../../media/icons/BikeIcon/BikeIcon";
import LiftIcon from "../../../../../media/icons/LiftIcon/LiftIcon";
import TuneIcon from '@mui/icons-material/Tune';
import { Box, Stack } from '@mui/material';

export default function TrainingSection({section, index, currentSection, setCurrentSection, sections, setSections}) {
    const [usedModifiers, setUsedModifiers] = useState([])
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    useEffect(() => {
        // Create a new array by filtering out values that are not 'none'
        const newModifierArray = Object.entries(section)
          .filter(([fieldName, fieldValue]) => fieldName === 'pace' || fieldName === 'repeat' || fieldName === 'textDescription')
          .map(([fieldName, fieldValue]) => ({fieldName: fieldName, fieldValue: fieldValue}));
    
        // Update the state with the new array
        setUsedModifiers(newModifierArray);
        if (section.pace) {
            const newPace = section.pace;
            const paceParts = newPace.split("'"); // Split at the single quote
            if (paceParts.length === 2) {
                // If pace has both minutes and seconds
                const newMinutes = parseInt(paceParts[0]);
                const newSeconds = parseInt(paceParts[1].slice(0, -1)); // Remove the last double quote
                setMinutes(newMinutes);
                setSeconds(newSeconds);
            } else if (paceParts.length === 1 && newPace.includes('"')) {
                // If pace has only seconds
                const newSeconds = parseInt(newPace.slice(0, -1)); // Remove the last double quote
                setMinutes(0);
                setSeconds(newSeconds);
            } else {
                // Invalid pace format
                setMinutes(0);
                setSeconds(0);
            }
        }
      }, [section]);

      const handleNewIndex = () => {
        setCurrentSection(index);
    }
    const validateDistance = (event) => {
        event.target.value = event.target.value.replace(/[^0-9.]/g, '');
        const value = event.target.value;
        const decimalCount = value.split('.').length - 1;
        if (decimalCount > 1) {
          event.target.value = value.replace(/\.+$/, '');
        }
      };
    const validateCalories = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '')
    }
    const validateDuration = (event) => {
        event.target.value = event.target.value.replace(/[^0-9:]/g, '');
    };
    const validateRepeat = (event) => {
        event.target.value = event.target.value.replace(/[^0-9-]/g, '');
        const value = event.target.value;
        const dashCount = value.split('-').length - 1
        if (dashCount > 1) {
            event.target.value = value.replace(/\-+$/, '')
        }
    };
    const validateMinutes = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
    const validateSeconds = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
        const value = event.target.value;
        const intValue = parseInt(value);
        if (intValue > 59) {
            event.target.value = '59'
        } 
    }
    const handleMetricChange = (event, currentMetricType) => {
        const newNumber = event.target.value;
        console.log(currentMetricType);
        const newSectionsArray = sections.map((item, index) =>
            index === currentSection
                ? { ...item, [currentMetricType]: newNumber } 
                : item
            );
        setSections(newSectionsArray);
    }   
    const handlePaceChange = (event, timeValue) => {
        if (timeValue === 'minutes') {
            const newMinutes = event.target.value;
            const newPace = `${newMinutes ? newMinutes : '0'}' ${parseInt(seconds) < 10 ? '0' + seconds : seconds}"`;
            console.log(newPace);
            setMinutes(newMinutes);
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? {
                            ...section,
                            pace: newPace,
                        }
                        : section
                )
            );
        } else {
            const newSeconds = event.target.value;
            const paddedSeconds = newSeconds.padStart(2, '0'); // Ensure seconds have two digits with leading zero if needed
            const newPace = `${minutes}' ${paddedSeconds}"`;
            console.log(newPace);
            setSeconds(newSeconds);
            setSections(prevSections =>
                prevSections.map((section, index) =>
                    index === currentSection
                        ? {
                            ...section,
                            pace: newPace,
                        }
                        : section
                )
            );
        }
    };
    
    const handleModifierChange = (event, type) => {
        const newValue = event.target.value;
        setSections(prevSections =>
            prevSections.map((section, index) =>
                index === currentSection
                ? {
                    ...section,
                    [type]: newValue,
                    }
                : section
            )
            );
    }

    const handleMovementNameChange = (event) => {
        const newName = event.target.value;
        setSections(prevSections =>
            prevSections.map((section, index) =>
                index === currentSection
                ? {
                    ...section,
                    movementName: newName,
                    }
                : section
            )
        );
    } 
    const handleMovementDescriptionChange = (event) => {
        const newDescription = event.target.value;
        setSections(prevSections =>
            prevSections.map((section, index) =>
                index === currentSection
                ? {
                    ...section,
                    movementDescription: newDescription,
                    }
                : section
            )
        );
    } 
    return (
        <Box>
            <Stack direction="column" onClick={handleNewIndex}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1} alignItems='center'>
                        {currentSection === index ? (
                            <div>
                                {section.workoutSectionType === 1 ? <RunIcon color={'#1CDF5E'}/> : 
                                 section.workoutSectionType === 2 ? <SwimIcon color={'#1CDF5E'}/> : 
                                 section.workoutSectionType === 3 ? <BikeIcon color={'#1CDF5E'}/> : 
                                 section.workoutSectionType === 4 ? <LiftIcon color={'#1CDF5E'}/> :
                                 null
                                }
                            </div>
                        ):(
                            <div>
                                {section.workoutSectionType === 1 ? <RunIcon color={'white'}/> : 
                                 section.workoutSectionType === 2 ? <SwimIcon color={'white'}/> : 
                                 section.workoutSectionType === 3 ? <BikeIcon color={'white'}/> : 
                                 section.workoutSectionType === 4 ? <LiftIcon color={'white'}/> :
                                 null
                                }
                            </div>
                        )}
                        <Stack direction='row' spacing={0.5} alignItems='center'>
                            {section.metricType === 2 && section.workoutSectionType !== 4 || section.metricType === 2 && section.workoutSectionType !== 5 ? (
                                <Stack direction='row' spacing={0.5} alignItems="center">
                                    <input 
                                        className='metric-input border-outline white'
                                        placeholder='0.00'
                                        value={`${section.distance ? section.distance : ''}`}
                                        onInput={validateDistance}
                                        onChange={(event) => {
                                            let currentMetricType = 'distance';
                                            handleMetricChange(event, currentMetricType); 
                                        }}
                                    />                                
                                </Stack>                        
                            ): section.metricType === 0 && section.workoutSectionType !== 4 || section.metricType === 0 && section.workoutSectionType !== 5 ? (
                                <Stack direction='row' spacing={0.5} alignItems="center">
                                    <input 
                                        className='metric-input border-outline white'
                                        placeholder='0'
                                        value={section.calories}
                                        onInput={validateCalories}
                                        onChange={(event) => {
                                            let currentMetricType = 'calories';
                                            handleMetricChange( event, currentMetricType); 
                                        }}
                                    />                                
                            </Stack>                            
                            ): section.metricType === 1 ? (
                                <Stack direction='row' spacing={0.5} alignItems="center">
                                    <input 
                                        className='metric-input border-outline white'
                                        placeholder='00:00'
                                        value={section.duration}
                                        onInput={validateDuration}
                                        onChange={(event) => {
                                            let currentMetricType = 'duration';
                                            handleMetricChange(event, currentMetricType); 
                                        }}
                                    />                                
                                </Stack>
                            ): section.metricType !== 0 || section.metricType !== 1 || section.metricType !== 2 ? (
                                <Stack direction='column' className="lift-card-container">
                                    <input 
                                        type='text'
                                        placeholder="Movement Name"
                                        className="movement-name-input border-outline white very-small"
                                        onChange={handleMovementNameChange}
                                        value={section.movementName}
                                    />
                                    <div className="lift-border-line"/>
                                    <textarea 
                                        placeholder="// Add Movement Details"
                                        className="white"
                                        onChange={handleMovementDescriptionChange}
                                        value={section.movementDescription}
                                    />
                                </Stack>
                            ) : null}
                            {section.metricType === 2 ? (
                                <text className='white small'>
                                    {section.unit === 2 && 'miles'}
                                    {section.unit === 1 && 'kilometers'}
                                    {section.unit === 3 && 'meters'}
                                    {section.unit === 4 && 'yards'}
                                    {section.unit === 5 && 'laps'}
                                </text>
                            ) : section.metricType === 1 ? (
                                <text className='white small'>
                                    time
                                </text>                    
                            ) : section.metricType === 0 ? (
                                <text className='white small'>
                                    calories
                                </text>
                            ) : null}
                        </Stack>
                    </Stack>
                    <button 
                        className='metric-input-button border-outline'
                        onClick={handleNewIndex}
                        >
                        {currentSection === index ? (
                           <div>
                                <TuneIcon className='submit-green tune-icon' style={{fontSize: '1rem'}}/>
                            </div>
                        ):(
                            <div>
                                <TuneIcon className='white tune-icon' style={{fontSize: '1rem'}}/>
                            </div>
                        )}
                    </button> 
                </Stack>
                    <Box>
                        {usedModifiers.map((field, modifierIndex) => (
                            field.fieldValue !== 'none' && (
                                <Box  key={`run-section-modifier-box-${index}-${modifierIndex}`}>
                                    {modifierIndex === 0 ?  <div className='connector-stem'/> :
                                                            <div className='second-connector-stem'/>
                                    }
                                    <div className='modifier-container'>
                                        <Stack style={{marginLeft: "2.25rem", paddingTop: '-0.25rem'}} direction='row' spacing={0.5} alignItems='center'>
                                            {modifierIndex=== 0 ? <div className='first-vertical-stem'/> :
                                                                  <div className='second-vertical-stem'/>
                                            }
                                            <div className='horizonal-stem'/>
                                            <Stack direction='column'>
                                                <div className='placeholder-metric'/>
                                                <Stack direction='row' spacing={0.5} alignItems='center' className='metric-top-padding'>
                                                    {field.fieldName === 'pace' ? (
                                                        <Stack direction='row' className='pace-container padding'>
                                                             <input
                                                                className='pace-input white'
                                                                placeholder='00'
                                                                maxLength="2"
                                                                onClick={(e) => e.target.select()} 
                                                                onChange={(event) => {
                                                                    let timeValue = 'minutes';
                                                                    handlePaceChange(event, timeValue);
                                                                }}
                                                                onInput={validateMinutes}
                                                                value={minutes}
                                                            />
                                                            <text className='white layer'>'</text>
                                                            <input
                                                                className='pace-input white'
                                                                placeholder='00'
                                                                maxLength="2"
                                                                onClick={(e) => e.target.select()} 
                                                                onChange={(event) => {
                                                                    let timeValue = 'seconds';
                                                                    handlePaceChange(event, timeValue);
                                                                }}
                                                                onInput={validateSeconds}
                                                                value={seconds}
                                                            />
                                                            <text className="white top-layer">"</text>
                                                        </Stack>
                                                    ) : field.fieldName === "repeat" ?(
                                                        <input 
                                                            className='metric-input border-outline white'
                                                            placeholder='2-3'
                                                            onChange={(event) => {
                                                                const type = 'repeat';
                                                                handleModifierChange(event, type);
                                                            }}
                                                            onInput={validateRepeat}
                                                            value={section.repeat}
                                                        />                                                         
                                                    ) : (
                                                        <textarea 
                                                            className='metric-input white text-description'
                                                            placeholder='description'
                                                            onChange={(event) => {
                                                                const type = 'textDescription';
                                                                handleModifierChange(event, type);
                                                            }}
                                                            value={section.textDescription}
                                                        />

                                                    )}
                                                    {field.fieldName === 'pace' ? (
                                                        <text className="white small">
                                                            {section.unit === 1 ? '/km' : 
                                                             section.unit === 3 && section.workoutSectionType === 2 ? '/100m' : 
                                                             section.unit === 3 && section.workoutSectionType !== 2 ? '/400m' :
                                                             section.unit === 5 ? 'sec / lap' :
                                                             section.unit === 4 ? '/100yd' : 
                                                             section.unit === 2 ? '/mi' :
                                                             section.unit === 6 ? 'mph' :
                                                             null}
                                                        </text>
                                                    ) : field.fieldName === 'repeat' ? (
                                                        <text className='white small'>
                                                            repeat
                                                        </text>
                                                    ) : field.fieldName === 'handleCaloriesChange' ? (
                                                        <text className='white small'>
                                                            Heart Rate Zone
                                                        </text>
                                                    ) : (
                                                        null
                                                    )}
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </div>
                                </Box>
                            )
                        ))}
                    </Box>
            </Stack>
        </Box>
    )
}
