import "./../../../../Styles/LoggedIn/Teams/Roster.css";
import { Stack, Box } from '@mui/material';
import React, { useContext } from 'react';
import CurrentRoster from './CurrentRoster';
import { ModalContext } from "../../../../context/ModalContext";
import TopRosterList from "./RosterList/TopRosterList";
import RosterList from "./RosterList/RosterList";
import NewSubRoster from "./NewSubRoster.js/NewSubRoster";
import UpdateSubRoster from "./NewSubRoster.js/UpdateSubRoster";
import PendingModal from "./PendingMembers/PendingModal";

export default function Roster() {
    const { isCreateRoster, setIsCreateRoster, isUpdateRoster, setIsUpdateRoster, isAllRoster, setIsAllRoster, isPendingRoster, setIsPendingRoster } = useContext(ModalContext);

    return (
        <div>
            <Stack direction='column' className='padding-top rosters-container'>
                <Stack direction='row'>
                    <TopRosterList />
                    <div className='vertical-border'/>
                    <Stack direction='row' justifyContent='center' alignItems='center' className='members-width'>
                        <text className='light-grey small'>Members</text>
                    </Stack>
                    <div className='vertical-border'/>
                </Stack>
                <div className="page-border-line"/>
                <RosterList />
            </Stack>
            {isPendingRoster && (
                <PendingModal 
                    isOpen={isPendingRoster}
                    setIsPend={setIsPendingRoster}
                    onCancel={() => setIsPendingRoster(false)}            
                />
            )}
            {isAllRoster && (
                <CurrentRoster
                    isOpen={isAllRoster}
                    setIsAllRostser={setIsAllRoster}
                    onCancel={() => setIsAllRoster(false)}
                 />
            )}
            {isCreateRoster && (
                <NewSubRoster 
                    isOpen={isCreateRoster}
                    setIsCreateRoster={setIsCreateRoster}
                    onCancel={() => setIsCreateRoster(false)}
                />
            )}
            {isUpdateRoster && (
                <UpdateSubRoster 
                    isOpen={isUpdateRoster}
                    setIsUpdateRoster={setIsUpdateRoster}
                    onCancel={() => setIsUpdateRoster(false)}
                />
            )}
        </div>
    )
}
