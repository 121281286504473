import React from 'react'
import SmallEventCreation from './SmallEventCreation'
import Modal from 'react-modal';

export default function EventModal({ isOpen, onCancel, setShowEventCreation, whichEventView, setWhichEventView, selectedDay}) {

    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "25rem",
          width: "100%",
        },
      };

    const handleClose = () => {
        onCancel();
      };
    return (
        <div>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                    <SmallEventCreation 
                        setShowEventCreation={setShowEventCreation}
                        whichEventView={whichEventView}
                        setWhichEventView={setWhichEventView}
                        selectedDay={selectedDay}
                        />
            </Modal>
        </div>
    )
}
