import React, { useContext } from 'react';
import { TeamContext } from '../../../../../../context/TeamContext';
import { UserContext } from '../../../../../../context/AuthContext';
import { Box, Stack } from '@mui/material';

export default function CoachOption({setWhichEventView}) {
    const { teamName } = useContext(TeamContext);
    const { teamID } = useContext(UserContext);
    return (
        <Box>
            <Stack direction="column" spacing={1}>
                {teamID && teamName && (
                    <Stack direction='column' spacing={1}>
                        <button 
                            className='text-button ocean-blue small-header mid-weight'
                            onClick={() => setWhichEventView(1)}
                            >
                            Team +
                        </button>
                        <div className='page-border-line'/>
                    </Stack>
                )}
                <Box>
                    <button 
                        className='text-button ocean-blue small-header mid-weight'
                        onClick={() => setWhichEventView(0)}
                        >
                        Personal +
                    </button>
                </Box>
                <div className='page-border-line'/>
            </Stack>
        </Box>
    )
}
