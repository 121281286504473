import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

export default function EndTimeChange({endTime, onEndTimeChange, initialEndTime}) {
    const [hours, setHours] = useState("");
    const [minutes, setMinutes] = useState("");
    const [amPm, setAmPm] = useState("");

    useEffect(() => {
        const hours = moment(initialEndTime, "hh:mm A").format("hh");
        const minutes = moment(initialEndTime, "hh:mm A").format("mm");
        const amPm = moment(initialEndTime, "hh:mm A").format("A");
    
        setHours(hours);
        setMinutes(minutes);
        setAmPm(amPm);
    }, [initialEndTime]); 

    const validateHour = (event) => {
        // Remove any non-numeric characters
        event.target.value = event.target.value.replace(/[^0-9]/g, '');

        // Ensure the value does not exceed the maximum allowed value
        const maxAllowedValue = 12;
        const currentValue = parseInt(event.target.value, 10);
        if (currentValue > maxAllowedValue) {
            event.target.value = maxAllowedValue;
        }
    };

    const validateMinute = (event) => {
        // Remove any non-numeric characters
        event.target.value = event.target.value.replace(/[^0-9]/g, '');

        // Ensure the value does not exceed the maximum allowed value
        const maxAllowedValue = 59;
        const currentValue = parseInt(event.target.value, 10);
        if (currentValue > maxAllowedValue) {
            event.target.value = maxAllowedValue;
        }
    };

    useEffect(() => {
        const newEndTime = `${hours}:${minutes} ${amPm}` 
        onEndTimeChange(newEndTime);
    }, [hours, minutes, amPm])

    const hanldleHoursChange = (event) => {
        const newHours = event.target.value;
        setHours(newHours);
    }
    const handleMinutesChange = (event) => {
        const newMinutes = event.target.value;
        setMinutes(newMinutes);
    }
    const handleAmPmChange = (event) => {
        const newAmPm = event.target.value;
        setAmPm(newAmPm);
    }

    return (
        <Box
            className="time-change-container"
            sx={{
                display: "flex",
                padding: "4px",
                borderRadius: "0.375rem",
                backgroundColor: "#222122",
                height: "1rem",
                alignItems: "center"
            }}
        >
            <input 
                className='hours-input time-change-input'
                placeholder='hh'
                maxLength="2"
                value={hours}
                onChange={hanldleHoursChange}
                onInput={validateHour} // Add the validation function to the "onInput" event
                onClick={(e) => e.target.select()} // Selects the text on click
            />
            <text className='white small'>:</text>
            <input
                className='minute-input time-change-input'
                placeholder='mm'
                maxLength="2"
                value={minutes}
                onChange={handleMinutesChange}
                onInput={validateMinute} // Add the validation function to the "onInput" event
                onClick={(e) => e.target.select()} // Selects the text on click
            />
            <select
                className='am-pm-selector'
                value={amPm}
                onChange={handleAmPmChange}
                >
                <option value="AM">am</option>
                <option value="PM">pm</option>
            </select>
        </Box>
    )
}
 