import './../../../../Styles/LoggedIn/TrainingManager/PlansList.css';
import React, { useContext, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { UserContext } from '../../../../context/AuthContext';
import { ModalContext } from '../../../../context/ModalContext';
import EastIcon from '@mui/icons-material/East';

export default function PlansList({allPlans, currentPlan, setCurrentPlan, setIsCreatePlan}) {
    const { setIsCreateTeam, setUpdatePlanData, setIsUpdatePlan, setUpdatePlanIndex } = useContext(ModalContext);
    const { userRole, teamID } = useContext(UserContext);

    const handleCurrentPlanChange = (index) => {
        // console.log("set Index: " + JSON.stringify(index));
        setCurrentPlan(index);
    }

    const openUpdatePlan = (plan, index) => {
        console.log("Plan: " + JSON.stringify(plan));
        setUpdatePlanData(plan);
        setUpdatePlanIndex(index)
        setIsUpdatePlan(true);
    }
    return (
        <Stack className='plans-list-container'>
            {allPlans.length > 0 && (
                allPlans.map((plan, index) => (
                    <div onClick={() => handleCurrentPlanChange(index)}>
                        <Stack direction='column' spacing={0.5} className={`padding-left padding-right padding-top more-padding-bottom ${currentPlan === index && 'selected-plan'}`}>
                            <Stack direction='row' alignItems='baseline' justifyContent='space-between'>
                                <text className='small white'>{plan.planTitle}</text>
                                {currentPlan === index ? (
                                    <text className='submit-green really-small'>Viewing</text>
                                ):(
                                    <button className='text-button really-small white border-outline' onClick={() => handleCurrentPlanChange(index)}>
                                        <span className='underline'>{`Open`}</span>
                                    </button>
                                )}
                            </Stack>
                            <Stack direction='row' justifyContent='space-between'>
                                <Stack direction='column' spacing={0.5}>
                                    {plan.members && (
                                        <text className='light-grey really-small'>
                                            {(plan.members).length} members
                                        </text>
                                    )}
                                    <text className="light-grey really-small">
                                        {plan.description}
                                    </text>
                                </Stack>
                                {userRole === 1 && plan.linkedTeamID && (
                                    <button className='icon-button' onClick={() => openUpdatePlan(plan, index)}>
                                        <EastIcon style={{ fontSize: '1rem', color: 'white' }}/>
                                    </button>
                                )}
                                {userRole === 0 && plan.linkedTeamID === null && (
                                    <button className='icon-button' onClick={() => openUpdatePlan(plan, index)}>
                                        <EastIcon style={{ fontSize: '1rem', color: 'white' }}/>
                                    </button>
                                )}
                            </Stack>
                        </Stack>
                    </div>
                )
            ))}
            {/* {userRole === 0 && personalPlans.length > 0 && personalPlans.map((plan, index) => (
                <div onClick={() => handleCurrentPlanChange(index)}>
                    <Stack direction='column' spacing={0.5} className={`padding-left padding-right padding-top more-padding-bottom ${currentPlan === index && 'selected-plan'}`}>
                        <Stack direction='row' alignItems='baseline' justifyContent='space-between'>
                            <text className='small white'>{plan.planTitle}</text>
                            {currentPlan === index ? (
                                <text className='submit-green really-small'>Viewing</text>
                            ):(
                                <button className='text-button really-small white border-outline' onClick={() => handleCurrentPlanChange(index)}>
                                    <span className='underline'>{`Open`}</span>
                                </button>
                            )}
                        </Stack>
                        <Stack direction='row' justifyContent='space-between'>
                            <Stack direction='column' spacing={0.5}>
                                <text className="light-grey really-small">
                                    {plan.description}
                                </text>
                            </Stack>
                            <button className='icon-button' onClick={() => openUpdatePlan(plan, index)}>
                                <EastIcon style={{ fontSize: '1rem', color: 'white' }}/>
                            </button>
                        </Stack>
                    </Stack>
                </div>
            ))} */}
            {userRole === 1 && allPlans.length === 0 && (
                <div className='padding'>
                    {teamID && userRole === 1 ? (
                        <button className='suggest-create-team submit-green very-small' onClick={() => setIsCreatePlan(true)}>Create New Plan</button>
                    ): !teamID && userRole === 1 ? (
                        <button className='suggest-create-team submit-green very-small' onClick={() => setIsCreateTeam(true)}>Create New Team First</button>
                    ): null}
                </div>
            )}
            {userRole === 1 && !teamID && (
                <text className='update-purple padding'>To access training plans, you must first make a team.</text>
            )}
            {userRole === 0 && allPlans.length === 0 &&  (
                <text className='very-small update-purple padding'>Join a team to access your coaches training plans!</text>
            )}
            
        </Stack>
    )
}
