import { Stack } from "@mui/material";
import "./../../../Styles/LoggedOut/LandingPage/LandingPage.css";
import React from 'react';

export default function LandingPage() {
    return (
        <Stack direction='row' justifyContent='center' alignItems='center' className="landing-page-container">
            <text className='white'>Slate Fitness</text>
        </Stack>
    )
}
