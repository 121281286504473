import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../../../../context/AuthContext';
import { TeamContext } from '../../../../../context/TeamContext';
import { CalendarContext } from '../../../../../context/EventContext';
import { Box, Stack } from '@mui/material';
import { setDoc, doc, addDoc, collection, Timestamp, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import TopButtons from './TopButtons';
import EventName from './EventName/EventName';
import PersonalEvent from './PersonalEvent/PersonalEvent';
import CoachOption from './CoachOption/CoachOption';
import TeamEvents from './TeamEvents/TeamEvents';
 
export default function SmallEventCreation({setShowEventCreation, whichEventView, setWhichEventView, selectedDay}) {

    const { currentUser, teamID } = useContext(UserContext);
    const { teamName } = useContext(TeamContext);
    const { allEvents, setAllEvents } = useContext(CalendarContext);
    const [eventName, setEventName] = useState('');
    const [initialStartTime, setInitialStartTime] = useState(moment());
    const [startDate, setStartDate] = useState(moment(selectedDay).format("YYYY-MM-DD"));
    const [startTime, setStartTime] = useState(moment().format("hh:mm A"));
    const [initialEndTime, setInitialEndTime] = useState(moment().add(1, 'hour'));
    const [endDate, setEndDate] = useState(moment(selectedDay).format("YYYY-MM-DD"));
    const [endTime, setEndTime] = useState(moment().add(1, 'hour').format("hh:mm A"));
    const [repeat, setRepeat] = useState('Never');
    const [linkedCalendar, setLinkedCalendar] = useState('Personal')
    const [selectedTeam, setSelectedTeam] = useState(teamID);
    const [linkedWorkout, setLinkedWorkout] = useState(null);
    const [eventTags, setEventTags] = useState (null);
    const [description, setDescription] = useState('');
    

    // const handleHideEvent = () => {
    //     const change = false;
    //     setShowEventCreation(change)
    // }
    const handleCreateEvent = async () => {
        const allEventsArray = allEvents;
        const startDateTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD hh:mm A');
        const endDateTime = moment(`${endDate} ${endTime}`, 'YYYY-MM-DD hh:mm A');
        const startTimeStamp = Timestamp.fromDate(startDateTime.toDate());
        const endTimeStamp = Timestamp.fromDate(endDateTime.toDate());
        const creationDate = moment();
        const creationTimeStamp = Timestamp.fromDate(creationDate.toDate());
        if (whichEventView === 0) {
            try {
                const userDocRef = doc(db, 'users', currentUser.userID);
                const userDocSnap = await getDoc(userDocRef);
                if (userDocSnap.exists()) {
                    const data = {
                        createdAt: creationTimeStamp,
                        title: eventName,
                        description: description,
                        startDate: startTimeStamp,
                        endDate: endTimeStamp,
                    }
                    const newEvent = await addDoc(collection(db, 'users', currentUser.userID, 'events'), data);
                    const eventID = newEvent.id;
                    const userDocData = userDocSnap.data();
                    const userEvents = userDocData.events || [];
                    const updatedUserEvents = [...userEvents, {
                        createdAt: creationTimeStamp,
                        eventID: eventID,
                        eventName: eventName,
                        startDate: startTimeStamp,
                        endDate: endTimeStamp,
                    }];
                    const newUserData = {
                        events: updatedUserEvents
                    }
                    const userEventContext = [
                        ...allEvents,
                        {   
                            createdAt: creationTimeStamp,
                            eventID: eventID, 
                            eventName: eventName,
                            startDate: startTimeStamp,
                            endDate: endTimeStamp,
                        }
                    ]
                    setAllEvents(userEventContext);
                    await setDoc(doc(db, 'users', currentUser.userID), newUserData, { merge: true });
                    setShowEventCreation(false);
                    setWhichEventView(null);    
                } else {
                    console.log("user doesn't exist")
                }
                
            
            } catch (err) {
                console.error(err)
            }
        } else {
            try {
                const teamDocRef = doc(db, 'teams', selectedTeam);
                const teamDocSnap = await getDoc(teamDocRef);
                if (teamDocSnap.exists()) {
                    const teamData = teamDocSnap.data();
                    const teamEventsArray = teamData.teamEvents || [];
                    const data = {
                        createdAt: creationTimeStamp,
                        title: eventName,
                        description: description,
                        startDate: startTimeStamp,
                        endDate: endTimeStamp,
                        linkedTeamID: selectedTeam,
                        linkedTeamName: teamName,
                    }
                    const newEvent = await addDoc(collection(db, 'teams', selectedTeam, 'events'), data);
                    const eventID = newEvent.id;
                    const updatedTeamEventsArray = [...teamEventsArray, {
                        createdAt: creationTimeStamp,
                        eventID: eventID,
                        eventName: eventName,
                        startDate: startTimeStamp,
                        endDate: endTimeStamp,
                        linkedTeamID: selectedTeam,
                        linkedTeamName: teamName,
                    }];
                    const newTeamData = {
                        teamEvents: updatedTeamEventsArray
                    }
                    const userEventContext = [
                        ...allEvents,
                        {
                            createdAt: creationTimeStamp,
                            eventID: eventID, 
                            eventName: eventName,
                            startDate: startTimeStamp,
                            endDate: endTimeStamp,
                            linkedTeamID: selectedTeam,
                            linkedTeamName: teamName,
                        }
                    ]
                    setAllEvents(userEventContext);
                    await updateDoc(teamDocRef, newTeamData);
                    setShowEventCreation(false);
                    setWhichEventView(null);    
                } else {
                    console.log("No Team")
                }
            } catch (err) {
                console.error(err)
            }
        } 
    }
    const handleSaveAsDraft = async () => {
        setShowEventCreation(false);
        setWhichEventView(null);
    }

    return (
        <Box>
            <Stack direction="column">
                <EventName 
                    eventName={eventName}
                    setEventName={setEventName}
                    /> 
                {whichEventView === 'coach-options' && (
                    <CoachOption 
                        setWhichEventView={setWhichEventView}
                        />
                )}
                {whichEventView === 0 && (
                    <PersonalEvent 
                        startDate={startDate}
                        startTime={startTime}
                        endDate={endDate}
                        endTime={endTime}
                        setStartDate={setStartDate}
                        setStartTime={setStartTime}
                        setEndDate={setEndDate}
                        setEndTime={setEndTime}
                        repeat={repeat}
                        setRepeat={setRepeat}
                        linkedCalendar={linkedCalendar}
                        setLinkedCalendar={setLinkedCalendar}
                        description={description}
                        setDescription={setDescription}
                        onCreateEvent={handleCreateEvent}
                        onSaveAsDraft={handleSaveAsDraft}
                        initialStartTime={initialStartTime}
                        initialEndTime={initialEndTime}
                    />
                )}

                {[1, 2, 3].includes(whichEventView) && (
                    <TeamEvents 
                        startDate={startDate}
                        startTime={startTime}
                        endDate={endDate}
                        endTime={endTime}
                        setStartDate={setStartDate}
                        setStartTime={setStartTime}
                        setEndDate={setEndDate}
                        setEndTime={setEndTime}
                        description={description}
                        setDescription={setDescription}
                        onCreateEvent={handleCreateEvent}
                        onSaveAsDraft={handleSaveAsDraft}
                        selectedTeam={selectedTeam}
                        setSelectedTeam={setSelectedTeam}
                        initialStartTime={initialStartTime}
                        initialEndTime={initialEndTime}
                    />
                )}
            </Stack>
        </Box>
    )
}
