import { Box, Stack } from '@mui/material'
import React from 'react'
import EntryIcon  from '../../../../media/icons/EntryIcon/EntryIcon';
import RunIcon from '../../../../media/icons/RunIcon/RunIcon';
import Clipboard from "../../../../media/icons/Clipboard.png";
import TrainingPlanIcon from '../../../../media/icons/TrainingPlanIcon/TrainingPlanIcon';



export default function LogbookNav({ whichLogbookView, setWhichLogbookView, setIsWorkoutModal }) {
    const isSelected = (tab) => whichLogbookView === tab;

    const handleCreateWorkoutClick = () => {
        setIsWorkoutModal(true);
    }
    return ( 
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={3} alignItems="center">
                    <button 
                        className={`text-button white small ${isSelected("plans") ? 'selected-tab' : ''}`}
                        onClick={() => setWhichLogbookView("plans")}
                        >
                        <Stack direction="row" spacing={0.5}>
                            <TrainingPlanIcon color={'white'} />
                            <text className='white small'>
                                Plans
                            </text>
                        </Stack>
                    </button>
                    <button 
                        className={`text-button white small ${isSelected("workouts") ? 'selected-tab' : ''}`}
                        onClick={() => setWhichLogbookView("workouts")}
                        >
                        <Stack direction="row" spacing={0.5}>
                            <RunIcon color={'white'} />
                            <text className='white small'>
                                Workouts
                            </text> 
                        </Stack>
                    </button>
                </Stack>
                {whichLogbookView === "workouts" && (
                    <button 
                        className='white small outline-button'
                        onClick={handleCreateWorkoutClick}
                    >
                        Log Manual Workout +
                    </button>
                )}
            </Stack>
        </Box>
    )
}
