import { Box, Stack } from '@mui/material'
import React from 'react'

export default function WeeklySummary() {
    return (
        <Box>
            
        </Box>
    )
}
