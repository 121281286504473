//Consistent Stlye templates for entire app

import React from "react";
import { Typography, Box, styled, TextField, Button } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const WhiteTypography = styled(Typography)({
    color: '#FFF',
    fontSize: '1.25rem',
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal"
  });

export const WhiteTypographySmall = styled(Typography)({
  color: "#FFF",
  fontSize: "0.75rem",
  fontStyle: "normal",
  fontWeight: "520",
  lineHeight: "normal",
  letterSpacing: "-0.01rem"
  });
  export const WhiteTypographyMedium = styled(Typography)({
    color: '#FFF',
    fontSize: '1rem',
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal"
  });
  export const GreyTypographySmall = styled(Typography)({
    color: '#8A8A8A',
    fontSize: '0.875rem',
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal"
  });
  export const GreyTypographyMedium = styled(Typography)({
    color: '#8A8A8A',
    fontSize: '1rem',
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "-0.015"
  });
  export const MonthlyDateTypography = styled(Typography)({
    color: '#8A8A8A',
    fontSize: '1rem',
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "-0.01875"
  });
export const NavTypography = styled(Typography)({
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: "600",
  fontFamily: "Inter"
});
export const TypographySmall = styled(Typography)({
    fontSize: '1rem',
});
export const TopPageTypography = styled(Typography)({
  color: '#FFF',
  fontSize: '2.25rem',
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "normal",
  letterSpacing: "-0.03375"
});

export const PageContainer = styled("div")({
    paddingTop: "8vh"
});
export const WhiteTextField = styled(TextField)({
    backgroundColor: "#7ACFF0",
    borderRadius: "4px",
    outline: "1px solid grey"
})
export const IconButton = styled(Button)({
    width: "auto",
    minWidth: 0,
  });
export const LogbookNavButton = styled(Button)({
  border: "none",
  outline: "none",
  color: "#FFF",
  fontSize: "0.875rem",
  backgroundColor: "none",
  borderRadius: "0.46875rem",
  height: "2.1875rem",
})
