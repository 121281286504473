import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Profile() {
    return (
        <Box>
            <Typography variant='h4' color="white">Profile Page</Typography>
        </Box>
    )
}
