import "../../../Styles/LoggedIn/Calendar/Calendar.css";
import "../../../Styles/ScrollBar.css";
import { Box, Stack, Typography } from '@mui/material';
import React, { useState, useContext } from 'react';
import { UserContext } from "../../../context/AuthContext";
import { ModalContext } from "../../../context/ModalContext";
import Filter from './TopFeatures/Filter';
import ViewSelect from './TopFeatures/ViewSelect';
import RightBar from './RightBar/RightBar';
import MonthlyCalendar from "./MonthlyCalendar/MonthlyCalendar";
import moment from "moment";
import EventModal from "./RightBar/SmallEventCreation/EventModal";
import UpdateTraining from "../TrainingManager/UpdateTraining/UpdateTraining";
import UpdateEvent from "./UpdateEvent/UpdateEvent";



export default function Calendar() {
    const { userRole } = useContext(UserContext);
    const { showEventCreation, setShowEventCreation, whichEventView, setWhichEventView, isUpdateTraining, setIsUpdateTraining, isUpdateEvent, setIsUpdateEvent } = useContext(ModalContext);
    const [targetDate, setTargetDate] = useState(moment().format("YYYY-MM-DD"))
    const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));

    return (
        <div>
            <Stack direction='column' spacing={1}>
                {userRole !== 1 && (
                    <Stack direction='column' spacing={1}>
                        <text className='small-medium white'>Calendar</text>
                        <div className='page-border-line'/>
                    </Stack>
                    )}
                <Stack direction='row' spacing={2}>
                    <Stack direction='column' spacing={1} sx={{width: '75%'}}>
                        <ViewSelect 
                            setShowEventCreation={setShowEventCreation}
                            setWhichEventView={setWhichEventView}
                            targetDate={targetDate}
                            setTargetDate={setTargetDate}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            />
                        <MonthlyCalendar 
                            targetDate={targetDate}
                            setTargetDate={setTargetDate}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                            setShowEventCreation={setShowEventCreation}
                            whichEventView={whichEventView}
                            setWhichEventView={setWhichEventView}
                        />
                    </Stack>
                    <Stack direciton='column' spacing={1} sx={{width: '25%'}}>
                        <RightBar 
                            showEventCreation={showEventCreation}
                            setShowEventCreation={setShowEventCreation}
                            whichEventView={whichEventView}
                            setWhichEventView={setWhichEventView}
                            selectedDay={selectedDay}
                            setSelectedDay={setSelectedDay}
                        />
                    </Stack>
                </Stack>
            </Stack>
            {showEventCreation && (
                <Box>
                    <EventModal
                        isOpen={showEventCreation}
                        setShowEventCreation={setShowEventCreation}
                        whichEventView={whichEventView}
                        setWhichEventView={setWhichEventView}
                        selectedDay={selectedDay}
                        onCancel={() => setShowEventCreation(false)}
                        />
                </Box>    
            )}
            {isUpdateTraining && (
                <UpdateTraining
                    isOpen={isUpdateTraining}
                    setIsAddTraining={setIsUpdateTraining}
                    onCancel={() => setIsUpdateTraining(false)}
                    selectedDay={selectedDay}  
                    setSelectedDay={setSelectedDay} 
                    />
            )}
            {isUpdateEvent && (
                <UpdateEvent 
                    isOpen={isUpdateEvent}
                    setIsUpdateEvent={setIsUpdateEvent}
                    onCancel={() => setIsUpdateEvent(false)}
                    selectedDay={selectedDay}  
                    setSelectedDay={setSelectedDay} 
                    whichEventView={whichEventView}
                    setWhichEventView={setWhichEventView}
                />
            )}
        </div>
    )
}
 