import React, { useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import { PrivateRoute } from '../../context/PrivateRoute';
import { Box, Button, Stack } from '@mui/material';
import { UserContext } from "../../context/AuthContext";
import { ModalContext } from "../../context/ModalContext";
import Home from './Home/Home';
import LeftNavBar from "./LeftNavBar/LeftNavBar";
import Profile from "./Profile/Profile";
import Calendar from "./Calendar/Calendar";
import Teams from "./Teams/Teams";
import Logbook from "./Logbook/Logbook";
import Updates from "./Updates/Updates";
import Help from "./Help/Help";
import TeamNav from "./Teams/TeamNav/TeamNav";
import JoinTeam from "./Teams/NewTeam/JoinTeam";
import CreateTeam from "./Teams/NewTeam/CreateTeam";
import Admin from "./Admin/Admin";
import AthletePerformance from "./AthletePerformance/AthletePerformance";
import TrainingManager from "./TrainingManager/TrainingManager";

export default function LoggedIn() {
    const { currentUser, userRole } = useContext(UserContext);    
    const { isCreateTeam, setIsCreateTeam, isJoinTeam, setIsJoinTeam } = useContext(ModalContext); 
    const [isJoinModal, setIsJoinModal] = useState(false);
    const [isCreateModal, setIsCreateModal] = useState(false);
    return (
        <div>
            <Stack direction='row'>
                {currentUser && (
                    <Stack direction="row" style={{ width: '100%'}}>
                        <div className='leftNavBar'>
                            <LeftNavBar />
                        </div>
                        <Stack direction='row'  className='main-content'>
                            <div className="full-vertical-border fixxed"/>
                            <Stack direction='column' spacing={1} style={{ width: '100%' }} className="main-content-margin">
                                    <Stack direction='column' spacing={1}>
                                        <TeamNav 
                                            setIsJoinModal={setIsJoinModal}
                                            setIsCreateModal={setIsCreateModal}
                                            />
                                        <div className='page-border-line'/>                                        
                                    </Stack>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        overflowY: 'auto', // Allow vertical scrolling
                                    }}>
                                    <Routes>
                                        <Route element={<PrivateRoute />}>
                                            <Route path="/" element={<Home/>}/>        
                                            <Route path="/profile" element={<Profile />}/>  
                                            <Route path="/calendar" element={<Calendar />}/>  

                                            <Route path="/teams" element={<Teams />}/> 

                                            <Route path="/logbook" element={<Logbook />}/> 
                                            <Route path="/training-manager" element={<TrainingManager />}/>
                                            <Route path="/workouts" element={<Logbook />}/> 
                                            <Route path="/athlete-performance" element={<AthletePerformance />}/> 
                                            

                                            <Route path="/updates" element={<Updates />}/>                              
                                            <Route path="/help" element={<Help />}/>           

                                            <Route path="/admin" element={<Admin />}/> 
                                        </Route>
                                    </Routes>
                                </Box>

                            </Stack>

                        </Stack>
                    </Stack>
                )}
            </Stack>
            {isJoinTeam && (
                <JoinTeam
                    isOpen={isJoinTeam}
                    onCancel={() => setIsJoinTeam(false)}
                    setIsJoinTeam={setIsJoinTeam}
                />
                )}
            {isCreateTeam && (
                <CreateTeam
                    isOpen={isCreateTeam}
                    onCancel={() => setIsCreateTeam(false)}
                />
            )}
        </div>
    )
}
