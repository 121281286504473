import React, {useEffect, useState, useContext} from 'react';
import { CalendarContext } from '../../../../../../context/EventContext';
import moment from 'moment';
import { Box, Stack } from '@mui/material';

import SpecificDayPlan from './SpecificDayPlan';

export default function MonthlyPlan({targetDate, selectedDay, setSelectedDay, setIsAddTraining, whichEventView, setWhichEventView, trainings}) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', "Thu", "Fri", "Sat"];
    const [currentMonth, setCurrentMonth] = useState([]);
    const { allTrainings, setAllTrainings } = useContext(CalendarContext);

    const isLastRowToShow =
        (moment(targetDate).daysInMonth() === 31 && moment(targetDate).startOf('month').day() >= 5) ||
        (moment(targetDate).daysInMonth() === 30 && moment(targetDate).startOf('month').day() === 6);

    useEffect(() => {
        const firstDayOfTargetMonth = moment(targetDate).startOf('month');
        const dayOfWeek = firstDayOfTargetMonth.day();
        const startDate = firstDayOfTargetMonth.clone().subtract(dayOfWeek, 'days');
        const endDate = startDate.clone().add(42, 'days');

        let currentMonth = []
        for (let date = startDate.clone(); date.isBefore(endDate); date.add(1, 'day')) {
            currentMonth = [
                ...currentMonth, 
                {
                    date: date.format("YYYY-MM-DD"),
                    day: date.format("D"),
                    trainings: [],
                  }
            ]
          }
          currentMonth.map((day, index) => {
            trainings.map((training) => {
                const date = day.date;
                const trainingDate = moment(training.date.toDate()).format("YYYY-MM-DD");

                if (date === trainingDate) {
                    currentMonth[index] = {
                        ...currentMonth[index], 
                        trainings: [
                            ...currentMonth[index].trainings,
                            training
                        ]
                    }
                }
            })
        })
        // console.log("CurrentMonthData: " + JSON.stringify(currentMonth));
        setCurrentMonth(currentMonth);
        setSelectedDay(targetDate);
        
    }, [targetDate, trainings])



    // useEffect(() => {
    //     console.log("currentMonth: " + JSON.stringify(currentMonth));
    // }, [currentMonth])
    
    return (
        <Stack direction='column' spacing={1} className='training-manager-monthly-container more-padding'>
            <text className='small white'>
                {moment(targetDate).format('MMMM')} {moment(targetDate).format('YYYY')}
            </text>
            <div className='calendar-container'>
                <Stack direction="row" justifyContent='space-between'>
                    {daysOfWeek.map((name) => (
                        <Stack direction='row' justifyContent='center' className='day-of-week'>
                            <text className='light-grey very-small day-of-week-text'>
                                {name}
                            </text>                            
                        </Stack>
                    ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(0,7).map((day, index) => (
                            <SpecificDayPlan 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(7,14).map((day, index) => (
                            <SpecificDayPlan 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(14,21).map((day, index) => (
                            <SpecificDayPlan 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(21,28).map((day, index) => (
                            <SpecificDayPlan 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(28,35).map((day, index) => (
                            <SpecificDayPlan 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                {isLastRowToShow && (
                    <Stack direction='row'>
                        {currentMonth.slice(35,42).map((day, index) => (
                            <SpecificDayPlan 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                    </Stack>
                )}
            </div>
        </Stack>
    )
}
