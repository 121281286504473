import React, { useContext } from 'react';
import { UserContext } from '../../../../../context/AuthContext';
import { Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';


export default function TopPlan({setIsAddTraining, teamPlans, personalPlans, targetDate, setTargetDate}) {
    const { userRole, currentUser } = useContext(UserContext);

    const handleShowTrainingModal = () => {
        setIsAddTraining(true)
    }
    const oneMonthForward = () => {
        const newTargetDate = moment(targetDate).add(1, "month").format('YYYY-MM-DD');
        console.log("newtargetDate: " + newTargetDate);
        setTargetDate(newTargetDate);
    }
    const oneMonthBackward = () => {
        const newTargetDate = moment(targetDate).subtract(1, "month").format('YYYY-MM-DD');
        setTargetDate(newTargetDate)
    }
    const toToday = () => {
        const today = moment().format("YYYY-MM-DD");
        console.log("today: " + today)
        setTargetDate(today);
    }
    return (
        <div>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={2} alignItems="center">
                    {teamPlans.length === 0 && userRole === 1 || personalPlans.length === 0 && userRole === 0 ? (
                        <div className='new-training-grey small border-outline'>
                            New Training +
                        </div>
                    ):(
                        <button
                            className='new-training-button white small border-outline'
                            onClick={handleShowTrainingModal}
                            >
                            New Training +
                        </button>
                    )}
                </Stack>
                <Stack direction="row" alignItems="center" spacing={0.3}>
                    <button 
                        className='day-button left-button border-outline light-grey small'
                        onClick={oneMonthBackward}
                        >
                        <ArrowForwardIosIcon style={{ fontSize: '0.8rem' }}/>
                    </button>
                    <button 
                        className='day-button border-outline light-grey small'
                        onClick={toToday}
                        >
                        Today
                    </button>
                    <button 
                        className='day-button border-outline light-grey small'
                        onClick={oneMonthForward}
                        >
                        <ArrowForwardIosIcon style={{ fontSize: '0.8rem' }}/>
                    </button>
                </Stack>
            </Stack>
        </div>
    )
}
