//Sets which pages can be seen when logged in versus logged out

import { Navigate, Outlet } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from './AuthContext';

export function PrivateRoute({component: Component, ...rest}) {
  const { currentUser } = useContext(UserContext)

  return (
    currentUser ? <Outlet /> : <Navigate to="./../Slate"/>
  );
};