import React, { useEffect, useContext, useState } from 'react';
import { Stack } from '@mui/material';
import { TeamContext } from '../../../../../context/TeamContext';
import { UserContext } from '../../../../../context/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import { ModalContext } from '../../../../../context/ModalContext';
import PeopleIcon from '@mui/icons-material/People';

export default function RosterList() {
    const { subRosters, setSubRosters, personalSubRosters, setPersonalSubRosters, members, pendingMembers } = useContext(TeamContext);
    const { userRole, teamID } = useContext(UserContext);
    const { setIsUpdateRoster, setUpdateRosterData, setIsAllRoster, setIsPendingRoster } = useContext(ModalContext); 

    useEffect(() => {
        const teamDocRef = doc(db, "teams", teamID);
        const unsubscribe = onSnapshot(teamDocRef, (teamDocSnap) => {
            const data = teamDocSnap.data();
            const subRosters = data.subRosters;
            const members = data.members;

            // Use an object to keep track of added rosters
            const addedRosters = {};

            let subRosterData = [];

            subRosters.forEach(rosterName => {
                let rosterMembers = [];
                members.forEach(member => {
                    const memberSubRosters = member.subRosters;
                    console.log(JSON.stringify(member));
                    if (memberSubRosters.includes(rosterName)) {
                        if (!addedRosters[rosterName]) {
                            subRosterData.push({ title: rosterName, members: [member.userID] });
                            addedRosters[rosterName] = true;
                        } else {
                            const rosterIndex = subRosterData.findIndex(roster => roster.title === rosterName);
                            subRosterData[rosterIndex].members.push(member.userID);
                        }
                    }
                });
            });

            console.log("subRosterData: " + JSON.stringify(subRosterData));
            setSubRosters(subRosterData);
        });
            return () => unsubscribe();
    }, [teamID]);

    const handleUpdateRoster = (roster, index) => {
        console.log("Roster: " + JSON.stringify(roster));
        setIsUpdateRoster(true);
        const dataObject = { roster: roster, index: index}
        setUpdateRosterData(dataObject);
    }

    const viewAllMembers = () => {
        setIsAllRoster(true);
    }
    const viewPendingMembers = () => {
        setIsPendingRoster(true);
    }
    return (
        <div>
            <Stack direction='column'>
                {userRole === 1 && pendingMembers.length > 0 && (
                    <Stack direction='column'>
                        <Stack direction='row'>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' className='roster-list-container padding' onClick={() => viewPendingMembers()}>
                                <text className='submit-green small'>Pending Members</text>
                                <button className='open-roster white padding'>View</button>
                            </Stack>
                            <div className='vertical-border' />
                            <Stack direction='row' spacing={0.25} alignItems='center' justifyContent='center' className='members-width members-container'  onClick={() => viewPendingMembers()}>
                                    <button className='white small text-button'>
                                        {pendingMembers.length} 
                                    </button>
                                    <PeopleIcon style={{color: 'white'}}/>
                            </Stack>   
                            <div className='vertical-border'/>
                        </Stack>
                        <div className='page-border-line' />
                    </Stack>
                )}
                <Stack direction='column'>
                    <Stack direction='row'>
                        <Stack direction='row' justifyContent='space-between' alignItems='center' className='roster-list-container padding' onClick={() => viewAllMembers()}>
                            <text className='white small'>All Members</text>
                            <button className='open-roster white padding'>View</button>
                        </Stack>
                        <div className='vertical-border' />
                        <Stack direction='row' spacing={0.25} alignItems='center' justifyContent='center' className='members-width members-container'  onClick={() => viewAllMembers()}>
                                <button className='white small text-button'>
                                    {members.length} 
                                </button>
                                <PeopleIcon style={{color: 'white'}}/>
                        </Stack>   
                        <div className='vertical-border'/>
                    </Stack>
                    <div className='page-border-line' />
                </Stack>
                {subRosters.map((roster, index) => (
                    <Stack direction='column'>
                        <Stack direction='row'>
                            <Stack direction='row' justifyContent='space-between' alignItems='center' className='roster-list-container padding' onClick={() => handleUpdateRoster(roster, index)}>
                                <text className='white small'>{roster.title}</text>
                                <button className='open-roster white padding'>View</button>
                            </Stack>
                            <div className='vertical-border' />
                            {roster.members && (
                                <Stack direction='row' spacing={0.25} alignItems='center' justifyContent='center' className='members-width members-container'  onClick={() => handleUpdateRoster(roster, index)}>
                                        <button className='white small text-button'>
                                            {roster.members.length} 
                                        </button>
                                        <PeopleIcon style={{color: 'white'}}/>
                                </Stack>   
                            )}
                            <div className='vertical-border'/>
                        </Stack>
                        <div className='page-border-line' />
                    </Stack>
                ))}
            </Stack>
            <Stack direction='row'> 
                <div className='roster-width'>
                </div>
            </Stack>
        </div>
    )
}
