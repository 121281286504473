import React, { useEffect } from 'react';
import { Box, Stack } from '@mui/material';
import StartTimeChange from './StartTimeChange';
import EndTimeChange from './EndTimeChange';

export default function DateAndTime({  setStartDate, 
                                        setStartTime, 
                                        setEndDate,
                                        setEndTime, 
                                        startDate,
                                        startTime,
                                        endDate,
                                        endTime,
                                        initialStartTime,
                                        initialEndTime,
                                    }) {
                                         
    // useEffect(() => {
    //     console.log(endTime);
    //     console.log("Start time next");
    //     console.log(startTime);
    // }, [])
    const handleStartDateChange = (event) => {
        const newDate = (event.target.value);
        setStartDate(newDate);
    }
    const handleStartTimeChange = (newStartTime) => {
        setStartTime(newStartTime);
    }
    const handleEndDateChange = (event) => {
        const newDate = (event.target.value);
        setEndDate(newDate);
    }
    const handleEndTimeChange = (newEndTime) => {
        setEndTime(newEndTime);
    }
    return (
        <Box>
            <Stack direction="column" spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <text className='white small'>Start</text>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box className="date-selector-container">
                            <input
                                type="date"
                                id="date-input"
                                className="date-input"
                                value={startDate}
                                onChange={handleStartDateChange}
                            />
                        </Box> 
                        <Box>
                            <StartTimeChange 
                                startTime={startTime}
                                onStartTimeChange={handleStartTimeChange}
                                initialStartTime={initialStartTime}
                            />
                        </Box> 
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <text className='white small'>End</text>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Box className="date-selector-container">
                            <input
                                type="date"
                                id="date-input"
                                className="date-input"
                                value={endDate}
                                onChange={handleEndDateChange}
                            />
                        </Box> 
                        <Box>
                            <EndTimeChange 
                                endTime={endTime}
                                onEndTimeChange={handleEndTimeChange}
                                initialEndTime={initialEndTime}
                            />
                        </Box> 
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    )
}
