import React from 'react';
import { Box, Stack } from '@mui/material';
import EventDetails from '../EventDetails/EventDetails';
import EventDescription from '../EventDetails/EventDescription';
import PersonalCustomization from './PersonalCustomization';
import CreateEvent from '../SaveEvent/CreateEvent';
import DateAndTime from '../DateAndTime/DateAndTime';

export default function PersonalEvent({startDate,
                                       startTime,
                                       endDate,
                                       endTime,
                                       setStartDate,
                                       setStartTime,
                                       setEndDate,
                                       setEndTime,
                                       repeat,
                                       setRepeat,
                                       linkedCalendar,
                                       setLinkedCalendar,
                                       description,
                                       setDescription,
                                       onCreateEvent,
                                       onSaveAsDraft,
                                       initialStartTime,
                                       initialEndTime
                                    }) {
    return (
        <Box>
            <Stack direction="column" spacing={2}>
                <DateAndTime 
                    startDate={startDate}
                    startTime={startTime}
                    endDate={endDate}
                    endTime={endTime}
                    setStartDate={setStartDate}
                    setStartTime={setStartTime}
                    setEndDate={setEndDate}
                    setEndTime={setEndTime}
                    initialStartTime={initialStartTime}
                    initialEndTime={initialEndTime}
                />
                {/* <EventDetails 
                    repeat={repeat}
                    setRepeat={setRepeat}
                    linkedCalendar={linkedCalendar}
                    setLinkedCalendar={setLinkedCalendar}
                    /> */}
                <EventDescription 
                    description={description}
                    setDescription={setDescription}
                    />
                {/* <PersonalCustomization 
                    /> */}
                <CreateEvent
                    onCreateEvent={onCreateEvent}
                    onSaveAsDraft={onSaveAsDraft}
                    />
            </Stack>
        </Box>
    )
}
