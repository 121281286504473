import React from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useNavigate } from 'react-router-dom';

export default function TopButtons() {
    const navigate = useNavigate();
    const handleToSettings = () => {
        navigate("/Settings")
    }
    return (
        <div>
            
        </div>
        // <Box className="margin-top" sx={{display: "flex", justifyContent: 'space-between', width: "100%"}}>
        //     <button 
        //         className='settings-button'
        //         onClick={handleToSettings}
        //         >
        //         Settings
        //     </button>
        // </Box>
  )
}
