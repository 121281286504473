//Holds the workout creation Modal
import "./../../../Styles/LoggedIn/LeftNavBar/Profile.css"
import React, { useState, useContext, useEffect } from 'react';
import { db } from '../../../firebase';
import { setDoc, doc, addDoc, collection, Timestamp, getDoc } from 'firebase/firestore';
import { UserContext } from '../../../context/AuthContext';
import { Box, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import EditIcon from '@mui/icons-material/Edit';
import { auth } from "../../../firebase";
import { signOut } from "firebase/auth";

export default function ProfileModal({ isOpen, onCancel, setIsProfileModal }) {
    const { currentUser } = useContext(UserContext);
    const [newUserName, setNewUserName] = useState(currentUser.firstName + ' ' + currentUser.lastName);
    const navigate = useNavigate();
    
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "37rem",
            width: "100%",
        },
    }; 
    const logout = () => {
        signOut(auth).then(() => {
            localStorage.clear();
            navigate("/Slate-Fitness")
        })
    }
    const handleNameChange = (event) => {
        const newName = event.target.value;
        setNewUserName(newName);
    }
    const toAdmin = () => {
        navigate('/admin');
        setIsProfileModal(false);
    }
    const handleClose = () => {
        onCancel();
      };
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction='column' spacing={1}>
                    <Stack direction="row" alignItems='center' justifyContent='space-between'>
                        <Stack direction="row" spacing={1}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                <circle cx="14.5" cy="14.5" r="14.5" fill="#D9BC21">
                                    <text>J</text>
                                </circle>
                            </svg>
                            <Stack direction='row' spacing={1} className="">
                                <input 
                                    type='text'
                                    className='border-outline white small change-name'
                                    onChange={handleNameChange}
                                    value={newUserName}
                                />
                                <button className="icon-button">
                                    <EditIcon style={{ color: "#1d91f6", size: '1rem'}}/>
                                </button>
                            </Stack>
                        </Stack>
                        <button className="text-button delete-red small" onClick={logout}>
                            Sign Out
                        </button>
                    </Stack>                       
                    {/* <button className="text-button ocean-blue small" onClick={toAdmin}>
                        Admin Page
                    </button> */}
                </Stack>
            </Modal>
        </Box>
    )
}
 