import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from "../../../../context/AuthContext"; 
import { TeamContext } from '../../../../context/TeamContext';
import { ModalContext } from '../../../../context/ModalContext';
import { Box, Stack } from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

export default function TeamNav({setIsJoinModal, setIsCreateModal}) {
    const { userRole, currentUser, allTeams, teamID, setTeamID, setStatus } = useContext(UserContext);
    const { setPendingMembers, setMembers, setTeamName, setTeamDoc } = useContext(TeamContext);
    const { setIsCreateTeam, setIsJoinTeam } = useContext(ModalContext);

    useEffect(() => {
        if (teamID) {
            console.log('teamID: ' + teamID);
        }
    }, [teamID])

    const handleTeamChange = async (team) => {
        const newTeamID = team.teamID;
        const teamIndex = allTeams.findIndex(t => t.teamID === newTeamID);
        const teamDocRef = doc(db, "teams", newTeamID);
        const teamDocSnap = await getDoc(teamDocRef);
        if (teamDocSnap.exists()) {
            const teamData = teamDocSnap.data();
            const newTeamName = teamData.teamName;
            const newMembers = teamData.members || [];
            const newPendingMembers = teamData.pendingMembers || [];
            const userDocRef = doc(db, 'users', currentUser.userID);
            await updateDoc(userDocRef, {selectedTeam: newTeamID});
            setPendingMembers(newPendingMembers);
            setMembers(newMembers);
            setTeamID(newTeamID);
            setTeamName(newTeamName);
            setTeamDoc(teamData);
            if (teamIndex !== -1) {
                const newStatus = allTeams[teamIndex].status
                setStatus(newStatus);
            }
        }
    }
    const handleJoinTeamClick = () => {
        setIsJoinTeam(true);
    }
    const handleCreateNewTeamClick = () => {
        setIsCreateTeam(true);
    }
    return (
        <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={3}>
                    
                {allTeams && allTeams.length > 0 ? allTeams.map((team, index) => (
                        <Box key={team.teamID} className={`padding ${teamID === team.teamID && 'team-selected-tab'}`}>
                            <button 
                                className='icon-button '
                                onClick={() => handleTeamChange(team)}
                                >
                                <Stack direction="row" spacing={1} alignItems='center' >
                                <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                                    <circle cx="14.5" cy="14.5" r="14.5" fill="aqua">
                                        <text>J</text>
                                    </circle>
                                </svg>
                                <Stack direction="column" alignItems="flex-start">
                                    <text className='white small mid-weight'>{team.teamName}</text>
                                    <text className={`very-small ${teamID === team.teamID ? 'white' : 'mid-grey'}`}>{team.creatorName}</text>
                                </Stack>
                                </Stack>
                            </button>
                        </Box>
                    )): (
                        <text className='light-grey small'>
                            No Teams
                        </text>
                    )}
                </Stack>
                <Stack direction='row' spacing={2}>
                    {userRole === 1 && (
                        <button 
                            className={`outline-button small ${teamID ? 'white' : 'submit-green'}`}
                            onClick={handleCreateNewTeamClick}
                            >
                                Create New Team +
                        </button>
                    )}
                    <button 
                        className={`outline-button small ${teamID ? 'white' : 'submit-green'}`}
                        onClick={handleJoinTeamClick}
                        >
                        Join New Team +
                    </button>
                </Stack>
            </Stack>
        </Box>
    )
}
