import { Box, Stack } from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';

export default function CurrentDatePlan({selectedDay}) {
    return (
        <Box
            sx={{
                paddingTop: '0.25rem',
                paddingRight: '0.75rem',
                paddingLeft: '0.75rem'
            }}>
            <Stack direction="row" justifyContent="left" alignItems="center" >
                <text className='small-header white bold'>{moment(selectedDay).format("MMMM D, YYYY")}</text>
            </Stack>
        </Box>
    )
}
