import { Box, Stack } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../../context/AuthContext';
import { CalendarContext } from '../../../../context/EventContext';
import EventName from '../RightBar/SmallEventCreation/EventName/EventName';
import PersonalEvent from '../RightBar/SmallEventCreation/PersonalEvent/PersonalEvent';
import TeamEvents from '../RightBar/SmallEventCreation/TeamEvents/TeamEvents';
import moment from 'moment';
import { Timestamp, addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { ModalContext } from '../../../../context/ModalContext';
import Modal from "react-modal";


export default function UpdateEvent({ isOpen, onCancel, selectedDay, whichEventView, setWhichEventView }) {
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "25rem",
          width: "100%",
        },
      }; 

    const { updateEventData, setIsUpdateEvent, setUpdateEventData } = useContext(ModalContext);
    const { allEvents, setAllEvents } = useContext(CalendarContext);
    const { currentUser, teamID } = useContext(UserContext);
    const [teamName, setTeamName] = useState('');

    const [eventName, setEventName] = useState('');
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [startTime, setStartTime] = useState(moment().format("hh:mm A"));
    const [initialStartTime, setInitialStartTime] = useState(moment());
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [endTime, setEndTime] = useState(moment().format("hh:mm A"));
    const [initialEndTime, setInitialEndTime] = useState(moment().add(1, 'hour'));
    const [repeat, setRepeat] = useState('Never');
    const [linkedCalendar, setLinkedCalendar] = useState('Personal')
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [linkedWorkout, setLinkedWorkout] = useState(null);
    const [eventTags, setEventTags] = useState (null);
    const [description, setDescription] = useState('');

    useEffect(() => {   
        console.log("updatedData: " + JSON.stringify(updateEventData));
        const startDateData = updateEventData.startDate;
        const endDateData = updateEventData.endDate;
        console.log("startDate: "  + startDateData);
        console.log("endDateData: " + endDateData);
        setEventName(updateEventData.eventName);
        setInitialStartTime(moment(updateEventData.startDate.toDate()));
        setInitialEndTime(moment(updateEventData.endDate.toDate()));
        setDescription(updateEventData.description);
        if (updateEventData.linkedTeamID) {
            setWhichEventView(1);
            setTeamName(updateEventData.linkedTeamName);
            setSelectedTeam(updateEventData.linkedTeamID);
        } else {
            setWhichEventView(0);
        }
    }, [updateEventData])

    const handleUpdateEvent = async () => {
        const creationDate = moment();
        const creationTimeStamp = Timestamp.fromDate(creationDate.toDate());
        const startDateTime = moment(`${startDate} ${startTime}`, 'YYYY-MM-DD hh:mm A');
        const endDateTime = moment(`${endDate} ${endTime}`, 'YYYY-MM-DD hh:mm A');
        const startTimeStamp = Timestamp.fromDate(startDateTime.toDate());
        const endTimeStamp = Timestamp.fromDate(endDateTime.toDate());
        if (whichEventView === 0) {
            try {
                const userDocRef = doc(db, 'users', currentUser.userID);
                const userDocSnap = await getDoc(userDocRef);
                const eventDocRef = doc(db, "teams", currentUser.userID, 'events', updateEventData.eventID);
                if (userDocSnap.exists()) {
                    const teamData = userDocSnap.data();
                    const userEvents = teamData.events;
                    const newUserEvents = [...userEvents];
                    const eventCreationTimeData = moment(updateEventData.createdAt.toDate());
                    userEvents.map((event, index) => {
                        const eventCreationTime = moment(event.createdAt.toDate());
                        if (eventCreationTime.isSame(eventCreationTimeData, 'second')) {
                            const indexToUpdate = index;
                            if (indexToUpdate !== -1) {
                                newUserEvents[indexToUpdate] = {
                                    createdAt: creationTimeStamp,
                                    eventID: updateEventData.eventID,
                                    eventName: eventName,
                                    startDate: startTimeStamp,
                                    endDate: endTimeStamp,
                                }
                                const newContextObj = {
                                    createdAt: creationTimeStamp,
                                    eventID: updateEventData.eventID, 
                                    eventName: eventName,
                                    startDate: startTimeStamp,
                                    endDate: endTimeStamp,
                                }
                                const newEventContext = [...allEvents];
                                newEventContext[indexToUpdate] = newContextObj
                                setAllEvents(newEventContext);
                            }
                        }
                    })
                    const eventDocData = {
                        createdAt: creationTimeStamp,
                        description: description,
                        endDate: endTimeStamp,
                        startDate: startTimeStamp,
                        title: eventName
                    }
                    console.log("TEAM data:  " + newUserEvents);
                    console.log("eventDocData: " + eventDocData);
                    await updateDoc(teamDocRef, {teamEvents: newUserEvents});
                    await updateDoc(eventDocRef, eventDocData);
                    setIsUpdateEvent(false);
                    setUpdateEventData(null);
                    setWhichEventView(null); 
                } else {
                    console.log("user doesn't exist")
                }
                
            
            } catch (err) {
                console.error(err)
            }
        } else {
            try {
                const teamDocRef = doc(db, 'teams', selectedTeam);
                const teamDocSnap = await getDoc(teamDocRef);
                const eventDocRef = doc(db, "teams", selectedTeam, 'events', updateEventData.eventID);
                if (teamDocSnap.exists()) {
                    const teamData = teamDocSnap.data();
                    const teamEvents = teamData.teamEvents;
                    const newTeamEvents = [...teamEvents];
                    const eventCreationTimeData = moment(updateEventData.createdAt.toDate());
                    teamEvents.map((event, index) => {
                        const eventCreationTime = moment(event.createdAt.toDate());
                        if (eventCreationTime.isSame(eventCreationTimeData, 'second')) {
                            const indexToUpdate = index;
                            if (indexToUpdate !== -1) {
                                newTeamEvents[indexToUpdate] = {
                                    createdAt: creationTimeStamp,
                                    eventID: updateEventData.eventID,
                                    eventName: eventName,
                                    startDate: startTimeStamp,
                                    endDate: endTimeStamp,
                                    linkedTeamID: selectedTeam,
                                    linkedTeamName: teamName,
                                }
                                const newContextObj = {
                                    createdAt: creationTimeStamp,
                                    eventID: updateEventData.eventID, 
                                    eventName: eventName,
                                    startDate: startTimeStamp,
                                    endDate: endTimeStamp,
                                    linkedTeamID: selectedTeam,
                                    linkedTeamName: teamName,
                                }
                                const newEventContext = [...allEvents];
                                newEventContext[indexToUpdate] = newContextObj
                                setAllEvents(newEventContext);
                            }
                        }
                    })
                    const eventDocData = {
                        createdAt: creationTimeStamp,
                        description: description,
                        endDate: endTimeStamp,
                        linkedTeamID: selectedTeam,
                        linkedTeamName: teamName,
                        startDate: startTimeStamp,
                        title: eventName
                    }
                    console.log("TEAM data:  " + newTeamEvents);
                    console.log("eventDocData: " + eventDocData);
                    await updateDoc(teamDocRef, {teamEvents: newTeamEvents});
                    await updateDoc(eventDocRef, eventDocData);
                    setIsUpdateEvent(false);
                    setUpdateEventData(null);
                    setWhichEventView(null); 
                } else {
                    console.log("No Team")
                }
            } catch (err) {
                console.error(err)
            }
        } 
    }
    const handleClose = () => {
        setUpdateEventData(null);
        onCancel();
      };

    useEffect(() => {
        console.log("startTime")
    })
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
            >
                <Stack direction="column">
                    <EventName
                        eventName={eventName}
                        setEventName={setEventName}
                        /> 
                    {whichEventView === 0 && (
                        <PersonalEvent
                            startDate={startDate}
                            startTime={startTime}
                            endDate={endDate}
                            endTime={endTime}
                            setStartDate={setStartDate}
                            setStartTime={setStartTime}
                            setEndDate={setEndDate}
                            setEndTime={setEndTime}
                            repeat={repeat}
                            setRepeat={setRepeat}
                            linkedCalendar={linkedCalendar}
                            setLinkedCalendar={setLinkedCalendar}
                            description={description}
                            setDescription={setDescription}
                            onCreateEvent={handleUpdateEvent}
                            initialStartTime={initialStartTime}
                            initialEndTime={initialEndTime}
                        />
                    )}

                    {[1, 2, 3].includes(whichEventView) && (
                        <TeamEvents
                            startDate={startDate}
                            startTime={startTime}
                            endDate={endDate}
                            endTime={endTime}
                            setStartDate={setStartDate}
                            setStartTime={setStartTime}
                            setEndDate={setEndDate}
                            setEndTime={setEndTime}
                            description={description}
                            setDescription={setDescription}
                            onCreateEvent={handleUpdateEvent}
                            selectedTeam={selectedTeam}
                            setSelectedTeam={setSelectedTeam}
                            initialStartTime={initialStartTime}
                            initialEndTime={initialEndTime}
                        />
                    )}
                </Stack>
            </Modal>
        </Box>
    )
}
