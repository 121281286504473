import React, { useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import "./../../../Styles/LoggedIn/Admin/Admin.css";

export default function Admin() {
    const [monthlyPrice, setMonthlyPrice] = useState(0);
    const [peoplePerTeam, setPeoplePerTeam] = useState(1);
    const [numberOfTeams, setNumberOfTeams] = useState(0);

    const [users, setUsers] = useState(0);
    const [reads, setReads] = useState(0); // per user
    const [writes, setWrites] = useState(0); // per user
    const [deletes, setDeletes] = useState(0); // per user

    const [monthlyRevenue, setMonthlyRevenue] = useState(0);
    const [yearlyRevenue, setYearlyRevenue] = useState(0);

    const [dailyCost, setDailyCost] = useState(0);
    const [weeklyCost, setWeeklyCost] = useState(0);
    const [monthlyCost, setMonthlyCost] = useState(0);
    const [yearlyCost, setYearlyCost] = useState(0);

    const [monthlyProfit, setMonthlyProfit] = useState(0);
    const [yearlyProfit, setYearlyProfit] = useState(0);

    useEffect(() => {
        console.log('monthly Price: ' + monthlyPrice);
        console.log("users: " + users);
        console.log("peoplePerTeam: " + peoplePerTeam);
        if (parseFloat(peoplePerTeam) > 0) {
            const numberOfTeams = users/peoplePerTeam;
            const monthlyRevenue = monthlyPrice * numberOfTeams;
            const yearlyRevenue = monthlyRevenue * 12;
            setNumberOfTeams(numberOfTeams);
            setMonthlyRevenue(monthlyRevenue);
            setYearlyRevenue(yearlyRevenue);    
        } 
    }, [monthlyPrice, peoplePerTeam, users])

    useEffect(() => {
        const readsConst = 0.0345 / 100000;
        const writesConst = 0.1042 / 100000;
        const deletesConst = 0.0115 / 100000;
        
        const totalReadsPerDay = users * reads;
        console.log("Total Reads Per Day:", totalReadsPerDay);
        const totalWritesPerDay = users * writes;
        console.log("Total Writes Per Day:", totalWritesPerDay);
        const totalDeletesPerDay = users * deletes;
        console.log("Total Deletes Per Day:", totalDeletesPerDay);

        let readsCostPerDay, writesCostPerDay, deletesCostPerDay;

        if (totalReadsPerDay > 50000) {
            readsCostPerDay = (totalReadsPerDay - 50000) * readsConst;
        } else {
            readsCostPerDay = 0;
        }

        if (totalWritesPerDay > 20000) {
            writesCostPerDay = (totalWritesPerDay - 20000) * writesConst;
        } else {
            writesCostPerDay = 0;
        }

        if (totalDeletesPerDay > 20000) {
            deletesCostPerDay = (totalDeletesPerDay - 20000) * deletesConst;
        } else {
            deletesCostPerDay = 0;
        }

        const dailyCost = readsCostPerDay + writesCostPerDay + deletesCostPerDay;
        console.log("Daily Cost:", dailyCost);

        const weeklyCost = dailyCost * 7;
        console.log("Weekly Cost:", weeklyCost);

        const monthlyCost = dailyCost * 30;
        console.log("Monthly Cost:", monthlyCost);

        const yearlyCost = dailyCost * 365;
        console.log("Yearly Cost:", yearlyCost);

        setDailyCost(dailyCost);
        setWeeklyCost(weeklyCost);
        setMonthlyCost(monthlyCost);
        setYearlyCost(yearlyCost);
    }, [users, reads, writes, deletes]);

    useEffect(() => {
        const monthlyProfit = monthlyRevenue - monthlyCost;
        const yearlyProfit = yearlyRevenue - yearlyCost;
        setMonthlyProfit(monthlyProfit);
        setYearlyProfit(yearlyProfit);
    }, [monthlyRevenue, yearlyRevenue, monthlyCost, yearlyCost])

    const monthlyPriceChange = (e) => {
        setMonthlyPrice(e.target.value.replace(/\D/, ''));
    }
    const handlePeoplePerTeam = (e) => {
        setPeoplePerTeam(e.target.value.replace(/\D/, ''));
    }

    const handleUsersChange = (e) => {
        setUsers(e.target.value.replace(/\D/, ''));
    };

    const handleReadsChange = (e) => {
        setReads(e.target.value.replace(/\D/, ''));
    };

    const handleWritesChange = (e) => {
        setWrites(e.target.value.replace(/\D/, ''));
    };

    const handleDeletesChange = (e) => {
        setDeletes(e.target.value.replace(/\D/, ''));
    };

    const selectText = (e) => {
        e.target.select();
    };

    return (
        <Stack direction='column' spacing={5}>
            <Stack direction='row' spacing={1}>
                <Stack direction='column' spacing={0.5}>
                    <text className="white small">User Count</text>
                    <input 
                        className='white admin-input padding small'
                        onFocus={selectText}
                        onChange={handleUsersChange}
                        value={users}
                    />                    
                </Stack>
                <Stack direction='column' spacing={0.5}>
                    <text className='white small'>Montly Price</text>
                    <input 
                        className='white admin-input padding small'
                        onFocus={selectText}
                        onChange={monthlyPriceChange}
                        value={monthlyPrice}
                    />                                       
                </Stack>
                <Stack direction='column' spacing={0.5}>
                    <text className='white small'>Users per Team</text>
                    <input 
                        className='white admin-input padding small'
                        onFocus={selectText}
                        onChange={handlePeoplePerTeam}
                        value={peoplePerTeam}
                    />                                       
                </Stack>
            </Stack>
            <Stack direction='row' spacing={4} className="white small">
                <text>Number of Teams {parseFloat(numberOfTeams).toFixed(2)}</text>                
                <text>Monthly Revenue: {parseFloat(monthlyRevenue).toFixed(2)}</text>
                <text>Yearly Revenue: {parseFloat(yearlyRevenue).toFixed(2)}</text>
            </Stack>
            <Stack direction='row' spacing={1}>
                <Stack direction='column' spacing={0.5}>
                    <text className="white small">Avg Reads / User</text>
                    <input 
                        className='white admin-input padding small'
                        onFocus={selectText}
                        onChange={handleReadsChange}
                        value={reads}
                    />  
                </Stack>
                <Stack direction='column' spacing={0.5}>
                    <text className="white small">Avg Writes / User</text>
                    <input 
                        className='white admin-input padding small'
                        onFocus={selectText}
                        onChange={handleWritesChange}
                        value={writes}
                    />                    
                </Stack>
                <Stack direction='column' spacing={0.5}>
                    <text className="white small">Avg Deletes / User</text>
                    <input 
                        className='white admin-input padding small'
                        onFocus={selectText}
                        onChange={handleDeletesChange}
                        value={deletes}
                    />          
                </Stack>
            </Stack>
            <Stack direction='row' spacing={4} className="white small">
                <text>Daily Cost: {parseFloat(dailyCost).toFixed(2)}</text>
                <text>Weekly Cost: {parseFloat(weeklyCost).toFixed(2)}</text>
                <text>Monthly Cost: {parseFloat(monthlyCost).toFixed(2)}</text>
                <text>Yearly Cost: {parseFloat(yearlyCost).toFixed(2)}</text>
            </Stack>
            <Stack direction='row' spacing={4} className="white small">
                <text>Monthly Profit: {parseFloat(monthlyProfit).toFixed(2)}</text>
                <text>Yearly Profit: {parseFloat(yearlyProfit).toFixed(2)}</text>
            </Stack>
        </Stack>
    )
}
