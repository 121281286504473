//The container for the left side of the workout creation
//Determines what type of workout is displayed
//Add new sections to workouts

import React from 'react';
import { Box, Stack } from '@mui/material';
import RunSection from './RunSection/RunSection';
import SwimSection from './SwimSection/SwimSection';

export default function SectionValue({sections, setSections, currentSection, setCurrentSection}) {
    const handleAddSectionClick = () => {
        const newSections = [...sections, {
                                           workoutSectionType: 1,
                                           metricType: 2,
                                           distance: '',
                                           unit: 2,
                                        }] 
        const newCurrentIndex = newSections.length-1
        setSections(newSections);
        setCurrentSection(newCurrentIndex);
    }
    return (
        <Box>
            <Stack direction="column" spacing={1}>
                {sections.length > 0 && sections.map((section, index) => (
                    <Box key={`section-${index}`}>
                        <RunSection 
                            key={`run-section-${index}`}
                            section={section}
                            index={index}
                            currentSection={currentSection}
                            setCurrentSection={setCurrentSection}
                            sections={sections} 
                            setSections={setSections}
                        />   
                    </Box>
                ))} 
                <button 
                    className='text-button light-grey very-small'
                    onClick={handleAddSectionClick}
                    >
                        Add Section +
                </button>
            </Stack>
        </Box>
    )
}
