import './../../../../Styles/LoggedIn/TrainingManager/PlansList.css';
import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { TeamContext } from '../../../../context/TeamContext';
import { UserContext } from '../../../../context/AuthContext';
import { ModalContext } from '../../../../context/ModalContext';

export default function TopPlanList() {
    const { teamName } = useContext(TeamContext);
    const { userRole, teamID } = useContext(UserContext);
    const { setIsCreatePlan } = useContext(ModalContext);
    return (
        <Stack className='plans-list-width more-margin-right' direction='row' alignItems='baseline' justifyContent='space-between'>
            <text className='light-grey small'>{teamName} Training Plans</text>
            {(userRole === 1 && teamID) && (
                <button className='white very-small text-button' onClick={() => setIsCreatePlan(true)}>+</button>
            )}
            {/* {userRole === 0 && (
                <button className='white very-small text-button' onClick={() => setIsCreatePlan(true)}>+</button>
            )} */}
        </Stack>
    )
}
