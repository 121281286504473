import React, { useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import RunMetricModifiers from './RunMetricModifiers';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export default function RunMetric({sections, setSections, currentSection, setCurrentSection}) {
    const [section, setSection] = useState(sections[currentSection]);
    const handleTypeChange = (event) => {
        const newSectionType = parseInt(event.target.value);
    
        const createNewObject = (item) => {
            let newObj = { ...item, workoutSectionType: newSectionType };
            console.log("New OBJ: ", newObj);
    
            if (newSectionType === 1 || newSectionType === 3) {
                newObj = { ...newObj, unit: 2, metricType: 2 };
            } else if (newSectionType === 2) {
                newObj = { ...newObj, unit: 5, metricType: 2 };
            } else if (newSectionType === 4) {
                newObj = { workoutSectionType: 4, movementName: '', movementDescription: ''};
                delete newObj.distance;
                delete newObj.duration;
                delete newObj.calories;
                delete newObj.pace;
                delete newObj.textDescription;
                delete newObj.repeat;
            }
    
            return newObj;
        };
    
        setSections(prevSections =>
            prevSections.map((item, index) =>
                index === currentSection
                    ? createNewObject(item)
                    : item
            )
        );
    };
    const handleMetricTypeChange = (newDescriptor) => {
        setSections(prevSections =>
            prevSections.map((section, index) =>
                index === currentSection
                ? (() => {
                    const newObj = {...section, metricType: newDescriptor.number, [newDescriptor.word]: '', [newDescriptor.not1]: null, [newDescriptor.not2]: null};
                    return newObj;
                })()
                : section
            )
            );
    }
    const handleUnitChange = (newUnit) => {
        const newSectionsArray = sections.map((item, index) =>
        index === currentSection
            ? { ...item, unit: newUnit } 
            : item
        );
        setSections(newSectionsArray);
    }

    useEffect(() => {
        setSection(sections[currentSection])
    }, [currentSection, sections])

    const handleDeleteSection = () => {
        const newSectionsArray = sections.filter((_, index) => index !== currentSection);
        const newLastIndex = newSectionsArray.length - 1;
        setCurrentSection(newLastIndex);
        setSections(newSectionsArray);
    };
    
    return (
        <Box>
            <Stack direction='column' spacing={1}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <select 
                        className='segment-type-change border-outline light-grey small-medium'
                        onChange={handleTypeChange}
                        value={section.workoutSectionType}
                        >
                        <option value={1}>Run</option>
                        <option value={2}>Swim</option>
                        <option value={3}>Bike</option>
                        <option value={4}>Lift</option>
                    </select>
                    <button 
                        className='icon-button'
                        onClick={handleDeleteSection}
                        >
                        {sections.length > 1 && (
                            <RemoveCircleOutlineIcon className='delete-red' style={{ fontSize: '1.5rem'}}/>
                        )}
                    </button>
                </Stack>    
                
                <div className='page-border-line'
                    />
                {/*Metric and Unit Selectors */}
                {section.workoutSectionType !== 4 ? (
                    <Stack direction='column' spacing={1}>
                        <Stack direction="column" spacing={2.2}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <text className='very-small light-grey'>
                                    Metric:
                                </text>
                                <Stack direction="row" spacing={0.75}>
                                    <button 
                                        className={`text-button white small ${section.metricType === 2 ? 'selected-tab' : ''}`}
                                        onClick={() => {
                                            const newDescriptor = {number: 2, word: 'distance', not1: 'duration', not2: 'calories'};
                                            handleMetricTypeChange(newDescriptor);
                                        }}
                                        >
                                        Distance
                                    </button>  
                                    <button 
                                        className={`text-button white small ${section.metricType === 0 ? 'selected-tab' : ''}`}
                                        onClick={() => {
                                            const newDescriptor = {number: 0, word: 'calories', not1: 'distance', not2: 'duration'};
                                            handleMetricTypeChange(newDescriptor);
                                        }}
                                        >
                                        Calories
                                    </button>  
                                    <button 
                                        className={`text-button white small ${section.metricType === 1 ? 'selected-tab' : ''}`}
                                        onClick={() => {
                                            const newDescriptor = {number: 1, word: 'duration', not1: 'distance', not2: 'calories'};
                                            handleMetricTypeChange(newDescriptor);
                                        }}
                                        >
                                        Duration
                                    </button>
                                </Stack>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                <text className='very-small light-grey'>
                                    Unit:
                                </text>
                                <Stack direction="row" spacing={0.75}>
                                    <Box sx={{ padding: 'none', margin: 'none'}}>
                                        {section.workoutSectionType === 2 ? (
                                            <div>
                                                <button 
                                                    className={`text-button white small ${section.unit === 5 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 5;
                                                        handleUnitChange(newUnit);
                                                    }}
                                                    >
                                                    Laps
                                                </button>
                                                <button 
                                                    className={`text-button white small ${section.unit === 3 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 3;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    Meters
                                                </button>
                                                <button 
                                                    className={`text-button white small ${section.unit === 4 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 4;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    Yards
                                                </button>
                                            </div>                                
                                        ) : section.workoutSectionType === 1 ? (
                                            <div>
                                                <button 
                                                    className={`text-button white small ${section.unit === 2 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 2;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    Miles
                                                </button>
                                                <button 
                                                    className={`text-button white small ${section.unit === 1 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 1;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    Kilometers
                                                </button>    
                                                <button 
                                                    className={`text-button white small ${section.unit === 3 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 3;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    Meters
                                                </button>
                                            </div>
                                        ) : section.workoutSectionType === 3 ? (
                                            <div>
                                                <button 
                                                    className={`text-button white small ${section.unit === 2 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 2;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    Miles
                                                </button>
                                                <button 
                                                    className={`text-button white small ${section.unit === 1 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 1;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    Kilometers
                                                </button>    
                                                <button 
                                                    className={`text-button white small ${section.unit === 3 ? 'selected-tab' : ''}`}
                                                    onClick={() => {
                                                        const newUnit = 6;
                                                        handleUnitChange(newUnit);
                                                    }}                                                    >
                                                    MPH
                                                </button>
                                            </div>
                                        ) : null}
                                    </Box>
                                </Stack>
                            </Stack>
                        </Stack>
                        <div className='page-border-line'
                            />
                        <text className='light-grey small '>Modifiers:</text>
                        <Box>
                            <RunMetricModifiers 
                                section={section}
                                sections={sections}
                                setSections={setSections}
                                currentSection={currentSection}
                                />
                        </Box>
                    </Stack>
                ) : (
                    <div className='lift-container'/>
                )}
            </Stack>      
        </Box>
    )
}
