import { Stack } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../../context/AuthContext';
import moment from 'moment';
import { Timestamp, doc, getDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../firebase';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';


export default function SpecificAnnouncement({activeAnnouncement, setAnnouncementView, rawAnnouncements, currentIndex}) {
    const { currentUser, teamID } = useContext(UserContext);
    const [comments, setComments] = useState([]);
    const [rawComments, setRawComments] = useState([]);
    const [newComment, setNewComment] = useState([]);
    const [newReply, setNewReply] = useState([]);
    const [replyIndex, setReplyIndex] = useState(-1);
    const [numberComments, setNumberComments] = useState(0);
    useEffect(() => {
        const announcementDocRef = doc(db, "teams", teamID, "announcements", activeAnnouncement.announcementID);
        const unsubscribe = onSnapshot(announcementDocRef, (announcementDocSnap) => {
            if (announcementDocSnap.exists()) {
                const docData = announcementDocSnap.data();
                const commentsArray = docData.comments;
                let newCommentsData = [];
                commentsArray.map((comment) => {
                    const indexData = {
                        comment: comment,
                        reply: false
                    }
                    newCommentsData = [...newCommentsData, indexData];
                })
                const commentsNumber = commentsArray.length;
                setComments(newCommentsData);
                setRawComments(commentsArray);
                setNumberComments(commentsNumber);
            }    
        })
        return () => unsubscribe();            
    }, [activeAnnouncement])

    useEffect(() => {
        console.log("Raw stuff 2: " + JSON.stringify(rawAnnouncements));
    }, [rawAnnouncements])

    const handleCommentChange = (event) => {
        const newCommentString = event.target.value;
        setNewComment(newCommentString);
    }

    const handleSubmitComment = async () => {
        const currentTime = Timestamp.now();
        const newCommentObject = {
            content: newComment,
            creator: currentUser.firstName + ' ' + currentUser.lastName,
            replies: [],
            sentAt: currentTime,
            userID: currentUser.userID
        }
        const newCommentData = {
            comments: [
                ...comments,
                newCommentObject
            ]
        }
        const announcementDocRef = doc(db, "teams", teamID, "announcements", activeAnnouncement.announcementID);
        await updateDoc(announcementDocRef, newCommentData);
        console.log("Raw Shit 3: " + rawAnnouncements[currentIndex].comments);
        console.log("Active announcement: " + activeAnnouncement.announcementID)
        const newCommentNumber = rawAnnouncements[currentIndex].comments + 1;
        console.log(newCommentNumber);
        const newFeed = {
            announcements: [
                ...rawAnnouncements.slice(0, currentIndex),
                {
                    ...rawAnnouncements[currentIndex],
                    comments: newCommentNumber
                },
                ...rawAnnouncements.slice(currentIndex + 1)
            ]
        }
        const teamDocRef = doc(db, 'teams', teamID);
        await updateDoc(teamDocRef, newFeed);
        console.log("new Feed: " + JSON.stringify(newFeed));
        setNewComment('');
    }
    
    const handleReply = (index) => {
        if (replyIndex === -1) {
            const updatedComments = [...comments];
            updatedComments[index].reply = true;
            setComments(updatedComments);    
            setReplyIndex(index);
        } else {
            const updatedComments = [...comments];
            updatedComments[replyIndex].reply = false;
            updatedComments[index].reply = true;
            setComments(updatedComments);
            setReplyIndex(index);
        }
    }

    const handleReplyChange = (event) => {
        setNewReply(event.target.value);
    }

    const handleSubmitReply = async () => {
        const currentTime = Timestamp.now();
        const replyData = {
            content: newReply,
            creator: currentUser.firstName + ' ' + currentUser.lastName,
            sentAt: currentTime,
            userID: currentUser.userID
        }
        let updatedComments = [...rawComments];
        updatedComments = [
            ...updatedComments.slice(0, replyIndex),
            {
                ...updatedComments[replyIndex],
                replies: [...updatedComments[replyIndex].replies, replyData]
            },
            ...updatedComments.slice(replyIndex + 1)
        ]
        const newCommentsData = {
            comments: updatedComments
        }
        const announcementDocRef = doc(db, "teams", teamID, "announcements", activeAnnouncement.announcementID);
        await updateDoc(announcementDocRef, newCommentsData);
        setNewReply('');
        setReplyIndex(-1);
    }

    const handleBack = () => {
        setAnnouncementView(0);
    }
    useEffect(() => {
        console.log("COmments: " + JSON.stringify(comments));
    }, [comments])
    return (
        <div>
            <Stack direction='column' spacing={3} className='comment-feed-container'>
                <button className='text-button white small' onClick={handleBack}>Back</button>
                <Stack direction='row' justifyContent='center'>
                    <Stack direction='column' spacing={0.75} className='announcement-card-container more-padding'> 
                        <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            <Stack direction='row' spacing={0.75} alignItems='center'>
                                <text className='white small mid-weight'>{activeAnnouncement.title}</text>
                                <text className='light-grey very-small'>- {activeAnnouncement.creatorName}</text>
                            </Stack>
                            <text className='white small light-weight'>{moment(activeAnnouncement.sentAt.toDate()).format('h:mm A')}</text>
                        </Stack>
                        <div className='announcement-border-line top-border-line'/>
                        <text className='light-grey very-small'>{activeAnnouncement.content}</text>
                        <text className='white very-small'>{numberComments} Comments</text>
                    </Stack>
                </Stack>
                <Stack direction='row' justifyContent='center'>
                    <Stack direction='column' spacing={4} style={{ width: '80%'}}>
                        {comments.length > 0 ? <text className='white very-small'>{numberComments} Comments</text> : <text className='light-grey small'>No Comments</text>}
                        {comments.length > 0 && comments.map((comment, index) => (
                            <Stack direction='column' spacing={0.75}>
                                <text className='light-grey small-header'>{comment.comment.creator}</text>
                                <div  className='page-border-line'/>
                                <Stack direction='column' spacing={2}>
                                    <text className='white small'>{comment.comment.content}</text>  
                                    {comment.comment.replies.length > 0 && (
                                        <Stack direction='column' spacing={2} style={{ marginLeft: '4rem'}}>
                                            <text className='light-grey small'>Replies</text>
                                            {comment.comment.replies.map((reply, index) => (
                                                <Stack direction='column' spacing={0.75} className='announcement-card-container more-padding'> 
                                                    <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                                        <Stack direction='row' spacing={0.75} alignItems='center'>
                                                            <text className='white small mid-weight'>{reply.creator}</text>
                                                        </Stack>
                                                        <text className='white small light-weight'>{moment(reply.sentAt.toDate()).format('h:mm A')}</text>
                                                    </Stack>
                                                    <div className='announcement-border-line top-border-line'/>
                                                    <text className='light-grey very-small'>{reply.content}</text>
                                                </Stack>
                                            ))}      
                                        </Stack>  
                                    )} 
                                    {comment.reply === false ? (
                                        <button className='text-button white small' onClick={() => handleReply(index)}>Add Reply</button>
                                    ) : (
                                        <Stack direction='row' alignItems='center' className='more-margin-left more-margin-right'>
                                            <textarea 
                                                className='white message-text-area small' 
                                                placeholder='New Comment'
                                                onChange={handleReplyChange}
                                                value={newReply}
                                                />
                                            <Stack direction='row' alignItems='center' justifyContent='center' className='submit-arrow-container margin' onClick={handleSubmitReply}>
                                                <ArrowUpwardIcon style={{ fontSize: '1.4rem', color: '#FFF'}}/>
                                            </Stack>
                                        </Stack>
                                    )}                        
                                </Stack>
                                <div  className='page-border-line'/>

                            </Stack>
                        ))}
                    </Stack>                    
                </Stack>
            </Stack>
            
            
            <Stack direction='row' alignItems='center' className='announcement-blur-container'>
                <Stack direction='column' className='announcement-bar-container'>
                    <Stack direction='row' alignItems='center' className='more-margin-left more-margin-right'>
                        <textarea 
                            className='white message-text-area small' 
                            placeholder='New Comment'
                            onChange={handleCommentChange}
                            value={newComment}
                            />
                        <Stack direction='row' alignItems='center' justifyContent='center' className='announcement-submit-container' onClick={handleSubmitComment}>
                            <text className='mid-text white'>+</text>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>

        </div>
    )
}
