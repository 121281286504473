import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Help() {
    return (
        <Box>
            <Typography variant='h4' color="white">Help</Typography>
        </Box>
    )
}
