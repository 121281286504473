import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { Typography, Button, Paper, Box, TextField, Stack } from "@mui/material";

export default function Login() {
  const navigate = useNavigate();
  const [logEmail, setLogEmail] = useState("");
  const [logPassword, setLogPassword] = useState("");

  const loginWithEmailAndPassword = async () => {
    try {
      await signInWithEmailAndPassword(auth, logEmail, logPassword);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack direction='row' justifyContent='center' alignItems='center' sx={{ height: '95vh'}}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="35rem"
      >
        <Paper
          elevation={17}
          style={{
            padding: "3%",
            width: "100%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background:
              "linear-gradient(to bottom right, #26b0e7, #68d5f9)",
          }}
        >
          <Typography variant="h3">Sign In</Typography>
          <TextField
            type="email"
            label="Email"
            value={logEmail}
            onChange={(event) => {
              setLogEmail(event.target.value);
            }}
            fullWidth
            margin="normal"
          />
          <TextField
            type="password"
            label="Password"
            value={logPassword}
            onChange={(event) => {
              setLogPassword(event.target.value);
            }}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            style={{ marginTop: "1.3%" }}
            onClick={loginWithEmailAndPassword}
          >
            Sign In
          </Button>
        </Paper>
      </Box>

    </Stack>
  );
}
