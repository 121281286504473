import React from 'react';
import { Box, Stack } from '@mui/material';
import "../../../../../../../Styles/LoggedIn/Calendar/RightBar.css";
import CurrentDatePlan from './CurrentDatePlan';
import DaySnippetPlan from './DaySnippetPlan';

export default function RightPlan({selectedDay, setSelectedDay, trainingPlans, setTrainingPlans, trainings}) {
    return (
        <Box>
            <Stack direction="column" spacing={1}>
                <div className='left-bar'>
                    <CurrentDatePlan 
                        selectedDay={selectedDay}
                        setSelectedDay={setSelectedDay}
                        />
                </div>
                <div className="page-border-line"
                />
                <DaySnippetPlan
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}      
                    trainingPlans={trainingPlans}
                    setTrainingPlans={setTrainingPlans}

                    trainings={trainings}
                    />
           </Stack>
        </Box>
    )
}
 