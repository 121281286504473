import React, { useState, useContext } from 'react';
import { UserContext } from '../../../../context/AuthContext';
import { TeamContext } from '../../../../context/TeamContext';
import { ModalContext } from '../../../../context/ModalContext';
import { Box, Stack } from '@mui/material';
import { collection, query, where, getDocs, setDoc, addDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";



export default function CreateTeam({ isOpen, onCancel }) {
    const navigate = useNavigate();
    const { currentUser, userRole, setTeamID, allTeams, setAllTeams, setStatus } = useContext(UserContext);
    const { setTeamName, setTeamDoc, setMembers, setPendingMembers } = useContext(TeamContext);
    const { setParentTab, isCreateTeam, setIsCreateTeam } = useContext(ModalContext);

    const [name, setName] = useState('');
    const [noName, setNoName] = useState(false);
    const [joinCode, setJoinCode] = useState('');
    const [noJoinCode, setNoJoinCode] = useState(false);
    const [sameJoinCode, setSameJoinCode] = useState();
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "400px",
          width: "100%",
        },
      };

    const handleClose = () => {
        onCancel();
      };

    const handleTeamNameChange = (event) => {
        const newName = (event.target.value);
        setName(newName);
    }
    const handleJoinCodeChange = (event) => {
        const newJoinCode = (event.target.value);
        setJoinCode(newJoinCode);
    }
    const createTeam = async () => {
        if (name !== '') {
            setNoName(false);
            if (joinCode !== '') {
                setNoJoinCode(false);
                try {
                    const teamCollectionRef = collection(db, 'teams');
                    const q = query(teamCollectionRef, where("joinCode", "==", joinCode))
                    const querySnapshot = await getDocs(q);
                    if (querySnapshot.size === 0) {
                        const teamData = {
                            teamName: name,
                            joinCode: joinCode,
                            creatorID: currentUser.userID,
                            creatorName: currentUser.firstName + ' ' + currentUser.lastName,
                            members: [{userID: currentUser.userID,
                                        accountType: 1,
                                        email: currentUser.email,
                                        firstName: currentUser.firstName,
                                        lastName: currentUser.lastName,
                                        subRosters: [] 
                                    }],
                            pendingMembers: [],
                            teamEvents: [],
                            trainingPlans: [],
                            subRosters: [],
                        }
                        const teamDoc = await addDoc(collection(db, "teams"), teamData);
                        const teamID = teamDoc.id;
                        await updateDoc(doc(db, 'teams', teamID), {teamID: teamID});
                        const coachData = {
                            teams: [...allTeams, {teamID: teamID, 
                                    teamName: name,
                                    status: 1,
                                    creatorName: `${currentUser.firstName} ${currentUser.lastName}`,
                                    joinCode: joinCode
                                }],
                            selectedTeam: teamID
                        }
                        console.log("Wrote to Teams");
                        await setDoc(doc(db, 'users', currentUser.userID), coachData, { merge: true });
                        console.log("Wrote to User");
                        setTeamID(teamID);
                        setTeamName(name);
                        setMembers([{userID: currentUser.userID,
                            accountType: 1,
                            email: currentUser.email,
                            firstName: currentUser.firstName,
                            lastName: currentUser.lastName}]);
                        setStatus(1);
                        const forAllTeams = [...allTeams, {teamID: teamID, 
                            teamName: name,
                            status: 1,
                            creatorName: `${currentUser.firstName} ${currentUser.lastName}`,
                            joinCode: joinCode
                        }] 
                        setAllTeams(forAllTeams);
                        setTeamDoc(teamData);
                        setPendingMembers([]);
                        setIsCreateTeam(false);
                    } else {
                        console.log('Already a team with same joinCode');
                        setSameJoinCode(true);
                    }
                } catch (err) {
                    console.log("Error creating document ", err)
                }
            } else {
                setNoJoinCode(true);
            }
        } else {
            setNoName(true);
        }
           
    }
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction='column' spacing={1}>
                    {noName && <text className='delete-red small'>*Must Enter in a name</text>}
                    {noJoinCode && <text className='delete-red small'>*Must Enter in A join code</text>}
                    {sameJoinCode && <text className='delete-red small'>*Join code already taken</text>}
                    <text className='light-grey small'>Create New Team</text>
                    <Stack direction="row" spacing={1}>
                        <Stack className='team-create-input-container' direction='column' spacing={1}>
                            <input 
                                type="text"
                                placeholder="team name"
                                value={name}
                                onChange={handleTeamNameChange}
                                className='team-create-input border-outline white small'
                                />
                            <input 
                                type="text"
                                placeholder="create join code"
                                value={joinCode}
                                onChange={handleJoinCodeChange}
                                className='team-create-input border-outline white small'
                                />
                        </Stack>
                        <button
                            className='team-create-button border-outline submit-green small'
                            onClick={createTeam}
                            >
                            Create Team
                        </button>
                    </Stack>
                    <div className='white very-small'>
                      Enter the name of your new team and create a custom join code. The join code is case sensitive and will be used by future members to join your team.
                    </div>
                </Stack>
            </Modal>
        </Box>
    )
}
