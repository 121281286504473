import "./Styles/App.css";
import "./Styles/EventSnippet.css"
import { Box } from "@mui/material";
import { BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import { UserProvider } from "./context/AuthContext";
import { TeamProvider } from "./context/TeamContext";
import { PrivateRoute } from "./context/PrivateRoute";
import React, { useContext } from "react";
import { UserContext } from "./context/AuthContext";
import Home from "./pages/LoggedIn/Home/Home";


import LoggedOut from "./pages/LoggedOut/LoggedOut";
import LoggedIn from "./pages/LoggedIn/LoggedIn";
import { EventsProvider } from "./context/EventContext";
import { ModalProvider } from "./context/ModalContext";

export default function App() {
  return (
    <Router>
      <UserProvider>
        <TeamProvider>
          <EventsProvider>
            <ModalProvider>
              <Box className='website-container'>        
                <LoggedIn />
                <LoggedOut />
              </Box>
            </ModalProvider>
          </EventsProvider>
        </TeamProvider>
      </UserProvider>
    </Router>
  )

}


