import { Stack, Box } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../../../../../../context/AuthContext';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import RunIcon from '../../../../../../media/icons/RunIcon/RunIcon';
import BikeIcon from '../../../../../../media/icons/BikeIcon/BikeIcon';
import SwimIcon from '../../../../../../media/icons/SwimIcon/SwimIcon';
import LiftIcon from '../../../../../../media/icons/LiftIcon/LiftIcon';

export default function SpecificDayPlan({day, selectedDay, setSelectedDay, setIsAddTraining, whichEventView, setWhichEventView}) {
    const [isHovered, setIsHovered] = useState(false);
    const { userRole, teamID } = useContext(UserContext);
    const switchSelectedDay = () => {
        setSelectedDay(day.date);
    }
    const handleSetEventChange = () => {
        if (userRole === 1) {
            setWhichEventView("coach-options")
        } else {
            setWhichEventView("personal");
        }
        setIsAddTraining(true);
    } 
    useEffect(() => {
        console.log(day.events)
    }, [day])
    return (
        <Stack 
            direction='column' 
            className={`specific-day-container ${isHovered && day.date !== selectedDay && 'hovered-day'}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={switchSelectedDay}
            >
            <Stack 
                direction='row' 
                justifyContent={`${day.date === selectedDay && teamID ? 'space-between' : 'right'}`}
                >
                {day.date === selectedDay && teamID && (
                    <button 
                        className='icon-button'
                        onClick={handleSetEventChange}
                        >
                        <EditIcon fontSize='small' style={{ color: "#7ACFF0" }} />
                    </button>
                )}
                <div>
                    {day.date === selectedDay ? (
                        <Stack alignItems='center' justifyContent='center' className='selected-indicator day-number-padding'>
                            <text className={`background-grey small mid-weight`}>
                                {day.day}
                            </text>
                        </Stack>
                    ) : (
                        <text className={`white small day-number-padding mid-weight`}>
                            {day.day}
                        </text>
                    )}
                </div>
            </Stack>
            <Stack className='events-container' direction='column' spacing={1}>
                {(day.trainings).map((item) => {
                    const workout = item.workout;
                    const title = workout.title;
                    const workoutSections = workout.workoutSections;
                    console.log(workoutSections[0].distance)
                    if (
                        (workoutSections.length === 1 && ((workoutSections[0].metricType === 2 && workoutSections[0].distance !== '') || 
                        (workoutSections[0].metricType === 1 && workoutSections[0].duration !== '') || 
                        (workoutSections[0].metricType === 0 && workoutSections[0].calories !== '')))
                    ) {
                        return (
                            <button className="text-button white training-day-title" key={item.id}>
                                {workoutSections.map((metric, index) => (
                                    <Stack key={index} direction="row" spacing={1} alignItems='center'>
                                        <text className='white small light-weight'>
                                            {metric.workoutSectionType === 1 && <RunIcon color={'#FFF'}/>}
                                            {metric.workoutSectionType === 3 && <BikeIcon color={'#FFF'}/>}
                                            {metric.workoutSectionType === 2 && <SwimIcon color={'#FFF'}/>}
                                            {metric.workoutSectionType === 4 && <LiftIcon color={'#FFF'}/>}
                                        </text>
                                        <Stack direction="row" spacing={0.25} alignItems='baseline'>
                                            <text className='white small light-weight'>
                                                {metric.metricType === 2 && metric.distance}
                                                {metric.metricType === 1 && metric.duration}
                                            </text>
                                            {metric.metricType !== 1 && (
                                                <text className='white small light-weight'>
                                                    {metric.unit === 2 && 'miles'}
                                                    {metric.unit === 1 && 'kilometers'}
                                                    {metric.unit === 3 && 'meters'}
                                                    {metric.unit === 4 && 'yards'}
                                                    {metric.unit === 5 && 'laps'}
                                                </text>
                                            )}
                                        </Stack>   
                                    </Stack>
                                ))}                                    
                            </button>
                        );
                    } else {
                        return (
                            <button className='white small light-weight text-button training-day-title' key={item.id}>
                                {title}
                            </button>
                        );
                    }
                })}

            </Stack>
        </Stack>
    )
}
