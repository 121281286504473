//Hold plan creation modal
import React, { useState, useContext, useEffect } from 'react';
import { TeamContext } from '../../../../../context/TeamContext';
import { UserContext } from '../../../../../context/AuthContext';
import { CalendarContext } from '../../../../../context/EventContext';
import { Box, Stack } from '@mui/material';
import Modal from "react-modal";
import { db } from '../../../../../firebase';
import { setDoc, doc, addDoc, collection, getDoc, updateDoc } from 'firebase/firestore';
import { ThreeSixty } from '@mui/icons-material';
import { columnGroupsStateInitializer } from '@mui/x-data-grid/internals';
 
export default function NewSubRoster({ isOpen, onCancel, setIsCreateRoster }) {

    const { userRole, teamID } = useContext(UserContext);
    const { members } = useContext(TeamContext);
    
    const [rosterName, setRosterName] = useState('');
    const [description, setDescription] = useState('');
    const [selected, setSelected] = useState([]);
    const [noName, setNoName] = useState(false);
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "30rem",
            width: "100%",
        },
    };
    const handleRosterNameChange = (event) => {
        const newName = event.target.value;
        setRosterName(newName);
    }
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value
        setDescription(newDescription);
    }

    const addMember = (member) => {
        console.log("ADDMEMBER: " + JSON.stringify(member));
        const index = selected.findIndex(person => person === member.userID);

        if (index !== -1) {
            const newSelectedArray = selected.filter((_, i) => i !== index);

            setSelected(newSelectedArray);
        } else {
            setSelected(prevSelected => [...prevSelected, member.userID]);
        }
    };

    const handleCreateSubRoster = async () => {
        const teamDocRef = doc(db, 'teams', teamID);
        const teamDocSnap = await getDoc(teamDocRef);
        if (teamDocSnap.exists()) {
            const data = teamDocSnap.data();
            let teamSubRosters = data.subRosters || [];
            let members = data.members || [];
            console.log("Members: " + JSON.stringify(members));
            members.map((member, index) => {
                const accountType = member.accountType;
                if (accountType === 1) {
                    members[index].subRosters.push(rosterName);
                } else {
                    selected.map((person) => {
                        if (member.userID === person) {
                            members[index].subRosters.push(rosterName);
                        }
                    })    
                }
            })
            teamSubRosters.push(rosterName);
            const newTeamData = {
                members: members,
                subRosters: teamSubRosters
            }
            await updateDoc(teamDocRef, newTeamData);
            setIsCreateRoster(false)
        }
    }
    useEffect(() => {
        console.log("Selected: " + JSON.stringify(selected));
    }, [selected])
    const handleClose = () => {
        onCancel();
    };
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction="column" spacing={1}>
                    {noName && <text className='delete-red small'>*Must Enter in a name</text>}
                    <input 
                        className='workout-name-input border-outline white small-medium'   
                        placeholder='Untitled Roster'
                        onChange={handleRosterNameChange}

                        />
                    <div 
                        className='page-border-line'
                        />
                    {/* <textarea 
                        className='plan-description white small'
                        placeholder='//Add Description'
                        onChange={handleDescriptionChange}
                    />
                    <div 
                        className='page-border-line'
                    /> */}
                    {userRole === 1 && (
                        <Stack direction='column' spacing={1}>
                            <text className='very-small light-grey'> Plan Roster</text>
                            {members.length > 1 ? members.map((member, index) => {
                                if (member.accountType === 0) {
                                    return (
                                        <Stack direction='column' spacing={0.5}>
                                            <Stack key={index} direction='row' spacing={7}  className='select-plan-roster'>
                                                {selected.some(person => person === member.userID) ? (
                                                    <div className='roster-select-selected' onClick={() => addMember(member)} />
                                                ) : (
                                                    <div className='roster-select' onClick={() => addMember(member)} />
                                                )}
                                                <text className='white small'>{member.firstName} {member.lastName}</text>
                                            </Stack>
                                        </Stack>
                                    )
                                }}) : (
                                    <text className='small white'>No Members</text>
                                )}
                                <div className='page-border-line'/>
                        </Stack>
                    )}
                    <button 
                        className='border-outline submit-green create-workout-button'
                        onClick={handleCreateSubRoster}
                        >
                        Create Sub roster
                    </button>
                </Stack>
            </Modal>
        </Box>
    )
}
 