import "../../../Styles/LoggedIn/Logbook/Logbook.css";
import React, { useState, useContext } from 'react';
import { UserContext } from "../../../context/AuthContext";
import { ModalContext } from "../../../context/ModalContext";
import { Box, Stack } from '@mui/material';
import LogbookNav from './LogbookNav/LogbookNav';
import Workouts from "./Workouts/Workouts";
import Entries from "./Enties/Entries";
import WorkoutCreation from "./Workouts/WorkoutCreation/WorkoutCreation";
import Plans from "./Plans/Plans";


export default function Logbook() {
    const { userRole } = useContext(UserContext);
    const { isWorkoutModal, setIsWorkoutModal, whichLogbookView, setWhichLogbookView } = useContext(ModalContext);

    return (
        <Box>
            <Stack direction='column' spacing={0.5}>
                {userRole !== 1 && <text className='small-medium white'>Logbook</text>}
                <LogbookNav 
                    whichLogbookView={whichLogbookView}
                    setWhichLogbookView={setWhichLogbookView}
                    setIsWorkoutModal={setIsWorkoutModal}
                    />

                <div className="page-border-line"
                    />

                <div className="home-border-line"/>
                {whichLogbookView === "entries" && (
                    <Entries />
                )}
                {whichLogbookView === "workouts" && (
                    <Workouts />
                )}
                {whichLogbookView === "plans" && (
                    <Plans />
                )}
            </Stack>
            {isWorkoutModal && (
                <Box>
                    <WorkoutCreation 
                        isOpen={isWorkoutModal}
                        setIsWorkoutModal={setIsWorkoutModal}
                        onCancel={() => setIsWorkoutModal(false)}
                        />
                </Box>    
            )}
        </Box>
    )
} 
 