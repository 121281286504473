//Holds the workout creation Modal

import React, { useState, useContext, useEffect } from 'react';
import { db } from '../../../../firebase';
import { setDoc, doc, addDoc, collection, Timestamp, getDoc, updateDoc } from 'firebase/firestore';
import { UserContext } from '../../../../context/AuthContext';
import { CalendarContext } from '../../../../context/EventContext';
import { TeamContext } from '../../../../context/TeamContext';
import { ModalContext } from '../../../../context/ModalContext';
import { Box, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import TrainingSectionValue from './LeftSide/TrainingSectionValue';
import TrainingMetric from './RightSide/TrainingMetric';
import moment from 'moment';


export default function UpdateTraining({ isOpen, onCancel, selectedDay }) {
    const { updateTrainingData, setUpdateTrainingData, setIsUpdateTraining } = useContext(ModalContext);
    const { allTrainings, setAllTrainings } = useContext(CalendarContext);

    const navigate = useNavigate();
    const { currentUser, userRole, teamID } = useContext(UserContext);
    const [teamName, setTeamName] = useState('');
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [sections, setSections] = useState([])
    const [section, setSection] = useState(null);
    const [currentSection, setCurrentSection] = useState(0)
    const [linkedDate, setLinkedDate] = useState(moment(selectedDay).format("YYYY-MM-DD"));
    const [linkedPlan, setLinkedPlan] = useState({});

    useEffect(() => {
        if (updateTrainingData) {
            console.log("updateTrainingData: " + JSON.stringify(updateTrainingData));
            setSections(updateTrainingData.workoutSections);
            const sectionData = updateTrainingData.workoutSections[0];
            setSection(sectionData);
            setTitle(updateTrainingData.title);
            setDescription(updateTrainingData.description);
            setLinkedPlan({planID: updateTrainingData.planID, planName: updateTrainingData.planName})
            setTeamName(updateTrainingData.teamName)
        }
    }, [updateTrainingData])
    
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "37rem",
          width: "100%",
        },
      }; 

    const handleWorkoutNameChange = (event) => {
        const newName = event.target.value;
        setTitle(newName);
    } 
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        setDescription(newDescription);
    }
    const handleClose = () => {
        onCancel();
      };

    const handleUpdateWorkout = async () => {
        try {
            console.log("updateTrainingData: " + JSON.stringify(updateTrainingData));
            const creationDate = moment();
            const creationTimeStamp = Timestamp.fromDate(creationDate.toDate());
            const linkedDateData = Timestamp.fromDate(moment(linkedDate).toDate());
            if (userRole === 1) {
                const teamDocRef = doc(db, "teams", teamID);
                const teamDocSnap = await getDoc(teamDocRef);
                if (teamDocSnap.exists()) {
                    const teamData = teamDocSnap.data();
                    const teamTrainings = teamData.teamTrainings;
                    teamTrainings.map(async (training, index) => {
                        const trainingDate = moment(training.createdAt.toDate()); // Assuming training.date is a Firestore Timestamp
                        const updateTrainingDate = moment(updateTrainingData.createdAt.toDate());
                        if (trainingDate.isSame(updateTrainingDate, 'second')) { // Compare dates at second precision
                            // console.log("gets here");
                            const indexToUpdate = index;
                            // console.log("index: " + index);
                            if (indexToUpdate !== -1) {
                                teamTrainings[indexToUpdate] = {   
                                    createdAt: creationTimeStamp,
                                    date: linkedDateData,
                                    linkedPlanID: linkedPlan.planID,
                                    linkedPlanName: linkedPlan.planName,
                                    linkedTeamID: teamID,
                                    linkedTeamName: teamName,
                                    trainingName: title,
                                    sectionCount: sections.length
                                }
                                const trainingPlanDocRef = doc(db, 'teams', teamID, "training plans", linkedPlan.planID);
                                const planDocSnap = await getDoc(trainingPlanDocRef);
                                if (planDocSnap.exists()) {
                                    const planData = planDocSnap.data();
                                    const planWorkouts = planData.planWorkouts;
                                    planWorkouts.map(async (workout, index) => {
                                        console.log("workout: " + JSON.stringify(workout));
                                        const workoutDate = moment(workout.workout.createdAt.toDate());
                                        console.log(workoutDate);
                                        if (workoutDate.isSame(updateTrainingDate, 'second')) {
                                            const workoutIndex = index;
                                            console.log("WorkoutIndex: " + index);
                                            if (workoutIndex !== -1) {
                                                console.log("gets HERE DOG");
                                                planWorkouts[workoutIndex] = {
                                                    date: linkedDateData,
                                                    creatorID: currentUser.userID,
                                                    linkedPlanName: linkedPlan.planName,
                                                    linkedPlanID: linkedPlan.planID,
                                                    linkedTeamID: teamID,
                                                    workout: {
                                                        createdAt: creationTimeStamp,
                                                        creator: currentUser.userID,
                                                        description: description,
                                                        title: title,
                                                        workoutSections: sections,
                                                    }
                                                }
                                                console.log("Plan Workouts: " + JSON.stringify(planWorkouts));
                                                const newContextObj = {
                                                    createdAt: creationTimeStamp,
                                                    date: linkedDateData,
                                                    linkedPlanID: linkedPlan.planID,
                                                    linkedPlanName: linkedPlan.planName,
                                                    linkedTeamID: teamID,
                                                    linkedTeamName: teamName,
                                                    sectionCount: section.length,
                                                    trainingName: title,
                                                }
                                                let newAllTrainings = [...allTrainings];
                                                newAllTrainings[indexToUpdate] = newContextObj;
                                                console.log("newAllTrainings: " + JSON.stringify(newAllTrainings));
                                                setAllTrainings(newAllTrainings);
                                                await updateDoc(trainingPlanDocRef, {planWorkouts})
                                                await updateDoc(teamDocRef, { teamTrainings });
                                            }
                                        } 
                                    })
                                }
                            }        
                        }
                    })
                }
                setUpdateTrainingData(null);
                setIsUpdateTraining(false);
            }     
        } catch (err) {
            console.error(err);
        }
    }

    const handleDeleteWorkout = async () => {
        if (userRole === 1) {
            const teamDocRef = doc(db, "teams", teamID);
            const teamDocSnap = await getDoc(teamDocRef);
            if (teamDocSnap.exists()) {
                const teamData = teamDocSnap.data();
                let teamTrainings = teamData.teamTrainings;
                const updateTrainingDate = moment(updateTrainingData.createdAt.toDate());
                console.log("UpdateTrainingDate: " + updateTrainingDate);
                teamTrainings = teamTrainings.filter(training => {
                    const trainingCreation = moment(training.createdAt.toDate());
                    console.log('trainingCreation: ' + trainingCreation);
                    return !trainingCreation.isSame(updateTrainingDate, "second");
                });
                let newAllTrainings = [...allTrainings];
                newAllTrainings = newAllTrainings.filter(training => {
                    const trainingCreation = moment(training.createdAt.toDate());
                    console.log('trainingCreation: ' + trainingCreation);
                    return !trainingCreation.isSame(updateTrainingDate, "second");
                });
                const planDocRef = doc(db, 'teams', teamID, 'training plans', updateTrainingData.planID);
                const planDocSnap = await getDoc(planDocRef);
                if (planDocSnap.exists()) {
                    const planData = planDocSnap.data();
                    let planWorkouts = planData.planWorkouts
                    planWorkouts = planWorkouts.filter(workout => {
                        const workoutDate = moment(workout.workout.createdAt.toDate());
                        console.log('workoutDate: ' + workoutDate);
                        return !workoutDate.isSame(updateTrainingDate, "second");
                    })
                    setAllTrainings(newAllTrainings);
                    await updateDoc(teamDocRef, {teamTrainings});
                    await updateDoc(planDocRef, {planWorkouts});
                    setIsUpdateTraining(false);
                }               
            }
        }    
    }

    useEffect(() => {
        console.log('linkedPlan: ' + JSON.stringify(linkedPlan));
    }, [linkedPlan]);

    const handleLinkedPlanChange = (event) => {
        const linkedPlanEvent = JSON.parse(event.target.value);
        setLinkedPlan(linkedPlanEvent);
    };
    const handleLinkedDateChange = (event) => {
        setLinkedDate(event.target.value);
    }
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction="column" spacing={1}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <input 
                            className='workout-name-input border-outline white small-medium'   
                            placeholder='Untitled Workout'
                            onChange={handleWorkoutNameChange}
                            value={title}
                            />
                        <button className='text-button delete-red small' onClick={handleDeleteWorkout}>
                            delete
                        </button>
                    </Stack>
                    <div 
                        className='page-border-line'
                        />
                    <textarea 
                        className='workout-description white small'
                        placeholder='//Add Description'
                        onChange={handleDescriptionChange}
                        value={description}
                    />
                    <div 
                        className='page-border-line'
                        />
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction='column' spacing={0.5}>
                            <text className='light-grey small'>
                                Linked Plan
                            </text>
                            <select 
                                className='plans-select small-medium'
                                onClick={handleLinkedPlanChange}
                                >
                                {updateTrainingData.teamPlans.map((plan) => (
                                    <option value={JSON.stringify({planID: plan.planID, planName: plan.planTitle})}>
                                        {plan.planTitle}
                                    </option>
                                ))}
                            </select>
                        </Stack>
                        <Stack direction='column' spacing={0.5}>
                            <text className='light-grey small'>
                                Linked Date
                            </text>
                            <Box className="plan-date-selector-container">
                                <Stack direction='row' spacing={1} alignItems='baseline'>
                                    <text className='white small-header  mid-weight'>
                                        {moment(linkedDate).format("ddd")}
                                    </text>
                                    <input
                                        type="date"
                                        id="date-input"
                                        className="plan-date-input small-medium"
                                        value={linkedDate}
                                        onChange={handleLinkedDateChange}
                                    />
                                </Stack>
                            </Box> 
                        </Stack>
                    </Stack>
                    <div 
                        className='page-border-line'
                        />

                    <Stack direction="row" spacing={1}>
                        <Box className="workout-creation-container">
                            <TrainingSectionValue
                                sections={sections}
                                setSections={setSections}
                                currentSection={currentSection}
                                setCurrentSection={setCurrentSection}
                                />
                        </Box>
                        <div className='vertical-border'
                            />
                        <TrainingMetric
                            sections={sections}
                            setSections={setSections}
                            currentSection={currentSection}
                            setCurrentSection={setCurrentSection}
                            section={section}
                            setSection={setSection}
                            />
                    </Stack>
                    <button 
                        className='border-outline submit-green create-workout-button'
                        onClick={handleUpdateWorkout}
                        >
                        Update Workout
                    </button>
                </Stack>
            </Modal>
        </Box>
    )
}
 