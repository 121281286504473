import "./../../../../Styles/LoggedIn/Teams/GroupChat.css";
import React, { useContext, useEffect, useState } from "react";
import { db } from "../../../../firebase";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { UserContext } from "../../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { Stack, Box } from "@mui/material";
import NewGroupChat from "./NewGroupChat";
import Feed from "./Feed/Feed";
import moment from "moment";

export default function GroupChat() {
    const { userRole, currentUser } = useContext(UserContext);
    const [groupChats, setGroupChats] = useState([]);
    const [activeChat, setActiveChat] = useState('');
    const [isCreateGroupChat, setIsCreateGroupChat] = useState(false);
    const navigate = useNavigate();

    const maxRecentMessage = 16;

    const truncateContent = (content) => {
        if (content.length > maxRecentMessage) {
            return content.substring(0, maxRecentMessage) + '...';
        }
        return content
    }
    useEffect(() => {
        const getGroupChats = async () => {
            try {
                const q = query(collection(db, "groupchats"), where("members", "array-contains", currentUser.userID));
                const querySnapshot = await getDocs(q);
                const documentData = querySnapshot.docs.map((doc) => doc.data());
                if (documentData.length > 0) {
                    console.log('data: ' + JSON.stringify(documentData[0]));
                    const docData = documentData[0];
                    const docID = docData.groupchatID;
                    setGroupChats(documentData);
                    setActiveChat(docID);    
                } else {
                    console.log('no docs in the groupChat collection')
                }
            } catch (err) {
                console.error(err)
            } 
        }
        getGroupChats();
    }, [])
    const changeGroupChat = (chat) => {
        setActiveChat(chat.groupchatID);
    }
    useEffect(() => {
        console.log('activeChat: ' + activeChat);
    }, [activeChat])


    return (
        <Stack direction='row' spacing={2}>
            <Box sx={{ width: '22%' }}>
                <Stack direction='column' spacing={0.5}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <text className="light-grey small">Group Chats</text>
                        {userRole === 1 && <button onClick={() => setIsCreateGroupChat(true)} className='white small-medium light-weight icon-button'>+</button>}
                    </Stack>
                    {groupChats.length >= 1 ? (
                        <Stack direction="column" spacing={0.5}>
                            {groupChats.map((chat, index) => (
                                <Stack key={index} direciton='row' justifyContent='space-between' onClick={() => changeGroupChat(chat)}>
                                    <Stack direction='row' spacing={0.5}>
                                        <div>
                                            <div className='purple-box' />
                                        </div>
                                        <Stack direction='column'>
                                            <text className='white small padding chat-name'>{chat.groupchatName}</text>
                                            <Stack direction='row' spacing={0.5} alignItems='center'>
                                                {chat.viewingKey.map((user) => {
                                                    if (user.userID === currentUser.userID) {
                                                        const lastUserView = moment(user.lastView.toDate());
                                                        const lastMessageSent = moment(chat.recentMessage.sentAt.toDate());
                                                        if (lastUserView.isBefore(lastMessageSent)) {
                                                            return (
                                                                <div>
                                                                    <div className='new-message-circle'/>
                                                                </div>
                                                            )                         
                                                        }
                                                    }
                                                })}
                                                <text className ='light-grey really-small'>{truncateContent(chat.recentMessage.content)}</text>
                                            </Stack>
                                        </Stack>
                                    </Stack>                                
                                </Stack>
                            ))}
                        </Stack>
                    ):(
                        <text className="light-grey small-header">
                            No Groups
                        </text>
                    )}
                </Stack>
                {isCreateGroupChat && (
                        <NewGroupChat
                            isOpen={isCreateGroupChat}
                            onCancel={() => setIsCreateGroupChat(false)}
                            setIsCreateGroupChat={setIsCreateGroupChat}
                            groupChats={groupChats}
                            setGroupChats={setGroupChats}
                            setActiveChat={setActiveChat}
                        />
                    )}
            </Box>
            <div className='full-vertical-border' />
            <Feed 
                activeChat={activeChat}
            />
        </Stack>
    )
}