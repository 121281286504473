import { Stack } from '@mui/material';
import React, { useContext } from 'react';
import { UserContext } from '../../../../context/AuthContext';
export default function FirstView() {
    const { userRole, teamID } = useContext(UserContext);
    return (
        <div>
            {!teamID && (
                <Stack direction='column' spacing={0.5} sx={{width: '30rem'}}>
                    <text className='update-purple small-header'>Welcome To Slate</text>
                    <div className='page-border-line'/>
                    {userRole === 1 && (
                        <text className='light-grey small'>We appreciate you choosing to manage your team through Slate. Make your first team by clicking on the top right button!</text>
                    )}
                    {userRole === 0 && (
                        <text className='light-grey small'>Join your first team by clicking on the top right button or continue to create personal training plans and take your fitness to the next level!</text>
                    )}
                </Stack>
            )}
        </div>
    )
}
