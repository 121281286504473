import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";
import { UserContext } from '../../context/AuthContext';
import { Box, Stack } from '@mui/material';
import Signup from './Signup';
import TempararyNav from './TempararyNav';
import Login from './Login';
import LandingPage from './LandingPage/LandingPage';

export default function LoggedOut() {
    const { currentUser } = useContext(UserContext)
    return (
        <Box>
            <Stack direction="column" >
                {currentUser ? (
                    null
                ) : (
                    <TempararyNav />
                )}
                <Routes>
                    <Route path='/Slate-Fitness' element={<LandingPage />}/>
                    <Route path='/Signup' element={<Signup />}/>
                    <Route path='/Login' element={<Login />}/>                
                </Routes>
            </Stack>
        </Box>
    )
}
