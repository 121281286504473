import React, {useCallback, useEffect, useState, useContext} from 'react';
import { CalendarContext } from '../../../../context/EventContext';
import moment from 'moment';
import { Box, Stack } from '@mui/material';
import "../../../../Styles/LoggedIn/Calendar/MonthlyCalendar.css";
import SpecificDay from './SpecificDay';

export default function MonthlyCalendar({targetDate, selectedDay, setSelectedDay, setShowEventCreation, whichEventView, setWhichEventView}) {
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', "Thu", "Fri", "Sat"];
    const [currentMonth, setCurrentMonth] = useState([]);
    const { allEvents, allTrainings } = useContext(CalendarContext);


    const isLastRowToShow =
        (moment(targetDate).daysInMonth() === 31 && moment(targetDate).startOf('month').day() >= 5) ||
        (moment(targetDate).daysInMonth() === 30 && moment(targetDate).startOf('month').day() === 6);

    useEffect(() => {
        const firstDayOfTargetMonth = moment(targetDate).startOf('month');
        const dayOfWeek = firstDayOfTargetMonth.day();
        const startDate = firstDayOfTargetMonth.clone().subtract(dayOfWeek, 'days');
        const endDate = startDate.clone().add(42, 'days');

        let currentMonth = []
        for (let date = startDate.clone(); date.isBefore(endDate); date.add(1, 'day')) {
            currentMonth = [
                ...currentMonth, 
                {
                    date: date.format("YYYY-MM-DD"),
                    day: date.format("D"),
                    events: [],
                    trainings: [],
                  }
            ]
          }
          currentMonth.map((day, index) => {
            allEvents.map((event) => {
                const date = day.date;
                const eventDate = moment(event.startDate.toDate()).format("YYYY-MM-DD");

                if (date === eventDate) {
                    currentMonth[index] = {
                        ...currentMonth[index], 
                        events: [
                            ...currentMonth[index].events,
                            event
                        ]
                    }
                }
            })
            allTrainings.map((training) => {
                const date = day.date;
                const trainingDate = moment(training.date.toDate()).format("YYYY-MM-DD");
                if (date === trainingDate) {
                    currentMonth[index] = {
                        ...currentMonth[index],
                        trainings: [
                            ...currentMonth[index].trainings,
                            training
                        ]
                    }
                }
            })
        })
        // console.log(JSON.stringify(currentMonth));
        setCurrentMonth(currentMonth);
        setSelectedDay(targetDate);
        
    }, [targetDate, allEvents, allTrainings])



    useEffect(() => {
        console.log("allEvents: " + JSON.stringify(allEvents));
    }, [allEvents])
    
    return (
        <Stack direction='column' spacing={1} className='monthly-calendar-container'>
            <div className='calendar-container'>
            <Stack direction="row" spacing={11}>
                    {daysOfWeek.map((name) => (
                        <Stack direction='row' justifyContent='center'>
                            <text className='light-grey very-small day-of-week-text'>
                                {name}
                            </text>                            
                        </Stack>
                    ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(0,7).map((day, index) => (
                            <SpecificDay 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setShowEventCreation={setShowEventCreation}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(7,14).map((day, index) => (
                            <SpecificDay 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setShowEventCreation={setShowEventCreation}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(14,21).map((day, index) => (
                            <SpecificDay 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setShowEventCreation={setShowEventCreation}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(21,28).map((day, index) => (
                            <SpecificDay 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setShowEventCreation={setShowEventCreation}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                <Stack direction='row'>
                        {currentMonth.slice(28,35).map((day, index) => (
                            <SpecificDay 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setShowEventCreation={setShowEventCreation}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                </Stack>
                {isLastRowToShow && (
                    <Stack direction='row'>
                        {currentMonth.slice(35,42).map((day, index) => (
                            <SpecificDay 
                                day={day}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setShowEventCreation={setShowEventCreation}
                                whichEventView={whichEventView}
                                setWhichEventView={setWhichEventView}
                            />
                        ))}
                    </Stack>
                )}
            </div>
        </Stack>
    )
}
