import { Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { TeamContext } from '../../../../../context/TeamContext';
import { UserContext } from '../../../../../context/AuthContext';
import { doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';
 
export default function PendingMembers() {
    const { pendingMembers, setPendingMembers, members, setMembers, teamName, teamDoc } = useContext(TeamContext);
    const { currentUser, teamID, status } = useContext(UserContext);

    useEffect(() => {
        if (teamID) {
            const teamDocRef = doc(db, 'teams', teamID);
            const unsubscribe = onSnapshot(teamDocRef, (teamDocSnap) => {
                const teamDocData = teamDocSnap.data();
                const pendingMembers = teamDocData.pendingMembers;
                setPendingMembers(pendingMembers);
            })
            return () => unsubscribe();    
        }
    }, []);

    const handleAcceptMember = async (member, index) => {
        if (teamDoc.creatorID === currentUser.userID) {
            const userDocRef = doc(db, 'users', member.userID);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const teams = userData.teams || [];
                const index = teams.findIndex((team) => {
                    return (
                    team.status === 0 &&
                    team.teamID === teamID &&
                    team.teamName === teamName
                    );
                });
                if (index !== -1) {
                    teams[index].status = 1
                    // const newTeamsData = {
                    //     teams: newTeamsArray
                    // }
                    await setDoc(userDocRef, {teams}, {merge: true});
                  } else {
                    // The map with the specified values was not found
                    console.log("Map not found");
                  }
            }        
            const newMembersArray = [
                ...members, 
                member
            ]
            const newPendingMembersArray = [
                ...pendingMembers.slice(0, index), 
                ...pendingMembers.slice(index + 1)
            ]
            const teamDocRef = doc(db, 'teams', teamID);
            await setDoc(teamDocRef, {pendingMembers: newPendingMembersArray, members: newMembersArray}, {merge: true});
            console.log('wrote to teams doc');
            setPendingMembers(newPendingMembersArray);
            setMembers(newMembersArray);    
        }
    }

    const handleDeclineMember = async (member, index) => {
        const userDocRef = doc(db, 'users', member.userID);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const teams = userData.teams || [];
            const index = teams.findIndex((team) => {
                return (
                team.status === 0 &&
                team.teamID === teamID &&
                team.teamName === teamName
                );
            });
            if (index !== -1) {
                teams[index].status = -1
                // const newTeamsData = {
                //     teams: newTeamsArray
                // }
                await setDoc(userDocRef, {teams}, {merge: true});
              } else {
                // The map with the specified values was not found
                console.log("Map not found");
              }
        }        
        const newPendingMembersArray = [
            ...pendingMembers.slice(0, index), 
            ...pendingMembers.slice(index + 1)
        ]
        const teamDocRef = doc(db, 'teams', teamID);
        await setDoc(teamDocRef, {pendingMembers: newPendingMembersArray}, {merge: true});
        console.log('wrote to teams doc');
        setPendingMembers(newPendingMembersArray);
        setMembers(newMembersArray);
    }
    return (
        <div>
            {pendingMembers.length > 0 && (
                <Stack direction='column' spacing={1}>
                    <text className='small-header white'>
                        Pending Members
                    </text>
                    {pendingMembers.map((member, index) => (
                        <div className='roster-container'>
                            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                                <Stack direction='column' spacing='0.5'>
                                    <text className='small white'>
                                        {member.firstName}  {member.lastName}
                                    </text>
                                    <text className='small light-grey light-weight'>
                                        {member.accountType === 0 && 'athlete'}
                                        {member.accountType === 1 && 'coach'}
                                        {member.accountType === 2 && 'parent'}
                                    </text>
                                </Stack>
                                {status !== 0 && (
                                    <Stack direction='row' spacing={2}>
                                        <button 
                                            className='text-button small submit-green'
                                            onClick={()=> handleAcceptMember(member, index)}
                                            >
                                            Accept
                                        </button>
                                        <button 
                                            className='text-button small delete-red'
                                            onClick={() => handleDeclineMember(member, index)}
                                            >
                                            Decline
                                        </button>
                                    </Stack>
                                )}
                            </Stack>
                        </div>
                    ))}
                </Stack>
            )}
        </div>
    )
}
