import React from 'react';
import { Box, Stack } from '@mui/material';
import WorkoutSnapshot from './WorkoutSnapshot';

export default function RecentWorkouts() {
    return (
        <Box sx={{width: "33%"}}>
            <Stack direction="column" spacing={1}>
                <text className='light-grey small'>Recent Workouts</text>    
                {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <select className="white workout-type-select">
                        <option>All Types</option>
                        <option>Run</option>
                        <option>Swim</option>
                        <option>Bike</option>
                        <option>Lift</option>
                    </select>
                    <button className="white text-button">
                        See All
                    </button>
                </Stack>      */}
                <WorkoutSnapshot />   
            </Stack>
        </Box>
    )
}
