import React, { useState, useContext, useEffect } from 'react';
import { Stack, Box } from '@mui/material';
import "../../../../Styles/LoggedIn/Logbook/Plans.css";
import SwitchPlan from './SwtichPlan/SwitchPlan';
import NewPlan from './NewPlan/NewPlan';
import moment from 'moment';
import TopPlan from './TopPlan/TopPlan';
import NewTraining from './NewTraining/NewTraining';
import { TeamContext } from '../../../../context/TeamContext';
import { UserContext } from '../../../../context/AuthContext';
import { CalendarContext } from '../../../../context/EventContext';
import { ModalContext } from '../../../../context/ModalContext';
import { doc, onSnapshot, collection, query, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase';
import MonthlyPlan from './PlanCalendar/MonthlyPlan/MonthlyPlan';
import RightPlan from './PlanCalendar/MonthlyPlan/RightPlan/RightPlan';
import "./../../../../Styles/LoggedIn/Calendar/Calendar.css";
import "./../../../../Styles/LoggedIn/Calendar/MonthlyCalendar.css";
import "./../../../../Styles/LoggedIn/Calendar/RightBar.css";
import DetailPlan from './DetailPlan/DetailPlan';


export default function Plans() {
    const { teamName, allTeams } = useContext(TeamContext);
    const { currentUser, userRole, teamID, status } = useContext(UserContext);
    const { trainingPlans, setTrainingPlans } = useContext(CalendarContext);
    const { isAddTraining, setIsAddTraining, isCreatePlan, setIsCreatePlan } = useContext(ModalContext);
    
    const [targetDate, setTargetDate] = useState(moment().format("YYYY-MM-DD"))
    const [selectedDay, setSelectedDay] = useState(moment().format("YYYY-MM-DD"));
    const [personalPlans, setPersonalPlans] = useState([]);
    const [teamPlans, setTeamPlans] = useState([]);
    const [whichEventView, setWhichEventView] = useState(null);
    const [showSpecificPlan, setShowSpecificPlan] = useState(false);
    const [planDetails, setPlanDetails] = useState();

    const [trainings, setTrainings] = useState([]);


    useEffect(() => {
        console.log("Is Run");
        const getTeamPlans = async () => {
            if (status === 1) {
                const teamDocRef = doc(db, "teams", teamID);
                const unsubscribe = onSnapshot(teamDocRef, (teamDocSnap) => {
                    if (teamDocSnap.exists()) {
                        const teamData = teamDocSnap.data();
                        const teamPlans = teamData.trainingPlans || [];
                        console.log("teamPlans: " + JSON.stringify(teamPlans));
                        setTeamPlans(teamPlans);
                    }
                })
                return () => unsubscribe()    
            }
        }
        getTeamPlans();
    }, [teamID])
    useEffect(() => {
        console.log("User is run")
        const getUserPlans = async () => {
                const userDocRef = doc(db, "users", currentUser.userID);
                const unsubscribe = onSnapshot(userDocRef, (userDocSnap) => {
                    if (userDocSnap.exists()) {
                        const userData = userDocSnap.data();
                        const userPlans = userData.trainingPlans || [];
                        console.log("UserPlans: " + userPlans);
                        setPersonalPlans(userPlans);
                    }
                })
                return () => unsubscribe()    
            }            
        getUserPlans();
    }, [])

    useEffect(() => {
        const getTeamTrainings = async () => {
            if (userRole === 1) {
                console.log('teamID: ' + teamID);
                const trainingsCollectionRef = collection(db, 'teams', teamID, 'training plans');
                const q = query(trainingsCollectionRef);
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    console.log("querySnapShot  " + querySnapshot.size);
                    if (querySnapshot.size > 0) {
                        let newTrainings = [];
                        querySnapshot.forEach((doc) => {
                            const planData = doc.data();
                            const docTrainings = planData.planWorkouts;
                            newTrainings.push(...docTrainings);
                        });
                        console.log("Team Trainings: :" + trainings);
                        setTrainings(newTrainings);
                    } else {
                        console.log("No documents found in the query snapshot.");
                    }
                });
                return () => unsubscribe()
            } else {
                let teamTrainings = [];
                for (const team of allTeams) {
                    const teamStatus = team.status;
                    if (teamStatus === 1) {
                      console.log("GETS HERE?")
                      const teamID = team.teamID;
                      const teamTrainingsRef = collection(db, 'teams', teamID, 'training plans');
                      const teamSnapShot = await getDocs(teamTrainingsRef);
                      console.log("teamSnapShotSize" + teamSnapShot.size);
                      if (teamSnapShot.size > 0) {
                        teamSnapShot.forEach((doc) => {
                            const planData = doc.data();
                            const docTrainings = planData.planWorkouts;
                            teamTrainings = [...teamTrainings, ...docTrainings];
                        })
                      } 
                    }
                  }
                const userPlanCollectionRef = collection(db, 'users', currentUser.userID, 'training plans')
                const q = query(userPlanCollectionRef);
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let personalTrainings = [];
                    if (querySnapshot.size > 0) {
                        querySnapshot.forEach((doc) => {
                            const planData = doc.data();
                            const docTrainings = planData.planWorkouts;
                            personalTrainings.push(...docTrainings);
                        });

                        const allUserTrainings = [...teamTrainings, ...personalTrainings];
                        setTrainings(allUserTrainings);
                    } else {
                        console.log("No documents found in the query snapshot.");
                        const allUserTrainings = [...teamTrainings];
                        setTrainings(allUserTrainings);
                    }
                })
                return () => unsubscribe();
            }
        }
        getTeamTrainings();
    }, [teamID])
    useEffect(() => {
        console.log("teamPlans: " + JSON.stringify(teamPlans));
    }, [teamPlans]);
    useEffect(() => {
        console.log("plan Details: " + JSON.stringify(planDetails));
    }, [planDetails])
    return (
        <div>
            <Stack direction='column' spacing={1}>
                <SwitchPlan 
                    setIsCreatePlan={setIsCreatePlan}
                    personalPlans={personalPlans}
                    teamPlans={teamPlans}
                    teamName={teamName}
                    setShowSpecificPlan={setShowSpecificPlan}
                    setPlanDetails={setPlanDetails}
                />
                <div className="page-border-line"
                />
                {showSpecificPlan !== true ? (
                    <Stack direction='row' spacing={2} className={`${personalPlans.length === 0 && teamPlans.length === 0 && 'plan-calendar-container'}`}>
                        <Stack
                            sx={{
                                width: "75%"
                            }}
                            direction='column'
                            spacing={1}
                            >
                            <TopPlan 
                                setIsAddTraining={setIsAddTraining}
                                personalPlans={personalPlans}
                                teamPlans={teamPlans}
                                targetDate={targetDate}
                                setTargetDate={setTargetDate}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                            />
                            <MonthlyPlan 
                                targetDate={targetDate}
                                setTargetDate={setTargetDate}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                setIsAddTraining={setIsAddTraining}   
                                setWhichEventView={setWhichEventView}    
                                trainings={trainings}
                                />
                        </Stack>
                        <Box
                            sx={{
                                width: "25%"
                            }}>
                            <Stack direction="column">
                                <div>
                                    <RightPlan
                                        isAddTraining={isAddTraining}
                                        setIsAddTraining={setIsAddTraining}
                                        whichEventView={whichEventView}
                                        setWhichEventView={setWhichEventView}
                                        selectedDay={selectedDay}
                                        setSelectedDay={setSelectedDay}
                                        trainingPlans={trainingPlans}
                                        setTrainingPlans={setTrainingPlans}
                                        
                                        trainings={trainings}
                                        />
                                </div>
                            </Stack>
                        </Box>
                    </Stack>
                ):(
                    <DetailPlan 
                        setShowSpecificPlan={setShowSpecificPlan}
                        planDetails={planDetails}
                        setPlanDetails={setPlanDetails}
                    />
                )}
            </Stack>
            {isCreatePlan && (
                <Box>
                    <NewPlan 
                        isOpen={isCreatePlan}
                        setIsCreatePlan={setIsCreatePlan}
                        onCancel={() => setIsCreatePlan(false)}
                        />
                </Box>    
            )}
            {isAddTraining && (
                <NewTraining 
                    isOpen={isAddTraining}
                    setIsAddTraining={setIsAddTraining}
                    onCancel={() => setIsAddTraining(false)}
                    personalPlans={personalPlans}
                    teamPlans={teamPlans}
                    selectedDay={selectedDay}  
                    setSelectedDay={setSelectedDay} 
                    teamID={teamID}                         
                    teamName={teamName}
                    />
            )}
        </div>
    )
}
