import React, { useEffect, useRef } from 'react';
import "../../../Styles/Icons/Icons.css";
import "../../../Styles/Icons/ClipBoard.css";

const EntryIcon = ({ color }) => {
    const svgRef = useRef(null);

    useEffect(() => {
        // Function to resize and center the viewBox based on the bounding box of the SVG content
        const resizeViewBox = () => {
            const svgElement = svgRef.current;
            const bbox = svgElement.getBBox();
            const viewBoxValue = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
            svgElement.setAttribute('viewBox', viewBoxValue);
        };

        // Call resizeViewBox on initial render and on window resize
        resizeViewBox();
        window.addEventListener('resize', resizeViewBox);
        return () => window.removeEventListener('resize', resizeViewBox);
    }, []);

    return (
        <svg ref={svgRef} className='small-icon' id="_ëÎÓÈ_1" xmlns="http://www.w3.org/2000/svg">
            <path fill='white' d="M2792.28,472.98h-137.45v57.48c0,22.43-18.25,40.69-40.69,40.69h-374.75c-22.43,
            0-40.69-18.25-40.69-40.69v-57.48h-137.45c-31.46,0-56.96,25.5-56.96,56.96v1102.75c0,31.46,25.5,56.96,
            56.96,56.96h731.02c31.46,0,56.96-25.5,56.96-56.96V529.95c0-31.46-25.5-56.96-56.96-56.96ZM2668.4,
            1379.3h-483.27c-8.98,0-16.27-7.28-16.27-16.27s7.29-16.27,16.27-16.27h483.27c8.98,0,16.27,7.29,16.27,
            16.27s-7.29,16.27-16.27,16.27ZM2668.4,1191.49h-483.27c-8.98,0-16.27-7.28-16.27-16.27s7.29-16.27,
            16.27-16.27h483.27c8.98,0,16.27,7.29,16.27,16.27s-7.29,16.27-16.27,16.27ZM2668.4,1003.69h-483.27c-8.98,
            0-16.27-7.29-16.27-16.27s7.29-16.27,16.27-16.27h483.27c8.98,0,16.27,7.28,16.27,16.27s-7.29,16.27-16.27,
            16.27ZM2668.4,815.89h-483.27c-8.98,0-16.27-7.29-16.27-16.27s7.29-16.27,16.27-16.27h483.27c8.98,0,16.27,
            7.28,16.27,16.27s-7.29,16.27-16.27,16.27Z"/>
            <rect fill='white' x="2223.12" y="399.22" width="407.3" height="147.52" rx="16.27" ry="16.27"/>
        </svg>
    );
}

export default EntryIcon;
