import React, { useContext, useEffect, useState }from 'react';
import { UserContext } from '../../../../../context/AuthContext';
import { Box, Stack } from '@mui/material';
import { db } from '../../../../../firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
   
export default function WorkoutSnapshot() {
    const { currentUser } = useContext(UserContext);
    const [workouts, setWorkouts] = useState([]);

    useEffect(() => {
        const getRecentWorkouts = async () => {
            const userDocRef = doc(db, "users", currentUser.userID);
            const unsubscribe = onSnapshot(userDocRef, (doc) => {
                if (doc.exists()) {
                    const userData = doc.data();
                    let workoutsArray = userData.workouts || [];
                    workoutsArray.sort((a, b) => b.creationDate.toMillis() - a.creationDate.toMillis());
                    console.log("workouts Array: " + JSON.stringify(workoutsArray));
                    const newWorkoutArray = workoutsArray;
                    setWorkouts(newWorkoutArray);     
                }
            })
            return () => unsubscribe();
        }
        getRecentWorkouts();
    }, [])

    useEffect(() => {
        console.log("workouts: " + JSON.stringify(workouts));
    }, [workouts])

    const keyToColor = {
        pace: 'pace-blue',
        repeat: 'ocean-blue',
        // Add more key-color pairs as needed
      };
    return (
        <div>
            <Stack direction="column" spacing={1}> 
                {workouts.slice(0,7).map((workout, index) => {
                    const workoutSections = workout.workoutSections;
                    console.log("workoutsSections: " + JSON.stringify(workoutSections));
                    return ( 
                    <button key={`workout-${index}`} className='workout-snapshot-container border-outline'>
                        {workoutSections.length === 1 ? (
                            <div>
                                {(workoutSections).map((item) => (
                                    <Stack className='workout-snapshot-content' spacing={3} direction="row" alignContent="center">
                                        <Stack direction="column" spacing={0.75}>
                                            <text className='white very-small mid-weight'>Type</text>
                                            <text className='submit-green small mid-weight'>
                                                {item.workoutSectionType === 1 ? 'run' :
                                                item.workoutSectionType === 2 ? 'swim' :
                                                item.workotuSectionType === 3 ? 'bike' : 
                                                item.workoutSectionType === 4 ? 'lift' :
                                                item.workotuSectionType === 5 ? 'other' :                                                          
                                                null
                                                }
                                            </text>
                                        </Stack>
                                        {item.metricType === 2 ? (
                                            <div>
                                                <Stack direction="column" spacing={0.75}>
                                                    <text className='white very-small mid-weight'>Distance</text>
                                                    <Stack direction='row' spacing={0.25}>
                                                        <text className = 'distance-red small mid-weight'>
                                                            {item.distance}
                                                        </text>
                                                        <text className='distance-red small mid-weight'>
                                                            {item.unit === 2 ? 'mi' : 
                                                             item.unit === 1 ? 'km' :
                                                             item.unit === 3 ? 'm' : 
                                                             item.unit === 4 ? 'yd' : 
                                                             item.unit === 5 ? 'laps' : 
                                                             null}
                                                        </text>
                                                    </Stack>
                                                </Stack>
                                            </div>
                                        ): item.metricType === 1 ? (
                                            <div>
                                                <Stack direction="column" spacing={0.75}>
                                                    <text className='white very-small mid-weight'>Duration</text>
                                                    <text className='duration-yellow small mid-weight'>{item.duration}</text>
                                                </Stack>
                                            </div>
                                        ) : item.metricType === 0 ? (
                                            <div>
                                                <Stack direction="column" spacing={0.75}>
                                                    <text className='white very-small mid-weight'>Calories</text>
                                                    <text className='calorie-orange small mid-weight'>{item.calories} cal</text>
                                                </Stack>
                                            </div>
                                        ) : (
                                            <div>

                                            </div>
                                        )}
                                        {item.pace && (
                                            <Stack direction="column" spacing={0.75}>
                                                <text className='white very-small mid-weight'>pace</text>
                                                <text className={`small mid-weight pace-blue`}>{item.pace}</text>
                                            </Stack>
                                        )}
                                        {item.repeat && (
                                            <Stack direction="column" spacing={0.75}>
                                                <text className='white very-small mid-weight'>repeat</text>
                                                <text className={`small mid-weight ocean-blue`}>{item.repeat}</text>
                                            </Stack>
                                        )}
                                    </Stack>
                                ))}
                            </div>
                        ):(
                            <div>
                                 <Stack className='workout-snapshot-content' spacing={3} direction="row" alignContent="center">
                                    <Stack direction="column" spacing={0.75}>
                                        <text className='white very-small mid-weight'>Type</text>
                                        <text className='submit-green small mid-weight'>{workout.length} seg</text>
                                    </Stack>
                                    {workoutSections.slice(0,4).map((item) => (
                                        <div>
                                            <Stack direction="column" spacing={0.75}>
                                                <text className='white very-small mid-weight'>{item.type}</text>
                                                {item.descriptor === 'distance' ? (
                                                    <text className={`small mid-weight distance-red`}>{item.distance} mi</text>
                                                ): item.descriptor === 'duration' ? (
                                                    <text className={`small mid-weight duration-yellow`}>{item.duration}</text>
                                                ): item.descriptor === 'calories' ? (
                                                    <text className={`small mid-weight calorie-orange`}>{item.calories} cal</text>
                                                ):(
                                                    <div>

                                                    </div>
                                                )}
                                            </Stack>
                                        </div>
                                    ))}
                                </Stack>
                            </div>
                        )}
                    </button>
                )})}
            </Stack>
        </div>
    )
}
