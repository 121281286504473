//Hold plan creation modal
import React, { useState, useContext, useEffect } from 'react';
import { TeamContext } from '../../../../../context/TeamContext';
import { UserContext } from '../../../../../context/AuthContext';
import { CalendarContext } from '../../../../../context/EventContext';
import { Box, Stack } from '@mui/material';
import Modal from "react-modal";
import { db } from '../../../../../firebase';
import { setDoc, doc, addDoc, collection, getDoc, updateDoc } from 'firebase/firestore';
 
export default function NewPlan({ isOpen, onCancel, setIsCreatePlan }) {

    const { currentUser, userRole, teamID } = useContext(UserContext);
    const { teamName, members, subRosters, setSubRosters } = useContext(TeamContext);
    
    const [planName, setPlanName] = useState('');
    const [description, setDescription] = useState('');
    const [selected, setSelected] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [noName, setNoName] = useState(false);
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "30rem",
            width: "100%",
        },
    };
    const handlePlanNameChange = (event) => {
        const newName = event.target.value;
        setPlanName(newName);
    }
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value
        setDescription(newDescription);
    }
    const handleCreatePlan = async () => {
        if (planName !== '') {
            if (userRole === 1) {
                try {
                    const teamDocRef = doc(db, "teams", teamID);
                    const teamDocSnap = await getDoc(teamDocRef);
                    const trainingPlanCollectionRef = collection(db, 'teams', teamID, "training plans");
                    if (teamDocSnap.exists()) {
                        const teamData = teamDocSnap.data();
                        let teamSubRosters = teamData.subRosters || [];
                        let members = teamData.members || [];
                        let planRosterData
                        let data
                        let newSubRostersData
                        console.log("Members: " + JSON.stringify(members));
                        if (selected.length === 0 && selectedMembers.length > 0) {
                            members.map((member, index) => {
                                selectedMembers.map((person) => {
                                    if (member.userID === person) {
                                        members[index].subRosters.push(planName);
                                    }
                                })
                            })
                            teamSubRosters.push(planName);
                            planRosterData = [planName]
                            data = {
                                members: members,
                                subRosters: teamSubRosters
                            }    
                            newSubRostersData = [...subRosters, planName]
                        } else if (selected.length > 0 && selectedMembers.length === 0) {
                            planRosterData = [...selected];
                            data = {}
                            newSubRostersData =[...subRosters];
                        } else if (selected.length > 0 && selectedMembers.length > 0) {
                            let allMembers = [] 
                            members.map((member) => {
                                console.log("member:" + JSON.stringify(member));
                                selected.map((roster) => {
                                    if (member.subRosters.includes(roster) && !allMembers.includes(member.userID)) {
                                        allMembers.push(member.userID);
                                    }
                                })
                            })
                            selectedMembers.map((person) => {
                                allMembers.push(person);
                            })
                            members.map((member, index) => {
                                allMembers.map((person) => {
                                    if (member.userID === person) {
                                        members[index].subRosters.push(planName);
                                    }
                                })
                            })
                            teamSubRosters.push(planName);
                            planRosterData = [planName]
                            data = {
                                members: members,
                                subRosters: teamSubRosters
                            }
                            newSubRostersData = [...subRosters, planName];
                        } else {
                            planRosterData = []
                            data = {}
                            newSubRostersData = [...subRosters];
                        }
                        console.log(teamData);
                        const newTeamData = {
                            planCreatorName: currentUser.firstName,
                            planCreatorID: currentUser.userID,
                            planTitle: planName,
                            description: description,
                            subRosters: planRosterData,
                            linkedTeamID: teamID,
                            linkedTeamName: teamName,
                            planWorkouts: [],
                        }
                        const newTrainingPlan = await addDoc(trainingPlanCollectionRef, newTeamData);
                        const planID = newTrainingPlan.id;
                        const trainingPlanDocRef = doc(trainingPlanCollectionRef, planID);
                        const trainingPlanDocSnap = await getDoc(trainingPlanDocRef);
                        if (trainingPlanDocSnap.exists()) {
                            await setDoc(trainingPlanDocRef, {planID: planID}, {merge: true})
                        }
                        const trainingPlans = teamData.trainingPlans || [];
                        console.log(trainingPlans)
                        const denomalizedTeamData = [...trainingPlans , {
                            planCreatorName: currentUser.firstName,
                            planID: planID,
                            planTitle: planName,
                            linkedTeamID: teamID,
                            linkedTeamName: teamName
                        }]
                        console.log(denomalizedTeamData);
                        data = {
                            ...data,
                            trainingPlans: denomalizedTeamData,
                        }
                        console.log(data);
                        console.log(data)
                        await updateDoc(teamDocRef, data);
                        setIsCreatePlan(false);

                        setSubRosters(newSubRostersData);
                    }
                } catch (err) {
                    console.error(err);
                }   
        
            } else {
                try {
                    const userDocRef = doc(db, "users", currentUser.userID);
                    const userDocSnap = await getDoc(userDocRef);
                    if (userDocSnap.exists()) {
                        const trainingPlanCollectionRef = collection(db, 'users', currentUser.userID, "training plans");
                        const userData = userDocSnap.data();
                        console.log(userData);
                        const newUserData = {
                            planCreatorName: currentUser.firstName,
                            planCreatorID: currentUser.userID,
                            planTitle: planName,
                            description: description,
                            planWorkouts: [],
                        }
                        const newTrainingPlan = await addDoc(trainingPlanCollectionRef, newUserData);
                        const planID = newTrainingPlan.id;
                        const trainingPlanDocRef = doc(trainingPlanCollectionRef, planID);
                        const trainingPlanDocSnap = await getDoc(trainingPlanDocRef);
                        if (trainingPlanDocSnap.exists()) {
                            await setDoc(trainingPlanDocRef, {planID: planID}, {merge: true})
                        }
                        const trainingPlans = userData.trainingPlans || [];
                        console.log(trainingPlans)
                        const denomalizedTeamData = [...trainingPlans , {
                            planCreatorName: currentUser.firstName,
                            planID: planID,
                            planTitle: planName,
                            linkedTeamID: null,
                            linkedTeamName: null
                        }]
                        console.log(denomalizedTeamData);
                        const data = {
                            trainingPlans: denomalizedTeamData,
                        }
                        console.log(data)
                        await setDoc(userDocRef, data, {merge: true})
                        setIsCreatePlan(false);
                    }
                } catch (err) {
                    console.error(err);
                }   
            }    
        } else {
            setNoName(true);
        }
    }
    const addRoster = (roster) => {
        console.log("addRoster")
        const index = selected.findIndex(item => item === roster);

        if (index !== -1) {
            const newSelectedArray = selected.filter((_, i) => i !== index);

            setSelected(newSelectedArray);
        } else {
            setSelected(prevSelected => [...prevSelected, roster]);
        }
    };
    const addMember = (member) => {
        console.log("ADDMEMBER: " + JSON.stringify(member));
        const index = selectedMembers.findIndex(person => person === member.userID);

        if (index !== -1) {
            const newSelectedArray = selectedMembers.filter((_, i) => i !== index);

            setSelectedMembers(newSelectedArray);
        } else {
            setSelectedMembers(prevSelected => [...prevSelected, member.userID]);
        }
    };
    useEffect(() => {
        console.log("Selected: " + JSON.stringify(selectedMembers));
    }, [selectedMembers])
    const handleClose = () => {
        onCancel();
    };
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction="column" spacing={1}>
                    {userRole === 1 ? (
                        <text className='small-medium white'>
                            Plan For {teamName}
                        </text>
                    ):(
                        <text className='small-medium white'>
                            New Personal Plan
                        </text>
                    )}
                    {noName && <text className='delete-red small'>*Must Enter in a name</text>}
                    <div 
                        className='page-border-line'
                        />
                    <input 
                        className='workout-name-input border-outline white small-medium'   
                        placeholder='Untitled Plan'
                        onChange={handlePlanNameChange}

                        />
                    <div 
                        className='page-border-line'
                        />
                    <textarea 
                        className='plan-description white small'
                        placeholder='//Add Description'
                        onChange={handleDescriptionChange}
                    />
                    <div 
                        className='page-border-line'
                    />
                    {userRole === 1 && (
                        <Stack direction='column' spacing={1}>
                            <text className='very-small light-grey'>Sub Rosters</text>
                            {subRosters.length > 0 ? subRosters.map((roster, index) => (
                                <Stack key={index} direction='row' spacing={7}  className='select-plan-roster'>
                                    {selected.some(item =>  item === roster) ? (
                                        <div className='roster-select-selected' onClick={() => addRoster(roster)} />
                                    ) : (
                                        <div className='roster-select' onClick={() => addRoster(roster)} />
                                    )}
                                    <text className='white small'>{roster}</text>
                                </Stack>
                            )) : (
                                <text className='small white'>No Current Sub Rosters</text>
                            )}
                            <div className='page-border-line'/>
                            <text className='very-small light-grey'>Add Individuals</text>
                            {members.length > 1 ? members.map((member, index) => {
                                if (member.accountType === 0) {
                                    return (
                                        <Stack key={index} direction='row' spacing={7}  className='select-plan-roster'>
                                            {selectedMembers.some(person => person === member.userID) ? (
                                                <div className='roster-select-selected' onClick={() => addMember(member)} />
                                            ) : (
                                                <div className='roster-select' onClick={() => addMember(member)} />
                                            )}
                                            <text className='white small'>{member.firstName} {member.lastName}</text>
                                        </Stack>
                                    )
                                }}) : (
                                    <text className='small white'>No Members</text>
                                )}
                            <div 
                                className='page-border-line'
                            />
                        </Stack>
                    )}
                    <button 
                        className='border-outline submit-green create-workout-button'
                        onClick={handleCreatePlan}
                        >
                        Create Plan
                    </button>
                    <div className='light-grey very-small'>
                        Training Plans are long-term workout plans that can span as much time as you want. 
                        Build & schedule workouts, and scale them week to week to reach your goals.
                    </div>
                </Stack>
            </Modal>
        </Box>
    )
}
 