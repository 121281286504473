import "../../../Styles/Icons/Icons.css";
import "../../../Styles/Icons/RunIcon.css";

const RunIcon = ({color}) => {
    return (
        <svg fill={color} className="icon-size" id="_ÎÓÈ_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1097.15 1020.85"><defs>
        </defs><ellipse fill={color} cx="961.43" cy="117.16" rx="116.87" ry="117.16"/>
        <path fill={color} strokeWidth="0px" d="M1096.7,506.67c-3.76-31.38-24.43-44.56-45.63-42.73l-176.63,13.27c-4.76.34-9.16-5.76-9.09-10.55,0,0,2.82-114.3,2.84-114.51,0,0,.88-15.56,1.2-29.98.51-22.61-9.49-42.97-23.38-57.61-41.44-43.7-191.16-194.4-203.34-206.06-14.19-13.59-50.31-20.43-73.79-3.64l-151.75,139.06c-17.65,14.1-18.3,40.88-2.01,60.44,16.79,20.16,46.96,18.35,61.36,7.05,7.51-5.89,108.18-93.93,108.18-93.93,5.37-4.46,15.75-4.83,21.36,1.2l66.17,72.07c7.35,6.12,7.08,17.24.2,23.38-6.39,5.7-509.56,514.38-509.56,514.38-.19.19-146.18,147.36-146.18,147.36-16.42,16.54-26.56,51.86-1.91,77.72,22.87,23.99,58.99,21.1,78.19,2.69l414.46-398.76c7.1-7.1,16.69-8.66,19.92-8.8,14.07-.61,20.91,4.9,34.06,18.31,2.27,2.31,59.09,61.55,64.74,67.55,2.53,2.69,68.43,71.49,68.43,71.49,3.4,3.61,5.23,9.27-3.02,10.45-.66.09-233.57,12.33-233.57,12.33-22.2,1.25-44.29,26.66-44.92,51.65-.76,30.38,27.19,52.72,49.39,51.47l113.93-7.05c.41-.02,1.82,0,2.24.03,1.86.2,2.75.29,4.67.18,0,0,200.17-11.35,208.49-12.07,20.6-2.11,48.58-20.69,56.3-44.06,6.36-20.7,6.24-47.94-8.73-66.5,0,0-152.17-172.4-172.06-195.5-2.81-3.26-1.2-11.32,1.87-14.34l82.38-81.2c5.19-5.12,11.76-5.94,11.26,6.71l-.52,44.06c-.44,2.92.9,13.47,2.14,22.79,3.32,24.95,32.92,44.26,47.67,43.2,0,0,85.7-6.9,86.23-6.84,1.36.17,157.75-12.93,157.75-12.93,17.4-1.25,44.94-15.85,40.64-51.79Z"/></svg>
    )
}

export default RunIcon
