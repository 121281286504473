import React, { useContext, useState, useEffect } from 'react';
import { Stack } from '@mui/material';
import { UserContext } from '../../../../context/AuthContext';
import { Timestamp, addDoc, collection, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import moment from 'moment';
import SpecificAnnouncement from './SpecificAnnouncement';

export default function Announcements() {
    const { currentUser, teamID } = useContext(UserContext);
    const [announcementView, setAnnouncementView] = useState(0);
    const [newTitle, setNewTitle] = useState('');
    const [newAnnouncement, setNewAnnouncement] = useState('');
    const [rawAnnouncements, setRawAnnouncements] = useState([]);
    const [announcementFeed, setAnnouncementFeed] = useState([]);
    const [activeAnnouncement, setActiveAnnouncement] = useState({})
    const [currentIndex, setCurrentIndex] = useState();
    const handleTitleChange = (event) => {
        const newTitleString = event.target.value;
        setNewTitle(newTitleString);
    }
    const handleAnnoucementChange = (event) => {
        const newAnnouncementString = event.target.value;
        setNewAnnouncement(newAnnouncementString)
    }
    useEffect(() => {
        let lastTenDays = []
        let targetDay = moment().format("MM/DD/YYYY");
        lastTenDays.push(targetDay);
        let i = 1
        for (i=1; i < 10; i++) {
            const previousDay = moment(targetDay).subtract(1, 'day').format("MM/DD/YYYY");
            const dayData = {
                date: previousDay,
                announcements: []
            }
            lastTenDays.push(dayData);
            targetDay = previousDay;
        }
        const teamDocRef = doc(db, 'teams', teamID);
        const unsubscribe = onSnapshot(teamDocRef, (teamDocSnap) => {
            if (teamDocSnap.exists()) {
                let sortedDays = [];
                const teamDocData = teamDocSnap.data();
                const announcements = teamDocData.announcements || [];
                lastTenDays.map((day, index) => {
                    const date = moment(day.date).format('MM/DD/YYYY');
                    announcements.slice(0,15).map((item) => {
                        const announcementDate = moment(item.sentAt).format('MM/DD/YYYY');
                        if (date === announcementDate) {
                            lastTenDays[index]

                        }
                    })
                })
                announcements.sort((a, b) => b.sentAt.toMillis() - a.sentAt.toMillis());
                console.log("first announcementFeed: " + JSON.stringify(announcements));
                setAnnouncementFeed(announcements);
                setRawAnnouncements(announcements);
            }
        })
        return () => unsubscribe();
    }, [teamID])
    const handleSubmitAnnouncement = async () => {
        const currentTime = Timestamp.now();
        try {
            const announcementDocData = {
                comments: [],
                content: newAnnouncement,
                creatorID: currentUser.userID,
                creatorName: currentUser.firstName + '' + currentUser.lastName,
                sentAt: currentTime,
                title: newTitle,
                viewingKey: []
            }
            const announcementCollectionRef = collection(db, 'teams', teamID, 'announcements');
            const newDoc = await addDoc(announcementCollectionRef, announcementDocData);
            const newDocID = newDoc.id;
            const teamDocObject = {
                announcementID: newDocID,
                comments: 0,
                content: newAnnouncement,
                creatorID: currentUser.userID,
                creatorName: currentUser.firstName + ' ' + currentUser.lastName,
                sentAt: currentTime,
                title: newTitle,
                viewingKey: []
            }
            const teamData = {
                announcements: [
                    ...announcementFeed,
                    teamDocObject
                ]
            }
            const teamDocRef = doc(db, 'teams', teamID)
            await updateDoc(teamDocRef, teamData);
            setNewAnnouncement('');
            setNewTitle('');
        } catch (err) {
            console.error(err);
        }
    }

    const changeCurrentAnnouncement = (item, index) => {
        console.log("currentAnnounceMent: " + JSON.stringify(item));
        setActiveAnnouncement(item);
        setCurrentIndex(index);
        setAnnouncementView(1);
    }
    useEffect(() => {
        console.log("RawAnnouncement stuff: " + JSON.stringify(rawAnnouncements));
    }, [rawAnnouncements])
    return (
        <div>
            {announcementView === 0 ? (
                <div>
                    <Stack direction="column" spacing={2} alignItems='center'>
                        {announcementFeed.length > 0 && announcementFeed.map((item, index) => (
                            <Stack direction='column' spacing={0.75} className='announcement-card-container more-padding'> 
                                <Stack direction='row' alignItems='center' justifyContent='space-between'>
                                    <text className='white small mid-weight'>{item.title}</text>
                                    <text className='white small light-weight'>{moment(item.sentAt.toDate()).format('h:mm A')}</text>
                                </Stack>
                                <div className='announcement-border-line top-border-line'/>
                                <text className='light-grey very-small'>{item.content}</text>
                                <button className='text-button white very-small comments-button' onClick={() => changeCurrentAnnouncement(item, index)}>{item.comments} Comments</button>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack direction='row' alignItems='center' className='announcement-blur-container'>
                        <Stack direction='column' className='announcement-bar-container'>
                            <div className='margin'>
                                <input 
                                    type='text'
                                    placeholder='Announcement Title'
                                    className='white small border-outline background-none margin announcement-title-input'
                                    onChange={handleTitleChange}
                                    value={newTitle}
                                />
                            </div>
                            <div className='announcement-border-line bottom-border-line'/>
                            <Stack direction='row' alignItems='center' className='more-margin-left more-margin-right'>
                                <textarea 
                                    className='white message-text-area small' 
                                    placeholder='Content'
                                    onChange={handleAnnoucementChange}
                                    value={newAnnouncement}
                                    />
                                <Stack direction='row' alignItems='center' justifyContent='center' className='announcement-submit-container' onClick={handleSubmitAnnouncement}>
                                    <text className='mid-text white'>+</text>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </div>
            ): announcementView === 1 ? (
                <SpecificAnnouncement 
                    activeAnnouncement={activeAnnouncement}
                    setAnnouncementView={setAnnouncementView}
                    rawAnnouncements={rawAnnouncements}
                    currentIndex={currentIndex}
                /> 
            ) : null}

        </div>
    )
}
