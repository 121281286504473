import React, { useContext } from 'react';
import { UserContext } from '../../../../context/AuthContext';
import { Box, Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';

export default function ViewSelect({setShowEventCreation, setWhichEventView, targetDate, setTargetDate, selectedDay, setSelectedDay}) {
    const { userRole, teamID } = useContext(UserContext);
    const handleSetEventChange = () => {
        setShowEventCreation(true);
        if (userRole === 1) {
            setWhichEventView("coach-options")
        } else {
            setWhichEventView(0);
        }
    } 
    const oneMonthForward = () => {
        const newTargetDate = moment(targetDate).add(1, "month").format('YYYY-MM-DD');
        console.log("newtargetDate: " + newTargetDate);
        setTargetDate(newTargetDate);
    }
    const oneMonthBackward = () => {
        const newTargetDate = moment(targetDate).subtract(1, "month").format('YYYY-MM-DD');
        setTargetDate(newTargetDate)
    }
    const toToday = () => {
        const today = moment().format("YYYY-MM-DD");
        console.log("today: " + today)
        setTargetDate(today);
    }
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '50.5rem'}}>
            <Stack direction='row' spacing={3} alignItems='center'>
                <text className='small-header mid-weight white'>
                    {moment(targetDate).format('MMMM')} {moment(targetDate).format('YYYY')}
                </text>
                <button 
                    className='new-event-button white really-small mid-weight padding'
                    onClick={handleSetEventChange}
                    >
                    Draft New Event +
                </button>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.3}>
                <button 
                    className='day-button left-button border-outline light-grey small'
                    onClick={oneMonthBackward}
                    >
                    <ArrowForwardIosIcon style={{ fontSize: '0.8rem' }}/>
                </button>
                <button 
                    className='day-button border-outline light-grey small'
                    onClick={toToday}
                    >
                    Today
                </button>
                <button 
                    className='day-button border-outline light-grey small'
                    onClick={oneMonthForward}
                    >
                    <ArrowForwardIosIcon style={{ fontSize: '0.8rem' }}/>
                </button>
            </Stack>
        </Box>
    )
}
