import React, { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import { ModalContext } from '../../../../../../context/ModalContext';
export default function CreateEvent({ onCreateEvent, onSaveAsDraft }) {
    const { updateEventData } = useContext(ModalContext);
    
    return (
        <Box
            sx={{
                width: '100%',
            }}>
            <button 
                className='create-button submit-green small border-outline'
                onClick={onCreateEvent}
                >
                {updateEventData ? 'Update Event' : 'Create Event'}
            </button>
        </Box>
    )
}
 