//Hold plan creation modal
import React, { useState, useContext, useEffect } from 'react';
import { TeamContext } from '../../../../../context/TeamContext';
import { UserContext } from '../../../../../context/AuthContext';
import { ModalContext } from '../../../../../context/ModalContext';
import { Box, Stack } from '@mui/material';
import Modal from "react-modal";
import { db } from '../../../../../firebase';
import { setDoc, doc, addDoc, collection, getDoc, updateDoc } from 'firebase/firestore';
 
export default function PendingModal({ isOpen, onCancel, setIsPendingRoster }) {

    const { pendingMembers, setPendingMembers, teamName, members, setMembers, teamDoc } = useContext(TeamContext);
    const { currentUser, teamID } = useContext(UserContext); 
    
    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "30rem",
            width: "100%",
        },
    };
    const handleAcceptMember = async (member, index) => {
        if (teamDoc.creatorID === currentUser.userID) {
            const userDocRef = doc(db, 'users', member.userID);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const teams = userData.teams || [];
                const index = teams.findIndex((team) => {
                    return (
                    team.status === 0 &&
                    team.teamID === teamID &&
                    team.teamName === teamName
                    );
                });
                if (index !== -1) {
                    teams[index].status = 1
                    // const newTeamsData = {
                    //     teams: newTeamsArray
                    // }
                    await setDoc(userDocRef, {teams}, {merge: true});
                  } else {
                    // The map with the specified values was not found
                    console.log("Map not found");
                  }
            }        
            const newMembersArray = [
                ...members, 
                member
            ]
            const newPendingMembersArray = [
                ...pendingMembers.slice(0, index), 
                ...pendingMembers.slice(index + 1)
            ]
            const teamDocRef = doc(db, 'teams', teamID);
            await setDoc(teamDocRef, {pendingMembers: newPendingMembersArray, members: newMembersArray}, {merge: true});
            console.log('wrote to teams doc');
            setPendingMembers(newPendingMembersArray);
            setMembers(newMembersArray);    
        }
    }

    const handleDeclineMember = async (member, index) => {
        const userDocRef = doc(db, 'users', member.userID);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            const teams = userData.teams || [];
            const index = teams.findIndex((team) => {
                return (
                team.status === 0 &&
                team.teamID === teamID &&
                team.teamName === teamName
                );
            });
            if (index !== -1) {
                teams[index].status = -1
                // const newTeamsData = {
                //     teams: newTeamsArray
                // }
                await setDoc(userDocRef, {teams}, {merge: true});
              } else {
                // The map with the specified values was not found
                console.log("Map not found");
              }
        }        
        const newPendingMembersArray = [
            ...pendingMembers.slice(0, index), 
            ...pendingMembers.slice(index + 1)
        ]
        const teamDocRef = doc(db, 'teams', teamID);
        await setDoc(teamDocRef, {pendingMembers: newPendingMembersArray}, {merge: true});
        console.log('wrote to teams doc');
        setPendingMembers(newPendingMembersArray);
        setMembers(newMembersArray);
    }

    const handleClose = () => {
        onCancel();
    };
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction="column" spacing={1}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                        <text className='white small-medium'>All Members</text>
                    </Stack>
                    <div 
                        className='page-border-line'
                        />
                    {/* <textarea 
                        className='plan-description white small'
                        placeholder='//Add Description'
                        onChange={handleDescriptionChange}
                    />
                    <div 
                        className='page-border-line'
                    /> */}
                    <Stack direction='column' spacing={2}>
                        {pendingMembers.map((member, index) => (
                            
                            <Stack direction='row' justifyContent='space-between'>
                                <Stack key={index} direction='row' spacing={1}  className='select-plan-roster'>
                                    <text className='white small'>{member.firstName} {member.lastName}</text>
                                    <text className='small light-grey'>-</text>
                                    <text className='small light-grey light-weight'>
                                        {member.accountType === 0 && 'athlete'}
                                        {member.accountType === 1 && 'coach'}
                                        {member.accountType === 2 && 'parent'}
                                    </text>
                                </Stack>
                                <Stack direction='row' spacing={3}>
                                    <button className='submit-green small text-button' onClick={() => handleAcceptMember(member, index)}>Accept</button>
                                    <button className='delete-red small text-button' onClick={() => handleDeclineMember(member, index)}>Decline</button>
                                </Stack>
                            </Stack>
                        ))}
                        <div className='page-border-line'/>
                    </Stack>
                </Stack>
            </Modal>
        </Box>
    )
}
 