import "../../../Styles/LoggedIn/Home/Home.css"
import React, {useContext} from 'react';
import { UserContext } from "../../../context/AuthContext";
import { Box, Stack, Typography } from '@mui/material'
import { WhiteTypographyMedium } from '../../../context/CustomStyles'
import ShortcutButtons from './QuickNav/ShortcutButtons'
import QuickNav from './QuickNav/QuickNav'
import CalendarPreview from "./CalendarPreview/CalendarPreview";
import PendingMembers from "../Teams/Roster/PendingMembers/PendingMembers";
import FirstView from "./FirstView/FirstView";

export default function Home() {
    const { userRole } = useContext(UserContext);
    return (
        <div>  
            <Stack direction="column" spacing={1}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    {userRole !== 1 && <text className='small-medium white'>Dashboard</text>}
                </Stack>
                <FirstView />
                {userRole === 1 && (
                    <Box
                        sx={{ width: '33%'}}
                        >
                        <PendingMembers />
                    </Box>
                )}
            </Stack> 
        </div>
    )
}
