import "../../../Styles/Icons/Icons.css";
import "../../../Styles/Icons/TrainingPlanIcon.css";

const TrainingPlanIcon = ({color}) => {
    return (
        <svg className='big-icon' id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612.25 452.25">
            <path fill={'#161414'} d="M12.97,79.89v344.58c0,10.21,8.28,18.48,18.5,18.48h549.42c9.14,0,16.55-7.41,16.55-16.54V79.89H12.97Z"/>
            <path fill={'white'} d="M343.9,12.04v18.5c0,3.96,3.21,7.17,7.18,7.17h13.17c3.96,0,7.18-3.21,7.18-7.17V12.04h-27.53ZM446.97,12.04v18.5c0,
            3.96,3.21,7.17,7.18,7.17h13.18c3.96,0,7.18-3.21,7.18-7.17V12.04h-27.53ZM12.97,79.89v21.88h584.47v-21.88H12.97ZM580.89,440.16H31.47c-10.22,
            0-18.5-7.71-18.5-17.23v1.54c0,10.21,8.28,18.48,18.5,18.48h549.42c9.14,0,16.55-7.41,16.55-16.54v-1.67c0,8.51-7.41,15.41-16.55,15.41Z"/>
            <path fill={'#161414'} d="M597.44,79.89v346.53c0,9.13-7.41,16.54-16.55,16.54H31.47c-10.22,0-18.5-8.27-18.5-18.48V79.89h584.47Z"/>
            <path fill={'white'} d="M466.44,185.73h76.5v240.77c0,8.62-7,15.63-15.63,15.63h-45.25c-8.62,0-15.63-7-15.63-15.63v-240.77h0Z"
            transform="translate(1009.38 627.85) rotate(180)"/><path fill={'white'} d="M365.17,130.12h74.66v293.91c0,9.98-8.11,18.09-18.09,
            18.09h-38.48c-9.98,0-18.09-8.11-18.09-18.09V130.12h0Z" transform="translate(804.99 572.25) rotate(180)"/>
            <path fill={'white'} d="M259.75,252.66h81.11v172.48c0,9.38-7.61,16.99-16.99,16.99h-47.13c-9.38,0-16.99-7.61-16.99-16.99v-172.48h0Z"
            transform="translate(600.6 694.78) rotate(180)"/><path fill={'white'} d="M160.32,347.39h75.58v78.85c0,8.77-7.12,15.88-15.88,
            15.88h-43.81c-8.77,0-15.88-7.12-15.88-15.88v-78.85h0Z" transform="translate(396.22 789.52) rotate(180)"/>
            <path fill={'white'} d="M58.12,386.52h75.58v39.72c0,8.77-7.12,15.88-15.88,15.88h-43.81c-8.77,0-15.88-7.12-15.88-15.88v-39.72h0Z"
            transform="translate(191.83 828.65) rotate(180)"/><path fill={'white'} d="M165.28,12.04v18.5c0,3.96-3.21,7.17-7.18,7.17h-13.18c-3.96,
            0-7.18-3.21-7.18-7.17V12.04h27.53Z"/><path fill={'white'} d="M165.28,7.29v4.75h-27.53v-4.75c0-3.96,3.21-7.17,7.18-7.17h13.18c3.96,0,
            7.18,3.21,7.18,7.17Z"/><path fill={'white'} d="M268.35,12.04v18.5c0,3.96-3.21,7.17-7.18,7.17h-13.17c-3.96,0-7.18-3.21-7.18-7.17V12.04h27.53Z"/>
            <path fill={'white'} d="M268.35,7.29v4.75h-27.53v-4.75c0-3.96,3.22-7.17,7.18-7.17h13.17c3.96,0,7.18,3.21,7.18,7.17Z"/>
            <path fill={'white'} d="M371.43,7.29v23.25c0,3.96-3.22,7.17-7.18,7.17h-13.17c-3.96,0-7.18-3.21-7.18-7.17V7.29c0-3.96,
            3.21-7.17,7.18-7.17h13.17c3.96,0,7.18,3.21,7.18,7.17Z"/><path fill={'white'} d="M583.03,12.04h-108.54v18.5c0,3.96-3.21,
            7.17-7.18,7.17h-13.18c-3.96,0-7.18-3.21-7.18-7.17V12.04h-75.54v18.5c0,3.96-3.22,7.17-7.18,7.17h-13.17c-3.96,
            0-7.18-3.21-7.18-7.17V12.04h-75.55v18.5c0,3.96-3.21,7.17-7.18,7.17h-13.17c-3.96,0-7.18-3.21-7.18-7.17V12.04h-75.54v18.5c0,
            3.96-3.21,7.17-7.18,7.17h-13.18c-3.96,0-7.18-3.21-7.18-7.17V12.04H29.22C13.15,12.04.12,24.75.12,40.42v383.33c0,15.67,13.03,
            28.38,29.1,28.38h553.81c16.07,0,29.1-12.71,29.1-28.38V40.42c0-15.67-13.03-28.38-29.1-28.38ZM597.44,426.42c0,9.13-7.41,16.54-16.55,
            16.54H31.47c-10.22,0-18.5-8.27-18.5-18.48V79.89h584.47v346.53Z"/><path fill={'white'} d="M474.49,7.29v23.25c0,3.96-3.21,7.17-7.18,
            7.17h-13.18c-3.96,0-7.18-3.21-7.18-7.17V7.29c0-3.96,3.21-7.17,7.18-7.17h13.18c3.96,0,7.18,3.21,7.18,7.17Z"/>
        </svg>
    )
}

export default TrainingPlanIcon