//Hold the middle bar section with switching plans are creating a new plan

import React, { useEffect, useContext, useState} from 'react';
import { UserContext } from '../../../../../context/AuthContext';
import { Stack } from '@mui/material';
import DetailPlan from '../DetailPlan/DetailPlan';

export default function NewSwitchPlan({setIsCreatePlan, personalPlans, teamPlans, teamName, setShowSpecificPlan, setPlanDetails}) {
    const {userRole, teamID} = useContext(UserContext);

    const toDetailPlan = (planID, collection) => {
        const planDetails = {planID: planID, collection: collection}
        setShowSpecificPlan(true);
        setPlanDetails(planDetails);
    }
    return (
        <div>
            <Stack className="plan-buttons-container" direction='row' justifyContent='space-between' alignItems='center'>
                <Stack direction='row' spacing={4}>
                    {personalPlans.length > 0 && (
                        <Stack className='column' spacing={1}>
                            <text className='light-grey very-small'>
                                Personal 
                            </text>
                            <Stack direction='row' spacing={1}>
                                {personalPlans.map((plan) => (
                                    <div 
                                        className='switch-plan-container'
                                        onClick={(plan) => {
                                            const planID = plan.planID
                                            const personal = true;
                                            const collection = 'users';
                                            toDetailPlan(planID, collection, personal)
                                        }}
                                        >
                                        <Stack direction='column'>
                                            <text className='white small'>
                                                {plan.planTitle}
                                            </text>
                                            <text className="light-grey very-small">
                                                Created by {plan.planCreatorName}
                                            </text>
                                        </Stack>
                                    </div>
                                ))}
                            </Stack>
                        </Stack>
                    )}
                    {teamPlans.length > 0 && (
                        <Stack className='column' spacing={0.5}>
                            <text className='light-grey very-small'>
                                {teamName} 
                            </text>
                            <Stack direction='row' spacing={1}>
                                {teamPlans.map((plan) => (
                                    <div 
                                        className='switch-plan-container  border-outline'
                                        onClick={() => {
                                            console.log("Plan: " + plan.planID)
                                            const planID = plan.planID
                                            const personal = false;
                                            const collection = 'teams'
                                            toDetailPlan(planID, collection, personal)
                                        }}
                                        >
                                        <Stack direction='column' justifyContent='left'>
                                            <text className='white small'>
                                                {plan.planTitle}
                                            </text>
                                            <text className="light-grey very-small">
                                                Created by {plan.planCreatorName}
                                            </text>
                                        </Stack>
                                    </div>
                                ))}
                            </Stack>
                        </Stack>
                    )}
                    {teamPlans.length === 0 && personalPlans.length === 0 && (
                        <text className='light-grey small-header'>
                            No Plans
                        </text>
                    )}
                </Stack>
                {userRole === 1 && teamID || userRole === 2 ? (
                    <button 
                        className={`small new-plan-button border-outline ${(userRole === 1 && teamPlans.length !== 0) || (userRole === 0 && personalPlans.length !== 0) ? 'white' : 'submit-green'}`}
                        onClick={() => setIsCreatePlan(true)}
                        >
                        + New Training Plan
                    </button>
                ): (
                    <Stack direction='row' alignItems='center' justifyContent='center' className='black small new-plan-button-placeholder'>
                        + New Training Plan
                    </Stack>
                )}
            </Stack>
        </div>
    )
}
