//Holds the workout creation Modal

import React, { useState, useContext, useEffect } from 'react';
import { db } from '../../../../../firebase';
import { setDoc, doc, addDoc, collection, Timestamp, getDoc } from 'firebase/firestore';
import { UserContext } from '../../../../../context/AuthContext';
import { Box, Stack } from '@mui/material';
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import SectionValue from './LeftSide/SectionValue';
import SectionDetail from './RightSide/SectionDetail';
import moment from 'moment';


export default function WorkoutCreation({ isOpen, onCancel, setIsWorkoutModal }) {
    const navigate = useNavigate();
    const { currentUser } = useContext(UserContext);
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [sections, setSections] = useState([{
                                            workoutSectionType: 1,
                                            metricType: 2,
                                            distance: '',
                                            unit: 2,
                                        }])
    const [currentSection, setCurrentSection] = useState(0)
    
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "37rem",
          width: "100%",
        },
      }; 

    const handleWorkoutNameChange = (event) => {
        const newName = event.target.value;
        setTitle(newName);
    } 
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        setDescription(newDescription);
    }
    const handleClose = () => {
        onCancel();
      };
    const handleCreateWorkout = async () => {
        const creationDate = moment();
        const creationTimeStamp = Timestamp.fromDate(creationDate.toDate());
        try {
            const userDocRef = doc(db, "users", currentUser.userID)
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                const userWorkouts = userData.workouts || [];
                const workoutsCollectionRef = collection(db, 'users', currentUser.userID, "workouts");
            
                const data = {
                    creationDate: creationTimeStamp,
                    creator: currentUser.userID,
                    description: description,
                    title: title,
                    workoutSections: sections,
                }
                const newWorkout = await addDoc(workoutsCollectionRef, data);
                const workoutID = newWorkout.id; 
                const userWorkoutData = [...userWorkouts, {
                    creationDate: creationTimeStamp,
                    docID: workoutID,
                    creator: currentUser.userID,
                    description: description,
                    title: title,
                    workoutSections: sections
                }];
                const newUserWorkouts = {
                    workouts: userWorkoutData
                }
                await setDoc(userDocRef, newUserWorkouts, { merge: true });     
                setIsWorkoutModal(false);     
            }
        } catch (err) {
            console.error(err);
        }
    }
    useEffect(() => {
        console.log('Sections Data: ' + JSON.stringify(sections));
    }, [sections]);
    return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Modal"
                className="create-team-content"
                overlayClassName="create-team-overlay"
                ariaHideApp={false} // Opt-out of accessibility settings
                >
                <Stack direction="column" spacing={1}>
                    <input 
                        className='workout-name-input border-outline white small-medium'   
                        placeholder='Untitled Workout'
                        onChange={handleWorkoutNameChange}

                        />
                    <div 
                        className='page-border-line'
                        />
                    <textarea 
                        className='workout-description white small'
                        placeholder='//Add Description'
                        onChange={handleDescriptionChange}
                    />
                    <div 
                        className='page-border-line'
                        />
                    <Stack direction="row" spacing={1}>
                        <Box className="workout-creation-container">
                            <SectionValue
                                sections={sections}
                                setSections={setSections}
                                currentSection={currentSection}
                                setCurrentSection={setCurrentSection}
                                />
                        </Box>
                        <div className='vertical-border'
                            />
                        <SectionDetail 
                            sections={sections}
                            setSections={setSections}
                            currentSection={currentSection}
                            setCurrentSection={setCurrentSection}
                            />
                    </Stack>
                    <button 
                        className='border-outline submit-green create-workout-button'
                        onClick={handleCreateWorkout}
                        >
                        Create Workout
                    </button>
                </Stack>
            </Modal>
        </Box>
    )
}
 