import React from 'react';
import { Box, Stack } from '@mui/material';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import ChatIcon from '@mui/icons-material/Chat';
import PeopleIcon from '@mui/icons-material/People';
import HomeIcon from '../../../../media/icons/HomeIcon/HomeIcon';
import { useNavigate } from 'react-router-dom';
 
export default function InTeamNav({whichTab, setWhichTab}) {
    const navigate = useNavigate();
    const handleToHome = () => {
        setWhichTab('0');
    }
    const handleToChat = () => {
        setWhichTab('2');
    }
    const handleToAnnouncements = () => {
        setWhichTab('3');
    }
    const handleToRoster = () => {
        setWhichTab('1');
    }

    return (
        <Stack direction="row" spacing={1.3} alignItems="center">
            {/* <button 
                onClick={handleToHome}
                className={`text-button white very-small light-weight ${whichTab === '0' && 'selected-tab'}`}>
                <Stack direction='row' spacing={0.3} alignItems='center' className='white-icon-container'> 
                    <HomeIcon color={'white'} />
                    <text>Home</text>
                </Stack>
            </button>   */}
            <button 
                onClick={handleToChat}
                className={`text-button white very-small light-weight ${whichTab === '2' && 'selected-tab'}`}
                > 
                <Stack direction='row' spacing={0.3} alignItems='center'>
                    <ChatIcon className='white' style={{fontSize: '1.3rem'}}/>
                    <text>Groupchats</text>
                </Stack>
            </button>  
            <button 
                onClick={handleToAnnouncements}
                className={`text-button white very-small light-weight ${whichTab === '3' && 'selected-tab'}`}
                > 
                <Stack direction='row' spacing={0.3} alignItems='center'>
                    <ChatIcon className='white' style={{fontSize: '1.3rem'}}/>
                    <text>Annoucements</text>
                </Stack>
            </button> 
            <button 
                className={`text-button white very-small light-weight ${whichTab === '1' && 'selected-tab'}`}
                onClick={handleToRoster}
                > 
                <Stack direction='row' spacing={0.3} alignItems='center'>
                    <PeopleIcon className='white' style={{fontSize: '1.3rem'}}/>
                    <text>Roster</text>
                </Stack>
            </button>  
        </Stack>
    )
}
