import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../../../../../context/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import { Stack } from '@mui/material';

export default function DetailPlan(setShowSpecificPlan, planDetails, setPlanDetails) {
    const { teamID } = useContext(UserContext);
    const [members, setMembers] = useState([]);
    const [planTitle, setPlanTitle] = useState('');
    useEffect(() => {
        const getSpecificPlan = async () => {
            const trainingPlanID = planDetails.planID;
            console.log("TrainingPlanID: " + trainingPlanID);
            const planDocRef = doc(db, 'teams', teamID, 'training plans', trainingPlanID);
            const planDocSnap = await getDoc(planDocRef);
            if (planDocSnap.exists()) {

            }
        }
        getSpecificPlan();
    }, [planDetails])

    return (
        <Stack direction='column' spacing={1}>
            <Stack direction='column' spacing={1}>
                <Stack direction='row' spacing={0.5}>
                    <text className='submit-green small-header'>Things To Come</text>
                    <text className='white small'>{planTitle}</text>
                </Stack>
            </Stack>
        </Stack>   
    )
}
